/* eslint-disable angular/no-services,angular/controller-as */

angular.module('users')
  .controller('EditCompanyUserCtrl', function (modalService, $http,BASE_PATH, $uibModalInstance, userToEdit , UserService, $scope) {
    $scope.isNew = true;
    $scope.onPremise = BASE_PATH.ON_PREMISE;
    $scope.user = {};
    $scope.isLoading = false;
    $scope.ok = function () {
      $scope.isLoading = true;
      var postData = {user : $scope.user, companyId : UserService.getUserManagerCompanyId()};
      $http.post(BASE_PATH.API_URL+'assignusertocompany', postData).then(function(response){
        //TODO inserire loader
        $scope.mailOk = true;
        $scope.isLoading = false;
        // $uibModalInstance.close(response);
      }, function(error){
        $scope.isLoading = false;
        $scope.errorMessage = error;
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
