angular.module('videocloud', ['ngConstants', 'angularFileUpload', 'ui.router', 'permission', 'permission.ui', 'ui.bootstrap', 'shared', 'auth'])
    .config(function($stateProvider){
        // eslint-disable-next-line angular/controller-as-route
        $stateProvider
            .state('videocloud', {
                url: '/videocloud/:videocloudId/:streamId',
                parent: 'withoutHeader',
                controller: 'VideoCtrl',
                templateUrl: 'app/video/video/video.tpl.html',
                resolve: {
                    videocloudId:function($stateParams){
                        return $stateParams.videocloudId;
                      },
                    streamId:function($stateParams){
                        return $stateParams.streamId;
                      },
            
                },
                data: {
                    //FIXME
                    // permissions: {
                    //     only: ['MENU-UTENTI'],
                    //     redirectTo: 'installations'
                    // }
                }
            })
            .state('error', {
                url: '/error',
                parent: 'withoutHeader',
                controller: 'ErrorCtrl',
                templateUrl: 'app/video/video/error.tpl.html',
                resolve: {
                    videocloudId:function($stateParams){
                        return $stateParams.videocloudId;
                      },
                    streamId:function($stateParams){
                        return $stateParams.streamId;
                      },
            
                },
                data: {
                    //FIXME
                    // permissions: {
                    //     only: ['MENU-UTENTI'],
                    //     redirectTo: 'installations'
                    // }
                }
            });
    });
