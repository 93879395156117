/* eslint-disable angular/controller-as */
angular.module('maps')
  .controller('MapPropertyPopupCtrl', function (symbol, properties, $uibModalInstance, $scope) {
    // $scope.node = node;
    $scope.symbol = symbol;
    $scope.properties = properties;
    $scope.ok = function(){
      $uibModalInstance.dismiss();
    };
  });
