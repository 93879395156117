angular.module('shared')
  .service('CommandService', function (BASE_PATH, $rootScope, $log, $http, socketService, UserService,TIME_SLOT_KEY ) {
    function pad(n, width, z) {
      z = z || '0';
      n = n + '';
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }
    function formatMsg(msgObj, template){
      var cmdString=pad(msgObj.plantNumber, 4) + msgObj.cmdString;
      if (template.cmdArgs > 0){
        cmdString =pad(msgObj.plantNumber,4) + msgObj.cmdString + pad(msgObj.argument, template.cmdArgs);
      }
      if(template.cmdString===TIME_SLOT_KEY){
        var hh=pad(template.time.getHours(),2);
        var mm=pad(template.time.getMinutes(),2);
        var slotType;
        switch(template.operation){
          case 'BTS': // inizio fascia
            slotType='0';
            break;
          case 'ETS': // fine  fascia
            slotType='1';
            break;
          case 'DBTS': // cancella inizio fascia
            slotType='2';
            break;
          case 'DETS': //cancella fine fascia
            slotType='3';
            break;
          default:
            slotType='0';//same as BTS
            break;
        }
        cmdString=pad(msgObj.plantNumber,4)+'/7'+pad(template.timeSlot,2)+slotType+hh+mm;
      }
      return cmdString;
    }
    var _getEvents = function(installationId, plantId,groupId){
      return $http.get(BASE_PATH.API_URL+'plant/'+plantId+'/events/'+groupId).then(function(result){
        return result.data;
      });
    };

    var _sendCommand = function(plantAddress, commandItem, commandTemplate, installationId, toAddFields){
      var msgToSend = {'PLANT_ID' : plantAddress, 'MSG' : formatMsg(commandItem, commandTemplate)};
      msgToSend['INSTALLATION_ID'] = installationId;
      msgToSend['SOURCE'] =  UserService.getUserUsername();
      try {
        var mDate = moment();
        msgToSend['CLIENT_LOCAL_TIME'] = mDate.format('HHmmSS');
        msgToSend['CLIENT_LOCAL_DATE'] = mDate.format('DDMMYY');
        
      } catch (error) {
        $log.error('Error in client local time for command', error) ;      
      }

      for (var i in toAddFields){
        msgToSend[i] = toAddFields[i];
      }
      return $http.post(BASE_PATH.API_URL+'cmd', msgToSend);
    };
    var _getAvailableCommands = function(installationId, plantId){
      return $http.get(BASE_PATH.API_URL+'/installation/'+installationId+'/plant/'+plantId+'/commandlist')
        .then(function(result){
          var commands = result.data;
          var avCmds = commands.map(function(c){
            return {
              'cmdArgs' : c.r_length,
              'cmdString' : c.r_protocol,
              'description' : c.r_description
            };
          });

          return {'availableCommands' : avCmds};
        });
    };
    return {
      getAvailableCommands : _getAvailableCommands,
      getEvents : _getEvents,
      sendCommand : _sendCommand
    };
  });
