angular.module('scheduler')
    .component('takeOver', {
        bindings: {
            installationId: '=',
            resolve: '<',
            close: '&',
            dismiss: '&'
        },
        templateUrl: 'app/scheduler/pendingNotification/takeOver/takeOver.html',
        controller: function(){
            var $ctrl = this;
            $ctrl.$onInit = function(){
                $ctrl.notification = $ctrl.resolve.notification;
            };
            $ctrl.takeOver = function(){
                $ctrl.close({$value: $ctrl.notes});
            };
            
        }
    });

