/* eslint-disable angular/controller-as-route */
angular.module('managers',['ngConstants','angularFileUpload','ui.router', 'permission', 'permission.ui','ui.bootstrap','shared','auth','customer','blockUI'])
  .config(function($stateProvider) {
    $stateProvider
      .state('managers', {
        url:'/managers',
        parent:'withHeader',
        controller:'ManagerManagementCtrl',
        templateUrl:'app/manager/managers/managers.tpl.html',
        data: {
          permissions: {
            only: ['MENU-INSTALLATORI'],
            redirectTo: 'installations'
          }
        }
      });
  });
