/* eslint-disable angular/controller-as */
angular.module('shared')
  .controller('askValueModalCtrl', function (options, $uibModalInstance, $scope) {
    $scope.options = options;
    $scope.data={};
    $scope.cancel= $uibModalInstance.dismiss;
    $scope.ok = function(){
      $uibModalInstance.close($scope.data);
    };
  });
