/* eslint-disable */
angular.module('customer')
  .controller('ViewCustomerModalCtrl', function ($uibModalInstance, customerToView, $scope) {

  $scope.customer = customerToView;

  $scope.ok = function () {
    $uibModalInstance.dismiss('cancel');
  };
});
