angular.module('shared')
  .filter('localizedString',function(LocalizationStringService) {
    return function(input) {
      if (!LocalizationStringService.localizedStrings) {
        return input;
      }
      var t = LocalizationStringService.localizedStrings[input];
      return t ? t : input;
    };
  });
