angular.module('scheduler')
    .component('ruleDetail', {
        bindings: {
            rules: '=',
            installationId: '=',
        },
        controller: function ($log, Rules, $timeout, toastr) {
            var $ctrl = this;
            
            $ctrl.saveEditRule = function() {
                var r = confirm(' Sei sicuro di voler modificare la regola? ');
                if (r == true) {
                    $log.info('saving' ,$ctrl.toEditObj);
                    $ctrl.toEditObj.$save({installationId: $ctrl.toEditObj.installation}).then(function() {
                       $log.info('rule saved');
                    });
                } else {
                    return;
                }
            };

            $ctrl.onDataAvailable = function(){
                $ctrl.flteredRules = $ctrl.rules.filter(function(rule){
                    // console.log(rule);
                    return rule.event_start_filter.includes('MODBUS');
                });
            };

            $ctrl.$onInit = function () {
                $log.info($ctrl);
                $log.info('rule management', $ctrl.rules);
                $ctrl.onDataAvailable();
            };

            $ctrl.info = function(data){
                alert(angular.toJson(data));
            };
            
            $ctrl.editObj = function(obj){
                $ctrl.toEditObj = obj;;
            };


        },
        templateUrl: 'app/scheduler/rulesAdmin/ruleDetail/ruleDetail.tpl.html'
    });
