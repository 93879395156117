/* eslint-disable angular/no-services,angular/controller-as,angular/window-service,angular/controller-as-vm */
//TODO use directive
angular.module('videocloud')
    //.controller('VideoCtrl', function($filter, $rootScope, $window, $uibModal, $log, $scope, BASE_PATH, $http, $state, $location, modalService, socketService, ViewPluginService, installationId, viewId, toastr, UserService,plants,blockUI){

    .controller('VideoCtrl', function($filter, $rootScope, $window, $uibModal, $log, $scope, BASE_PATH, $http, $state, $location, modalService, socketService, ViewPluginService, toastr, UserService, blockUI, videocloudId, streamId){
        $log.info('Videocloud controller');
        $log.info(videocloudId, streamId);
        // $http.get(BASE_PATH.API_URL + '/videocloud/:idInstallation/:idVideocloud')
        $http.get(BASE_PATH.API_URL + '/videocloud/'+videocloudId+'/'+streamId)
        .then(function (data){
            var jconf = data.data;
            var cameraId = streamId;
            videoStartRendering(jconf.stunServer, jconf.turnServe, jconf.videoproxyUrl, jconf.videoProxyToken, jconf.deviceId, cameraId);
        });
        
        $scope.windowName = $location.search()["name"];
        // window.videoproxyUrl = "{{videoproxy_url}}";
        // deviceId = "{{device_id}}";
        // camNumber = {{number_of_cams}};
        // stunServer = '{{video_cloud_stun}}';
        // turnServer = JSON.parse('{{video_cloud_turn|safe}}');
        // videoProxyToken = "{{video_proxy_token}}";
    });



