/* eslint-disable angular/controller-as */

angular.module('maps')
    .controller('ChartModalCtrl', function ($uibModalInstance, $scope, $uibModal, $log, blockUI, toastr, ViewPluginService, symbol, installationId, $filter,command) {
        $scope.symbol = symbol;
        $log.info(symbol);
        $scope.installationId = installationId;
        $scope.period = 'today';
        $scope.convertPeriodToFilter = function (period) {
            var filter = null;
            var date = new Date();
            date.setHours(0, 0, 0, 0);
            switch (period) {
                case 'today':
                    filter = date;
                    break;
                case '7days':
                    date.setDate(date.getDate() - 7);
                    filter = date;
                    break;
                case 'month':
                    date.setMonth(date.getMonth() - 1);
                    filter = date;
                    break;
                case '3month':
                    date.setMonth(date.getMonth() - 3);
                    filter = date;
                    break;
                case '8h':
                    date = new Date();
                    date.setHours(date.getHours() - 8);
                    filter = date;
                    break;
            }
            return filter;
        };
        $scope.chartOptions = {
            
                // tooltips: {
                //     callbacks: {
                //         label: function(tooltipItem, data) {
                //             tooltipItem.xLabel = 'aa';
                //             tooltipItem.label = 'xx'
                //             return 'label';
                //         }
                //     }
                // },
            elements: {
                line: {
                    fill: true,
                    tension : 0
                },
                point:{radius:2}},
            scales: {
                yAxes: [{
                    ticks: {
                        min: $scope.symbol.r_symbolconfiguration.min,
                        max: $scope.symbol.r_symbolconfiguration.max,
                        
                        callback: function (value, index, values) {
                            return value + ' ' + $scope.symbol.r_symbolconfiguration.unit;
                        }
                    }
                }]
            },
            pan: {enabled: true, mode: 'xy'},
            zoom: {enabled: true, mode: 'xy'}
        };
        //$scope.data=[[]];
        //$scope.labels=[];
        $scope.retrieveData = function () {
            blockUI.start();
            ViewPluginService.getChartData(command.r_protocol, $scope.installationId, $scope.convertPeriodToFilter($scope.period)).then(function (result) {
                $scope.data = [[]];//[0].length=0;
                $scope.labels = [];//.length=0;
                $scope.series = [$scope.symbol.name];
                // for min,max data based
                // var minX = result[0][0];
                // var maxX = result[result.length - 1][0];
                // min,max period base
                var minX = $scope.convertPeriodToFilter($scope.period);
                var maxX = new Date();
                $scope.minX = minX;
                $scope.maxX = maxX;
                $scope.chartOptions.scales.xAxes = [{
                    type: 'time',
                    distribution: 'linear',
                    time: {
                        min: new Date(minX),
                        max: new Date(maxX),
                        // unit: 'day',
                        // unitStepSize: 1,
                        displayFormats: {
                            'millisecond': 'HH:mm:ss DD/MM',
                            'second': 'HH:mm:ss DD/MM',
                            'minute': 'HH:mm:ss DD/MM',
                            'hour': 'HH:mm:ss DD/MM',
                            'day': 'HH:mm:ss DD/MM',
                            'week': 'HH:mm:ss DD/MM',
                            'month': 'HH:mm:ss DD/MM',
                            'quarter': 'HH:mm:ss DD/MM',
                            'year': 'HH:mm:ss DD/MM',
                        }
                  
                    },

                    tooltipFormat: 'YYYY-MM-DD HH:mm',
                    dateTimeLabelFormats: {
                        day: '%b %H:%M:%S'
                    },
                }];
                result.forEach(function (element, index, array) {
                    var date=new Date(element[0]);
                    // DO NOT DO THIS! -> date = new Date(date.getTime() + date.getTimezoneOffset()*60*1000);
                    date = new Date(date.getTime());

                    // noinspection JSUnresolvedVariable
                    //$scope.labels.push($filter('date')(date, 'dd/MM/yyyy HH:mm:ss'));
                    $scope.data[0].push({
                        x: date,
                        y: element[1]
                    });
                    if (array[index+1]){
                        var nextDate = new Date(array[index+1][0]);
                        $scope.data[0].push({
                            x: nextDate,
                            y: element[1]
                        });
                    }
                    
                });
                blockUI.stop();
            }).catch(function (err) {
                $log.error(err);
                // noinspection SpellCheckingInspection
                toastr.error('Errore', 'Errore Generico, riprovare più tardi');
                blockUI.stop();
            });
        };
        $scope.retrieveData();
        $scope.ok = function () {
            $uibModalInstance.close();
        };

        $scope.csvExport = function () {
            var csv = $scope.symbol.name+';\r\n\r\n'+
                     'Data;'+$scope.symbol.r_symbolconfiguration.unit+';\r\n';
            debugger;
            try {
                if($scope.data) {
                    var now = new Date();
                    now.setFullYear(now.getFullYear() + 1);
                    $scope.data.forEach(function(row) {
                        row.forEach(function(values) {
                            var current = new Date(values.x);
                            if(current.getTime() !== now.getTime()) {
                                csv += moment(values.x).format('DD/MM/YYYY HH:mm:ss') + ';' + values.y + ';\r\n';
                                now = current;
                            }
                        });
                    });
                }
                var blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
                var filename = command.r_protocol.split('|').join('_') + '_' + $scope.installationId + '_' + Date.now() + '.csv';
                if(window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else{
                    var elem = document.createElement('a');
                    elem.setAttribute("href", URL.createObjectURL(blob));
                    elem.setAttribute("download", filename);
                    document.body.appendChild(elem);
                    elem.dispatchEvent(new MouseEvent('click'));
                    URL.revokeObjectURL(elem.href);     
                    document.body.removeChild(elem);
                }    
            }
            catch(err) {
                var message = err.message || 'messaggio errore non disponibile';
                toastr.error('Errore CSV export: ' + message);
                console.log('CSV export error:', err);
            }
        };
    });
