angular.module('installations')
  .controller('InstallationListCtrl', function (fileReader, $uibModal, $log, BASE_PATH, $location, modalService, UserService, $rootScope, Installation) {
    var vm=this;
    vm.getInstallations = function(){
      Installation.getInstallationsByUserId(UserService.getLoggedUserId()).then(function(data){
        vm.installations = data;
        if (vm.installations.length == 0) {
          // new gui takes precedence over old one for new empty hicloud.
          console.log('no installations found, going to new gui');
          window.location.replace(window.location.origin+'/bms2');
        }
      });
    };

    vm.editInstallation = function(inst){
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        backdrop: 'static',
        templateUrl: 'app/installations/editInstallation/editInstallation.tpl.html',
        controller: 'EditInstallationCtrl',
        resolve: {
          installationToEdit: function () {
            return  inst;
          }
        }
      });
      modalInstance.result.then(function () {
        vm.getInstallations();
      });
    };
    vm.$onInit=function(){
      vm.currentCompany = UserService.getUserCompanyDescription();
      vm.getInstallations();
    };
  })
.component('installationList',{
  templateUrl:'app/installations/installationList/installationList.tpl.html',
  controller:'InstallationListCtrl'
});
