angular.module('auth')
    .component('groupUsers', {
        bindings: {
            group: '=',
            groupUsers: '=',
            installationId: '<'
        },
        controller: function($log, GroupUsers, BASE_PATH, $http, $uibModal, toastr, userInfo, UserService){
            var $ctrl = this;
            $ctrl.addUser = function(){
                GroupUsers.query({
                    installationId: $ctrl.installationId,
                    groupId: $ctrl.group.id
                }).$promise.then(function(possibleUsers){//Todo hide client admin from list
                    //if(possibleUsers.length){
                    var modalInstance = $uibModal.open({
                        component: 'addUserGroup',
                        size: 'lg',
                        resolve: {
                            users: function(){
                                return possibleUsers;
                            }
                        }
                    });
                    modalInstance.result.then(function(selectedUser){
                        $log.info('handle selected user', selectedUser);
                        selectedUser.installationId = $ctrl.installationId;
                        selectedUser.groupId = $ctrl.group.id;
                        var username = angular.copy(selectedUser.username);
                        selectedUser.$save().then(function(result){
                            toastr.success('Utente correttamente aggiunto', '');
                            $log.info(selectedUser);
                            selectedUser.username = username;
                            $ctrl.groupUsers.push(selectedUser);
                            $ctrl.group.userCount += 1;
                            $ctrl.group.reloadGroups();
                        }).catch(function(error){
                            $log.error(error);
                            var msg;
                            error.data = error.data || {error: {}};
                            if(error.data.error.errorMsg === 'ADMIN_USER'){
                                msg = 'Impossibile Spostare utente admin dal gruppo admin';
                            }else{
                                msg = 'Problema Imprevisto, riprova piu tardi';
                            }
                            toastr.error(msg, '');
                        });
                    });
                });
            };
            $ctrl.selectInitialPage = function(){
                $uibModal.open({
                    component: 'chooseInitialPage',
                    size: 'lg',
                    resolve: {
                        installationId: function(){
                            return $ctrl.installationId;
                        }
                    }
                }).result.then(function(selectedPage){
                    $ctrl.group.$setInitialPageToAllUsers(selectedPage).then(function(){
                        angular.forEach($ctrl.groupUsers,function(user){
                            user.initialpage=selectedPage;
                        });
                    }).catch(function(error){
                        $log.error(error);
                        toastr.error('problema imprevisto riprovare piu tardi');
                    });
                });
            };
            $ctrl.showRemoveBtn = function(u){
                $log.debug(u);
                $log.debug($ctrl.group);
                var hide = false;
                //hide button for admin users in admin group
                $log.debug($ctrl.group.isadmin, u.isadmin, $ctrl.group.isinstaller, u.isinstaller);
                hide = hide || $ctrl.group.isadmin && u.isadmin && $ctrl.group.isinstaller === u.isinstaller;
                //hide button for not admin user in guest degault group
                hide = hide || ($ctrl.group.isguest && !u.isadmin) & $ctrl.group.isinstaller === u.isinstaller;
                return hide;
                //todo hide button for guest group when user is guest (i. guest customer user in guest customer group)
            };
            $ctrl.removeUser = function(user, index){
                $log.info(user);
                user.installationId = $ctrl.installationId;
                user.groupId = $ctrl.group.id;
                user.$delete().then(function(result){
                    $ctrl.groupUsers.splice(index, 1);
                    $ctrl.group.userCount -= 1;
                    $ctrl.group.reloadGroups();
                }).catch(function(error){
                    $log.info(error);
                });
            };
            $ctrl.showResetPasswordBtn = function(u){
                return UserService.getPermissionByInstallationId($ctrl.installationId).indexOf('RESET_USERS_PASSWORD') >= 0;
            };
            $ctrl.resetPassword = function(user, index){
                user.installationId = $ctrl.installationId;
                user.groupId = $ctrl.group.id;
                // TODO: move in controller
                $http.post(BASE_PATH.API_URL+'resetPassword', user).then(function (result){
                    toastr.success('password resettata con successo');
                }).catch(function (err){
                    $log.error(err);
                    toastr.error('errore durante il reset della password');
                });
            };
        },
        templateUrl: 'app/auth/groups/groupDetail/users/users.tpl.html'
    });

