angular.module('dashboard').service('DashboardService', function (BASE_PATH, $http,$q,ViewPluginService) {
  var  getSymbolsForInstallation=function(installationId){
      return $http.get(BASE_PATH.API_URL+'/installation/'+installationId+'/dashboardSymbols');
  };
  var  getSymbolsWithCounterForMaps=function(installationId){
    var deferred =$q.defer();
    $q.all({symbols:getSymbolsForInstallation(installationId),counters:ViewPluginService.getInstallationViewsCounters(installationId)}).then(function(data){
      var views=data.symbols.data.filter(function(item){
        return angular.isUndefined(item.r_symbolconfiguration.$type)||item.r_symbolconfiguration.$type===null;
      }).reduce(function(views,item){
        var counterQuery=item.r_symbolconfiguration.Queries.filter(function(item){
          return item.Counter;
        })[0];
        if(counterQuery){
          item.CounterQueryId=counterQuery.Id;
        }
        views[item.r_symbolconfiguration.Id]=item;
        return views;
      },{});
      angular.forEach(data.counters,function(item){
        if(views[item.viewId]){
          views[item.viewId].counter=item.count;
        }
      });
      deferred.resolve(data.symbols.data);
    }).catch(function (reason) {
      deferred.reject(reason);
    });
    return deferred.promise;
  };
  return {
    getSymbolsWithCounterForMaps:getSymbolsWithCounterForMaps
  };
});
