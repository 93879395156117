angular.module('installations')
    .component('installationHome', {
        bindings: {
            installationId: '='
        },
        templateUrl: 'app/installations/installationHome/installationHome.tpl.html',
        controller: function($location, $uibModal, $log, $window, BASE_PATH, Installation, UserService){
            var $ctrl = this;
            $ctrl.currentUrl = $location.absUrl();
            $ctrl.accessControlUrl = BASE_PATH.ACCESS_CONTROL || '/access-control';
            $ctrl.staffManagementlUrl = BASE_PATH.STAFF_MANAGEMENT || '/staff-gui';
            $ctrl.newGui = BASE_PATH.NEW_GUI || '/bms2';
            $ctrl.newAccessControl = BASE_PATH.NEW_ACCESS_CONTROL || '/access-control2';

            $ctrl.getInstallation = function(installationId){
                return Installation.getInstallationsByUserId(UserService.getLoggedUserId()).then(function(installations){
                    return installations.filter(function(installation){
                        return installation.installationId.toString() === installationId.toString();
                    })[0];
                });
            };
            $ctrl.$onInit=function(){
                $ctrl.installationButtons = [
                    { permissionSref: 'calendarManagement', text: 'Scheduler', state: 'calendarManagement', icon: 'fa-10x fa fa-calendar-alt' },
                    { permissionSref: 'pendingNotification', text: 'Notifiche Aperte', state: 'pendingNotification', icon: 'fa-10x fa fa-exclamation-triangle' },
                    { permissionSref: 'historyNotification', text: 'Storico Notifiche', state: 'historyNotification', icon: 'fa-10x fa fa-history' },
                    { permissionSref: 'groupList', text: 'Gestione Gruppi', state: 'groupList', icon: 'fa-10x fa fa-users-cog' },
                    { permissionSref: 'rules', text: 'Gestione Regole', state: 'rules', icon: 'fa-10x fa fa-users-cog' }
                ];
                $ctrl.getInstallation($ctrl.installationId).then(function(installation){
                    $ctrl.installation = installation;
                    localStorage.setItem('ba_inst_access', $ctrl.installation.installationId);
                });
            };
            $ctrl.editInstallation = function(){
                var modalInstance = $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    backdrop: 'static',
                    templateUrl: 'app/installations/editInstallation/editInstallation.tpl.html',
                    controller: 'EditInstallationCtrl',
                    resolve: {
                        installationToEdit: function(){
                            return $ctrl.installation;
                        }
                    }
                });
                modalInstance.result.then(function(){
                    $ctrl.onUpdateInstallation();
                });
            };
        }
    });
