/* eslint-disable angular/controller-as */

angular.module('plants')
  .controller('CommandPopupCtrl', function ($uibModalInstance, command, plantAddress, plant, sendMsgFn, $scope,TIME_SLOT_KEY) {
    $scope.command = command;
    $scope.command.isTimeSlot=command.cmdString===TIME_SLOT_KEY;// this command has  different input fields
    $scope.slots=[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
    if($scope.command.isTimeSlot){
      $scope.command.timeSlot=$scope.slots[0];
      var a=new Date();
      a.setHours(0,0,0,0);
      $scope.command.time=a;
    }
    $scope.resetTime=function(){
      var a=new Date();
      a.setHours(0,0,0,0);
      $scope.command.time=a;
    };
    $scope.plantAddress = plantAddress;
    $scope.plant = plant;
    function pad(n, width, z) {
      z = z || '0';
      n = n + '';
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }
    $scope.msg = {
      cmdString :  $scope.command.cmdString,
      plantNumber : ''+pad(parseInt(plant.plantId,10), 4)
    };
    $scope.selected = {
      item: $scope.command[0]
    };
    $scope.ok = function () {
      $scope.sendingMsg = true;
      $scope.errorMsg = null;
      sendMsgFn(plantAddress, $scope.msg, $scope.command).then(function(){
        $uibModalInstance.close($scope.msg);
      }, function(error){
        $scope.sendingMsg = false;
        $scope.errorMsg = error.data;
      });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
