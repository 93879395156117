
/* eslint-disable angular/no-services,angular/controller-as,angular/window-service,angular/controller-as-vm */
//TODO use directive
angular.module('views')
    .directive('customFilterDropdown', function() {
        return {
            template: '<select class="form-control" ng-model="colFilter.term" ng-options="option.id as option.value for option in colFilter.options"></select>'
        };
    })
    .controller('GetDataCtrl', function($scope, $rootScope) {
        var onevent = $rootScope.$on('CallApplySelectionFilters', function (evt, data) {
            $scope.applySelectionFilters(data.idview, data.datum);
        });

        $scope.applySelectionFilters = function (idview, datum) {
            filtered = datum;

            // TODO: Tre loop nidificati, da correggere (Mauro, 10/09/2020)
            angular.forEach($scope.respselectionFilters, function(filtro, key) {
                v = filtro.value.split('|');
                if (filtro.idview == idview) {
                    angular.forEach(datum, function(value2, key2) {
                        angular.forEach(value2.item, function(value3, key3) {
                            if (filtro.field == key3) {
                                if (v.length > 1)  {
                                    /* Codice eseguito se c'è un filtro multiplo sulla stessa colonna */
                                    f = 0;
                                    angular.forEach(v, function(x, index) {
                                        if (value3.toString().toLowerCase().indexOf(x.toLowerCase()) >= 0)
                                            f++;
                                    });

                                    if (f == 0) {
                                        // Il filtro fallisce, tolgo l'elemento da quelli restituiti
                                        filtered = filtered.filter(function (z) {
                                            return z.item.r_key != value2.item.r_key;
                                        });
                                    }
                                }
                                else
                                {
                                    /* Codice eseguito se c'è un filtro per un unico valore */
                                    if (value3 == null || value3 == '') {
                                        // Campo null lo filtro via
                                        filtered = filtered.filter(function (z) {
                                            if (z.item.r_key === undefined)
                                                if (z.item.r_personalcode === undefined)
                                                    return z.item[key3] != value2.item[key3];
                                                else
                                                    return z.item.r_personalcode != value2.item.r_personalcode;
                                            else
                                                return z.item.r_key != value2.item.r_key;
                                        });
                                    } else {
                                        if (value3.toString().toLowerCase().indexOf(filtro.value.toLowerCase()) < 0) {
                                            // Il filtro fallisce, tolgo l'elemento da quelli restituiti
                                            // filtered = filtered.filter(z => z.r_key != value2.r_key)
                                            // ES5
                                            filtered = filtered.filter(function (z) {
                                                if (z.item.r_key === undefined)
                                                    if (z.item.r_personalcode === undefined)
                                                        return z.item[key3] != value2.item[key3];
                                                    else
                                                        return z.item.r_personalcode != value2.item.r_personalcode;
                                                else
                                                    return z.item.r_key != value2.item.r_key;
                                            });
                                        }
                                    }
                                }
                            }
                        });
                    });
                }
            });
            // Sorting...
            // found = $scope.respsortView.find(x => x.idview == idview);
            // ES5
            found = $scope.respsortView.find(function (x) {
                return x.idview == idview;
            });
            if (found) {
                found.field = found.field.toLowerCase();
                sortFactor = 1;
                if (found.order == 'DESC')
                    sortFactor = -1;

                filtered = filtered.sort(function(a,b) {
                    if(a.item[found.field]!=null && b.item[found.field]!=null){
                        if (a.item[found.field] > b.item[found.field])
                        return 1 * sortFactor;
                    if (a.item[found.field] < b.item[found.field])
                        return -1 * sortFactor;
                    return 0;
                    }else if(a.item[found.field] == null && b.item[found.field] == null)
                        return -1 * sortFactor;
                    else if(b.item[found.field] == null && a.item[found.field] !=null)
                        return 1 * sortFactor;
                    else
                        return 0;
                    // return a.r_description > b.r_description ? 1 : a.r_description < b.r_description ? -1 : 0
                });
            }

            $rootScope.filtered = filtered;

            //console.log('Fine chiamata applySelectionFilters ' + idview);
        };
    })
    .controller('ViewsCtrl', function($filter, $rootScope, $window, $uibModal, $log, $scope, BASE_PATH, $http, $state, $stateParams, $location, modalService, socketService, ViewPluginService, installationId, viewId, toastr, UserService,plants,blockUI,filters, CommandService){
        $scope.onPremise = BASE_PATH.ON_PREMISE == 'true';
        $scope.isDashboard = $stateParams.isDashboardViews;
        $scope.popup1 = {
            opened: false
        };
        $scope.popup2 = {
            opened: false
        };
        $scope.open1 = function(){
            $scope.popup1.opened = true;
        };
        $scope.open2 = function(){
            $scope.popup2.opened = true;
        };
        $scope.timespan=[
            {hours:2,description:'2 ore'},
            {hours:4,description:'4 ore'},
            {hours:6,description:'6 ore'}
        ];
        $scope.filters={};

        if(filters) {
            try {
                filters = decodeURIComponent(escape(window.atob(filters)));
                angular.forEach((filters).split('|'), function (filter, index) {
                    var items = filter.split('(');
                    var filterValues = items.pop().replace(/“/g, '').replace(/"/g, '').replace(')', '').split(',');
                    var filterName = items.pop().replace(/“/g, '').replace(/"/g, '').replace(/”/g, '');
                    $scope.filters[filterName] = filterValues;
                });
            } catch (e) {
                $location.path('installations/');
            }
        }
        var today = new Date();
        today.setHours(today.getHours()-1);
        // this is a tradeoff between improving caching hit ratio vs having the last events at the current minute
        today.setSeconds(0);
        today.setMilliseconds(0);
        $scope.$watch('filter.selectedPlant', function(value) {
            if(value && !angular.isString(value) ){
                $scope.timespan.push({hours:24,description:'1 giorno'});
                $scope.timespan.push({hours:48,description:'2 giorni'});
            }else{
                if($scope.timespan.length===5){
                    $scope.timespan.pop();
                    $scope.timespan.pop();
                    $scope.filter.selectedTimeSpan=$scope.timespan[0].hours;
                    $scope.updateFilter($scope.filter.dateFrom,$scope.filter.selectedTimeSpan);
                }
            }
        });

        $scope.filter = {
            selectedTimeSpan:$scope.timespan[0].hours,
            dateFrom: today,
            hourFrom: today.getHours(),
            minuteFrom: today.getMinutes(),
            dateTo: new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours() + $scope.timespan[0].hours, today.getMinutes(), today.getSeconds(), today.getMilliseconds()),
            hourTo: today.getHours() + $scope.timespan[0].hours,
            minuteTo: today.getMinutes(),
        };
        $scope.dateOptions = {
            formatYear: 'yyyy',
            maxDate: new Date(),
            startingDay: 1
        };
        $scope.installationId = installationId;
        $scope.selectedView = viewId;
        $scope.updateFilter=function(dateFrom,selectedHour){
            $scope.filter.dateTo= new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate(), dateFrom.getHours()+selectedHour, dateFrom.getMinutes(),dateFrom.getSeconds());
        };
        $scope.changeDateFrom=function(dateFrom, hourFrom, minuteFrom){
            if (angular.isDefined(hourFrom) && angular.isDefined(minuteFrom)) {
                if (hourFrom < 0 || hourFrom > 23) {
                    hourFrom = 0;
                    $scope.filter.hourFrom = 0;
                }
                if (minuteFrom < 0 || minuteFrom > 59) {
                    minuteFrom = 0;
                    $scope.filter.minuteFrom = 0;
                }
                dateFrom.setHours(hourFrom);
                dateFrom.setMinutes(minuteFrom);
            }
            if (dateFrom && !$scope.onPremise){
                $scope.updateFilter(dateFrom,$scope.filter.selectedTimeSpan);
            }
        };
        $scope.changeDateTo=function(dateTo, hourTo, minuteTo){
            if (angular.isDefined(hourTo) && angular.isDefined(minuteTo)) {
                if (hourTo < 0 || hourTo > 23) {
                    hourTo = 0;
                    $scope.filter.hourTo = 0;
                }
                if (minuteTo < 0 || minuteTo > 59) {
                    minuteTo = 0;
                    $scope.filter.minuteTo = 0;
                }
                dateTo.setHours(hourTo);
                dateTo.setMinutes(minuteTo);
            }
            if (dateTo && !$scope.onPremise){
                $scope.updateFilter(dateTo,$scope.filter.selectedTimeSpan);
            }
        };
        $scope.changeTimespan=function(selectedTimeSpan){
            $scope.updateFilter($scope.filter.dateFrom,selectedTimeSpan);
        };

        $scope

        $scope.plants=plants;
        function isInSTRlistFilter(toBeChecked, stringList){
            toBeChecked = toBeChecked.toLowerCase();
            stringList = stringList.toLowerCase().split(/[,]/);
            return stringList.reduce(function(acc, item){
                return acc || toBeChecked.includes(item.trim().toLowerCase());
            }, false);
        }

        $scope.getColorForView = function(v){
            if(angular.isUndefined($scope.queryCounters) || angular.isUndefined(v)){
                //FIXME wait counter info before render
                return '';
            }
            var counter = $scope.queryCounters[ v.Id ];
            if(angular.isDefined(counter)){
                if(counter > 0){
                    return v.WebColorWithRows;
                }else{
                    return v.WebColorWithNoRows;
                }
            }else{
                //TODO insert default
                return '';
            }
        };
        $scope.getCharFromIconCode = function(v){
            return String.fromCharCode(v.WebIcon);
        };
        $scope.navigateToView = function(v){
            $state.go('views', { installationId: $scope.installationId, viewId: v.Id });
            //$location.path('installation/' + $scope.installationId + '/view/' + v.Id);
        };
        $scope.isSelectedView = function(v){
            // noinspection EqualityComparisonWithCoercionJS
            return v.Id == $scope.selectedView;
        };
        /**
         * On Grid Row selection Handler
         * @param  {object} entity [description]
         * @param  {object} query  [description]
         */
        $scope.onItemSelection = function(entity, query){
            // $log('on item selection handling.');
            // clean current Selected Items List
            query.selectedItemsCommand = 'LOADING';
            $scope.querySelected = query.Id;
            $scope.getCommand(entity, query);
            $scope.getMapSymbol(entity, query);
        };
        /**
         * Clean command list by given querylist
         * @param  {object} item      [description]
         * @param  {object} q [description]
         */
        $scope.cleanCommandList = function(item, q){
            q.selectedItemsCommand = [];
            q.mapUrl = null;
        };
        /**
         * Initiate Ws call for command string - command key is contained in selected item at specified field
         * @param  {object} item      [description]
         * @param  {object} queryList [description]
         */
        $scope.getCommand = function(item, queryList){
            var qL = queryList;
            var command = item.item[ queryList.commandColumnKey ];
            if(command){
                ViewPluginService.getCommand(command, $scope.installationId).then(function(data){
                    qL.selectedItemsCommand = data;
                }, function(){
                    qL.selectedItemsCommand = 'ERROR';
                });
            }else{
                // no command string available
                qL.selectedItemsCommand = [];
            }
        };
        $scope.getMapSymbol = function(item, queryList){
            var qL = queryList;
            var command = item[ queryList.commandColumnKey ];
            if(command){
                ViewPluginService.getMapSymbol(command, $scope.installationId).then(function(data){
                    try{
                        data.r_hierarchy = data.r_hierarchy.split('.');
                        qL.maSymbolParent = data.r_hierarchy[ data.r_hierarchy.length - 1 ];
                        /** @namespace data.r_symbol */
                        qL.mapSymbol = data.r_symbol;
                        qL.mapUrl = $state.href('standAloneMap', {
                            installationId: $scope.installationId,
                            mapId: qL.maSymbolParent
                        });
                        if(qL.mapUrl){
                            qL.mapUrl = qL.mapUrl + '?symbolId=' + qL.mapSymbol;
                        }
                    }catch(e){
                        qL.mapUrl = undefined;
                    }
                }).catch(function(error){
                    $log.error(error);
                    qL.mapUrl = undefined;
                });
            }else{
                qL.mapUrl = undefined;
            }
        };
        $scope.goToMap = function(url, id){
            $window.open(url, '_blank_' + id, 'top=20');
        };
        /**
         * Send command object to gemss
         * @param  {object} item
         * @param  {string} item.r_plantdescription
         * @param  {object} command
         */
        $scope.sendCommand = function(item, command){
            if(command.r_description.toLowerCase() === 'programmazione fasce giornaliere'){
                var plant = findPlantSelected(item.r_plantid);
                var availableCommands = null;
                CommandService.getAvailableCommands($scope.installationId, plant.plantId).then(function(commands){
                    availableCommands = commands.availableCommands;
                    var commandSelected;
                    for(var i=0; i<availableCommands.length; i++){
                        if(availableCommands[i].description == command.r_description){
                            commandSelected = availableCommands[i];
                            break;
                        }
                    }
                    openCommandPopup(plant, commandSelected);
                });
            }else{
                var destinationRole = item.item[ 'r_command' ].split('|')[ 0 ],
                plantId = item.item[ 'r_plantid' ], descrizione = item.item['r_plantdescription'];
                if(angular.isDefined(plantId) && destinationRole){
                    $log.info('send command', command[ 'r_protocol' ], plantId);
                    return ViewPluginService.sendCommand(command[ 'r_protocol' ], plantId, destinationRole, $scope.installationId, { 'PLANT_DESCRIPTION': descrizione });
                }else{
                    throw new Error('no plantid for item command');
                }
            }

        };

        function findPlantSelected(plantId){
            var plant = undefined;
            for(var i=0; i<plants.length; i++){
                if(plants[i].MAC === plantId){
                    plant = plants[i];
                    break;
                }
            }
            return plant;
        }

        function openCommandPopup(plant, command){
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/plants/commandPopup/commandPopup.tpl.html',
                controller: 'CommandPopupCtrl',
                size: 200,
                resolve: {
                    plantAddress: function(){
                        return plant.MAC;
                    },
                    plant: function(){
                        return plant;
                    },
                    sendMsgFn: function(){
                        return $scope.sendMsg;
                    },
                    command: function(){
                        return command;
                    }
                }
            });
        }

        $scope.sendMsg = function(plantAddress, commandItem, commandTemplate){
            return CommandService.sendCommand(plantAddress, commandItem, commandTemplate, $scope.plantInfo.installationId, { 'PLANT_DESCRIPTION': $scope.plantInfo.description });
        };

        /*******************************************/
        /* Funzioni di gestione tabella responsiva */
        /* (Mauro, 4 settembre 2020)               */
        /*******************************************/
        $scope.windowWidth = window.innerWidth;
        $scope.respselectionvalue = 0;
        $scope.querySelected = 0;
        $scope.respselectionFilters = [];
        $scope.numFilteredItems = 0;
        $scope.respsortView = [];
        $scope.numViewColumns = 1;
        $scope.dimView = 100;
        $scope.historyEvent = [];
        $scope.historyRealtime = false;
        $scope.numItemsSelection = [
            {
                num: 10,
                desc: '10 elementi'
            },
            {
                num: 20,
                desc: '20 elementi'
            },
            {
                num: 40,
                desc: '40 elementi'
            },
            {
                num: 50,
                desc: '50 elementi'
            },
            {
                num: 100,
                desc: '100 elementi'
            }]; // Angular js ama le tendine fatte con oggetti complessi
        $scope.numItemViews = $scope.numItemsSelection[3];

        appWindow = angular.element($window);

        appWindow.on('resize', function () {
              $scope.windowWidth = window.innerWidth;
              $scope.$apply();
        });

        $scope.getSelectionButtonDetails = function (value) {
            if (value != $scope.respselectionvalue) {
                $scope.respselectionvalue = value;

                json = value;

                if (json.item.r_number >= 0) {
                    $scope.onItemSelection(json, $scope.currentView.Queries[ json.gemssQueryIndex ]);
                    $scope.currentView.Queries[ json.gemssQueryIndex ].selectedItems[ 0 ] = json;
                } else {
                    $scope.cleanCommandList(json, $scope.currentView.Queries[ json.gemssQueryIndex ]);
                    $scope.currentView.Queries[ json.gemssQueryIndex ].selectedItems = [];
                }
            }
            else {
                $scope.respselectionvalue = 0;
                $scope.cleanCommandList(json, $scope.currentView.Queries[ json.gemssQueryIndex ]);
                $scope.currentView.Queries[ json.gemssQueryIndex ].selectedItems = [];
            }
        };
        $scope.manageSelectionFilters = function (view, field, value) {
            idview = view.Id;
            datum = view.tableoptions.data;
            field = field.toLowerCase();
            dato = {
                'idview': idview,
                'field': field,
                'value': value
            };

            //console.log ("Manage " + idview);

            // es6
            //found = $scope.respselectionFilters.find(x => x.idview == dato.idview && x.field == dato.field);

            // es5
            found = $scope.respselectionFilters.find(function (x) {
                return x.idview == dato.idview && x.field == dato.field;
              });

            if (!found || $scope.respselectionFilters.length == 0) {
                $scope.respselectionFilters.push(dato);
            }
            else {
                if (dato.value.length > 0) {
                    //$scope.respselectionFilters.find(x => x.idview == dato.idview && x.field == dato.field).value = dato.value;
                    // ES5
                    $scope.respselectionFilters.find(function (x) {
                        return x.idview == dato.idview && x.field == dato.field;
                      }).value = dato.value;
                } else {
                    //$scope.respselectionFilters = $scope.respselectionFilters.filter(x => x.idview != dato.idview || x.field != dato.field);
                    // ES5
                    $scope.respselectionFilters = $scope.respselectionFilters.filter(function (x) {
                        return x.idview != dato.idview || x.field != dato.field;
                      });
                }
            }

            $rootScope.$emit('CallApplySelectionFilters', {
                idview: idview,
                datum: datum
              });
            view.tableoptions.filtered = $rootScope.filtered;
            $scope.numFilteredItems  = view.tableoptions.filtered.length;

            /* Disabilito comandi quando si eseguono filtri perché potrei nascondere il record selezionato */
            if ($scope.respselectionvalue != 0) {
                $scope.cleanCommandList($scope.respselectionvalue, $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ]);
                $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ].selectedItems = [];
                $scope.respselectionvalue = 0;
            }
        };
        $scope.searchSelectionFilters = function (idview) {
            // Funzione non implementata, qui sarà la logica del bottone di ricerca quando si vorrà farlo apparire
            target = document.getElementsByName('fTT_' + idview);
            //console.log("Filtri: ", $scope.respselectionFilters);
        };
        $scope.resetSelectionFilters = function (idview) {
            target = document.getElementsByName('fTT_' + idview);
            /*target.forEach(element => {
                element.value = "";
            });*/
            // ES5
            target.forEach(function (element) {
                element.value = '';
              });
            $scope.respselectionFilters = [];
        };
        $scope.displaySelectionFilters  = function (view) {
            if (view.respViewFilters == 'display: block;')
                view.respViewFilters = 'display: none;';
            else
                view.respViewFilters = 'display: block;';

            $rootScope.$emit('CallApplySelectionFilters', {
                idview: idview,
                datum: datum
              });
            view.tableoptions.filtered = $rootScope.filtered;
            $scope.numFilteredItems  = view.tableoptions.filtered.length;

            /* Disabilito comandi quando si eseguono filtri perché potrei nascondere il record selezionato */
            if ($scope.respselectionvalue != 0) {
                $scope.cleanCommandList($scope.respselectionvalue, $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ]);
                $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ].selectedItems = [];
                $scope.respselectionvalue = 0;
            }
        };
        $scope.searchSelectionFilters = function (idview) {
            // Funzione non implementata, qui sarà la logica del bottone di ricerca quando si vorrà farlo apparire
            target = document.getElementsByName('fTT_' + idview);
            //console.log('Filtri: ', $scope.respselectionFilters);
        };
        $scope.resetSelectionFilters = function (view) {
            target = document.getElementsByName('fTT_' + idview);
            /*target.forEach(element => {
                element.value = '';
            });*/
            // ES5
            target.forEach(function (element) {
                element.value = '';
              });
            $scope.respselectionFilters = [];

            idview = view.Id;
            datum = view.tableoptions.data;
            $rootScope.$emit('CallApplySelectionFilters', {
                idview: idview,
                datum: datum
              });
            view.tableoptions.filtered = $rootScope.filtered;
            $scope.numFilteredItems  = view.tableoptions.filtered.length;

            /* Disabilito comandi quando si cambiano i filtri perché potrei non vedere più il record selezionato */
            if ($scope.respselectionvalue != 0) {
                $scope.cleanCommandList($scope.respselectionvalue, $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ]);
                $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ].selectedItems = [];
                $scope.respselectionvalue = 0;
            }
        };
        $scope.displaySelectionFilters  = function (view) {
            if (view.respViewFilters == 'display: block;')
                view.respViewFilters = 'display: none;';
            else
                view.respViewFilters = 'display: block;';

            $scope.respselectionFilters = [];
        };

        $scope.manageSortingView = function(view, field) {
            idview = view.Id;
            datum = view.tableoptions.data;
            field = field.toLowerCase();
            dato = {
                'idview': idview,
                'field': field,
                'order': null
            };

            //console.log("Sorting view " + idview);
            //found = $scope.respsortView.find(x => x.idview == dato.idview);
            // ES5
            found = $scope.respsortView.find(function (x) {
                return x.idview == dato.idview;
              });
            var columnsFilter = document.querySelectorAll('[id^="sort'+view.Id+'_"]');
            columnsFilter.forEach(function(elem) {
                document.getElementById(elem.id).style.color='#b3af9c';
            });
            if (!found || $scope.respsortView.length == 0) {
                dato.order = 'ASC';
                if(field && document.getElementById("sort"+view.Id+"_"+field)){
                    document.getElementById("sort"+view.Id+"_"+field).classList.remove('fa-angle-up');
                    document.getElementById("sort"+view.Id+"_"+field).classList.add('fa-angle-down');
                    document.getElementById("sort"+view.Id+"_"+field).style.color='#2b686e';
                }
                $scope.respsortView.push(dato);
            }
            else {
                if (found.field == dato.field) {
                    if (found.order == 'ASC'){
                        dato.order = 'DESC';
                        if(field && document.getElementById("sort"+view.Id+"_"+field)){
                            document.getElementById("sort"+view.Id+"_"+field).style.color='#2b686e';
                            document.getElementById("sort"+view.Id+"_"+field).classList.remove('fa-angle-down');
                            document.getElementById("sort"+view.Id+"_"+field).classList.add('fa-angle-up');
                        }
                    }else{
                        dato.order = 'ASC';
                        if(field && document.getElementById("sort"+view.Id+"_"+field)){
                            document.getElementById("sort"+view.Id+"_"+field).style.color='#2b686e';
                            document.getElementById("sort"+view.Id+"_"+field).classList.remove('fa-angle-up');
                            document.getElementById("sort"+view.Id+"_"+field).classList.add('fa-angle-down');
                        }
                    }
                    //$scope.respsortView.find(x => x.idview == dato.idview).order = dato.order;
                    // ES5
                    $scope.respsortView.find(function (x) {
                        return x.idview == dato.idview;
                      }).order = dato.order;
                } else {
                    dato.order = 'DESC';
                    if(field && document.getElementById("sort"+view.Id+"_"+field)){
                        document.getElementById("sort"+view.Id+"_"+field).style.color='#2b686e';
                        document.getElementById("sort"+view.Id+"_"+field).classList.remove('fa-angle-down');
                        document.getElementById("sort"+view.Id+"_"+field).classList.add('fa-angle-up');
                    }
                    //$scope.respsortView = $scope.respsortView.filter(x => x.idview != dato.idview);
                    // ES5
                    $scope.respsortView = $scope.respsortView.filter(function (x) {
                        return x.idview != dato.idview;
                      });
                    $scope.respsortView.push(dato);
                }
            }

            $rootScope.$emit('CallApplySelectionFilters', {
                idview: idview,
                datum: datum
              });
            view.tableoptions.filtered = $rootScope.filtered;
            $scope.numFilteredItems  = view.tableoptions.filtered.length;

            /* Disabilito comandi quando si eseguono filtri perché potrei nascondere il record selezionato */
            if ($scope.respselectionvalue != 0) {
                $scope.cleanCommandList($scope.respselectionvalue, $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ]);
                $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ].selectedItems = [];
                $scope.respselectionvalue = 0;
            }
        };
        $scope.exportCsvView = function(query, data) {
            var table = document.getElementById('table_' + query.Id);
            var csvString = '';

            // Prendo solo il primo record della tabella, quello con il nome dei campi. Popolata usando la proprietà id della th.
            i = 0;
            var rowData = table.rows[i].cells;
            for(var j=1; j<rowData.length;j++){
                s = rowData[j].id.trim();
                // s = rowData[j].innerText.trim();
                if(s!=='r_videocloudregid')
                    csvString = csvString? csvString + ',' + s: s;
            }
            // csvString = csvString.substring(0,csvString.length - 1);
            csvString = csvString + '\n';
            //popola le righe contenenti i dati. Per popolare queste riche viene usata la proprietà title della td.
            for(var i=0; i<data.length;i++) {
                for(var j=1; j<rowData.length;j++){
                    s2 = rowData[j].attributes.mapping.value.trim().toLowerCase();
                    if(s2 !=='r_videocloudregid')
                        x = data[i].item[s2];
                    else
                        x=null;

                    if (x == null)
                        x = '';
                    s = x.toString().trim();
                    csvString = j>1? csvString + ',' + s: csvString + s ;
                }

                // csvString = csvString.substring(0,csvString.length - 1);
                csvString = csvString + '\n';
            }

            var a = $('<a/>', {
                style:'display:none',
                href:'data:application/octet-stream;base64,'+btoa(csvString),
                download: query.Title + '.csv'
            }).appendTo('body');
            a[0].click();
            a.remove();
        };
        $scope.visibleColumn = function(columnsAllowed, columnName) {
            var ret = true;

            found = columnsAllowed.find(function (x) {
                return x.MappingName.toLowerCase() == columnName.toLowerCase();
            });

            if (!found)
                ret = false;

            return ret;
        };
        $scope.historyRealtimeSelection = function(view) {
            view.tableoptions = {
                data: [],
                filtered: [],
                command: false
             };

            if ($scope.historyRealtime) {
                $scope.historyRealtime = false;
                $scope.populateViewData();
            }
            else {
                $scope.historyRealtime = true;

                angular.forEach($scope.historyEvent, function(hev){
                    dato = {
                        gemssQueryIndex: view.gridOptions.gemssQueryIndex,
                        item: hev
                    };

                    view.tableoptions.data.push(dato);
                    view.tableoptions.filtered.push(dato);
                });

                $scope.manageSortingView(view, 'r_datetime');
            }
        };
        /*********************************************************************************************************************************/

        /**
         * init function
         */
        $scope.populateViewData = function(){
            blockUI.start();
            ViewPluginService.getInstallationViewsCounters($scope.installationId).then(function(counters){
                $scope.queryCounters = {};
                angular.forEach(counters, function(counter){
                    $scope.queryCounters[ counter.viewId ] = counter.count;
                });
            });
            ViewPluginService.getInstallationViewsInfo($scope.installationId).then(function(instViewObj){
                var selectedVIdx = 0;
                $scope.installationInfo = instViewObj.installationInfo;
                $scope.views = instViewObj.views;
                // retrieve selected view index
                for(var i = $scope.views.length - 1; i >= 0; i--){
                    if($scope.isSelectedView($scope.views[ i ])){
                        selectedVIdx = i;
                        break;
                    }
                }
                //Retrieve info of Views/Query for current installation
                if($scope.installationInfo && $scope.views){
                    $scope.currentView = $scope.views[ selectedVIdx ];
                    if($scope.currentView.Queries && $scope.currentView.Queries.length > 0){
                        //Retrieve info and data for each query
                        $scope.currentView.Queries.forEach(function(query, i){
                            query.selectedItems = [];
                            query.selectedItemsCommand = [];
                            query.respViewFilters = 'display: none;';

                            // Data grid register API
                            var onRegisterApiF = function(gridApi){
                                $scope.currentView.Queries[ gridApi.grid.options.gemssQueryIndex ].gridApi = gridApi;
                                // noinspection JSCheckFunctionSignatures
                                gridApi.selection.on.rowSelectionChanged($scope, function(row){
                                    var msg = 'row selected ' + row.isSelected;
                                    $log.debug(msg);
                                    if(row.isSelected){
                                        $scope.onItemSelection(row.entity, $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ]);
                                        $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ].selectedItems[ 0 ] = row.entity;
                                    }else{
                                        $scope.cleanCommandList(row.entity, $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ]);
                                        $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ].selectedItems = [];
                                    }
                                });
                                // noinspection JSCheckFunctionSignatures
                                gridApi.selection.on.rowSelectionChangedBatch($scope, function(rows){
                                    var row = rows[ 0 ];
                                    if(row.isSelected){
                                        $scope.onItemSelection(row.entity, $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ]);
                                        $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ].selectedItems[ 0 ] = row.entity;
                                    }else{
                                        $scope.cleanCommandList(row.entity, $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ]);
                                        $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ].selectedItems = [];
                                    }

                                });
                                /** @namespace gridApi.core.on */
                                // noinspection JSValidateTypes
                                /** @namespace gridApi.core.on.filterChanged */
                                gridApi.core.on.filterChanged($scope, function(){
                                    var grid = this.grid;
                                    $log.debug('filterChanged');
                                    $scope.queries[ grid.options.gemssQueryIndex ].filterTerm = grid.columns[ 1 ].filters[ 0 ].term;
                                });
                            };
                            //Data Grid Options
                            query.gridOptions = {
                                exporterCsvFilename:query.Title+'_'+(new Date().toLocaleDateString()),
                                gemssQueryIndex: i,
                                onRegisterApi: onRegisterApiF,
                                enableSelectionBatchEvent: false,
                                enableFiltering: true,
                                enableRowSelection: true,
                                enableSelectAll: false,
                                selectionRowHeaderWidth: 35,
                                rowHeight: 35,
                                showGridFooter: true,
                                columnDefs: [],
                                selectedItems: query.selectedItems,
                                multiSelect: false,
                                enableRowHeaderSelection: true,
                                enableColumnResize: true,
                                init: function(){
                                    $log.debug('NG_GRID INITIALIZE');
                                },
                                filterOptions: {
                                    filterText: '',
                                    useExternalFilter: false
                                },
                                afterSelectionChange: function(){
                                    $log.log('selected data change');
                                },
                                showFilter: true,
                                data: [],
                            };

                            /************************************************/
                            /* Inizializzazione gestione tabella responsiva */
                            /* (Mauro, 4 settembre 2020)                    */
                            /************************************************/
                            query.tableoptions = {
                                data: [],
                                filtered: [],
                                command: false
                             };

                            /* Colonne e paginazione */
                            commandPresent = query.Columns.filter(function(quer){
                                return quer.MappingName === 'r_number';}).length;
                            query.tableoptions.command = (commandPresent > 0);
                            $scope.numViewColumns = query.Columns.filter(function(quer){
                                return (quer.MappingName != 'r_key' && quer.MappingName != 'r_command' && quer.MappingName != 'r_videocloudregid');
                            }).length;
                            $scope.dimView = function(){
                                var videocloudColumn = query.Columns.filter(function(quer){
                                    return (quer.MappingName == 'r_videocloudregid');
                                }).length;
                                if(videocloudColumn===1)
                                    return 90;
                                else
                                    return 95;
                            };
                            /* Fine colonne e paginazione */

                            $scope.currentView.Queries[ i ].selectedItemsCommand = '';

                            query.gridOptions.onRegisterApi = function(gridApi){
                                //set gridApi on scope
                                query.gridApi = gridApi;
                                // noinspection JSCheckFunctionSignatures
                                gridApi.selection.on.rowSelectionChanged($scope, function(row){
                                    var msg = 'row selected ' + row.isSelected;
                                    $log.log(msg);
                                    if(row.isSelected){
                                        $scope.onItemSelection(row.entity, $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ]);
                                        $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ].selectedItems[ 0 ] = row.entity;
                                    }else{
                                        $scope.cleanCommandList(row.entity, $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ]);
                                        $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ].selectedItems = [];
                                    }
                                });
                                query.exportCsv=function(){
                                    var rowTypes = uiGridExporterConstants.ALL;
                                    var colTypes = uiGridExporterConstants.ALL;
                                    query.gridApi.exporter.csvExport( rowTypes, colTypes );
                                };
                                // noinspection JSCheckFunctionSignatures
                                gridApi.selection.on.rowSelectionChangedBatch($scope, function(rows){
                                    var msg = 'rows changed ' + rows.length;
                                    $log.log(msg);
                                });
                            };
                            //Column formatting data grid
                            var createCol = function(colDescription){
                                // noinspection EqualityComparisonWithCoercionJS
                                if(colDescription.Command && colDescription.Command == true){
                                    query.commandColumnKey = colDescription.MappingName;
                                }
                                // noinspection EqualityComparisonWithCoercionJS
                                if(colDescription.Width == 0){
                                    return;
                                }
                                var columnFormat = {};
                                colDescription.MappingName = colDescription.MappingName.toLowerCase();
                                //popolo l'array delle chiavi della query
                                if(angular.isString(colDescription.Key) || colDescription.Key instanceof String){
                                    /** @namespace query.filterKeys */
                                    query.filterKeys.push(colDescription.MappingName);
                                }
                                //Set Sorting Alg for column
                                columnFormat.sortingAlgorithm = function(a, b, rowA){
                                    // noinspection JSUnresolvedFunction
                                    var nulls = rowA.grid.api.core.sortHandleNulls(a, b);
                                    if(nulls !== null){
                                        return nulls;
                                    }else{
                                        if(angular.isString(a)){
                                            if(a > b){
                                                return 1;
                                            }else{
                                                if(a === b){
                                                    return 0;
                                                }else{
                                                    return -1;
                                                }
                                            }
                                        }else{
                                            if(angular.isNumber(a)){
                                                if(a > b){
                                                    return 1;
                                                }else{
                                                    if(a === b){
                                                        return 0;
                                                    }else{
                                                        return -1;
                                                    }
                                                }
                                            }
                                        }

                                    }
                                };
                                //POPOLAMENTO COLONNE
                                columnFormat.field = colDescription.MappingName;
                                // DATE TIME COLUMN FORMAT / formattazione datetime
                                if(colDescription.format && colDescription.format === 'DATETIME'){
                                    columnFormat.cellFilter = 'date:\'dd/MM/yyyy H:mm:ss\'';
                                }
                                /** @namespace colDescription.HeaderText */
                                if(colDescription.HeaderText){
                                    columnFormat.displayName = colDescription.HeaderText;
                                }
                                if(colDescription.Width !== 0){
                                    columnFormat.width = colDescription.Width;
                                }
                                //TODO column sizing
                                var colFilterValues=$scope.filters[columnFormat.displayName];
                                //console.log(columnFormat.displayName,colFilterValues);
                                if(colFilterValues && (query.gridOptions.enableFiltering||columnFormat.enableFiltering)) {
                                    /* Gestione filtri da querystring con tabella */
                                    var f = '';
                                    angular.forEach(colFilterValues, function (x, index) {
                                        f = f + x + '|';
                                    });

                                    if (f.length > 0)
                                        f = f.substring(0, f.length - 1);

                                    dato = {
                                        'idview': query.Id,
                                        'field': columnFormat.field,
                                        'value': f
                                    };

                                    $scope.respselectionFilters.push(dato);
                                    colDescription.filterValue = f.replace('|', '/');
                                    /* Fine gestione filtri da querystring con tabella */

                                    columnFormat.filterHeaderTemplate= '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters"><div custom-filter-dropdown></div></div>';
                                    columnFormat.filter = {
                                        options: colFilterValues.map(function (item) {
                                            return {id: item, value: item};
                                        })
                                    };
                                    if (columnFormat.filter.options.length){
                                        columnFormat.filter.term=columnFormat.filter.options[0].id;
                                    }
                                }
                                else {
                                    colDescription.filterValue = '';
                                }
                                query.gridOptions.columnDefs.push(columnFormat);
                            };
                            for(var j in query.Columns){//TODO move to forEach
                                // noinspection JSUnfilteredForInLoop
                                createCol(query.Columns[ j ]);
                            }

                            /*Data Retrieve*/
                            if(query.Data && query.Data.Url){
                                // ensure we are not looking at more than 1 year at a time
                                // il giorno extra è per accomodare eventuali differenze in ore
                                var dateDiff = Math.floor((Math.abs($scope.filter.dateTo - $scope.filter.dateFrom)) / 1000);
                                if (dateDiff > 366 * 24 * 3600) {
                                    alert('ERRORE: è possibile cercare eventi nel periodo massimo di 1 anno per volta');
                                    blockUI.stop();
                                    return;
                                }
                                ViewPluginService.getQueryData($scope.installationId, query, UserService.getGroupIdByInstallationId($scope.installationId), $scope.filter.dateTo, $scope.filter.dateFrom,($scope.filter.selectedPlant||{}).MAC).then(function(result){
                                    var excludeFilter = UserService.getGroupSettingsByInstallationId($scope.installationId).history_exclude_filter;
                                    if(query.Data.Url === './query/events'){
                                        query.gridOptions.data = [];
                                        angular.forEach(result, function(item){
                                            if (item !== null) {
                                                if(excludeFilter){
                                                    if(!isInSTRlistFilter(item.r_description, excludeFilter) && !isInSTRlistFilter(item.r_info, excludeFilter)){
                                                        query.gridOptions.data.push(item);

                                                        // Oggetto per tabella responsiva
                                                        dato = {
                                                            gemssQueryIndex: query.gridOptions.gemssQueryIndex,
                                                            item: item,
                                                            r_key: item.r_key,
                                                            r_plantid: item.r_plantid,
                                                            r_number: item.r_number,
                                                            r_description: item.r_description,
                                                            r_command: item.r_command,
                                                            r_userinfo: item.r_userinfo,
                                                            r_videocloudregid: item.r_videocloudregid
                                                        };
                                                        query.tableoptions.data.push(dato);
                                                        query.tableoptions.filtered.push(dato);
                                                    }
                                                }else{
                                                    query.gridOptions.data.push(item);

                                                    // Oggetto per tabella responsiva
                                                    dato = {
                                                        gemssQueryIndex: query.gridOptions.gemssQueryIndex,
                                                        item: item,
                                                        r_key: item.r_key,
                                                        r_plantid: item.r_plantid,
                                                        r_number: item.r_number,
                                                        r_description: item.r_description,
                                                        r_command: item.r_command,
                                                        r_userinfo: item.r_userinfo,
                                                        r_videocloudregid: item.r_videocloudregid
                                                    };
                                                    query.tableoptions.data.push(dato);
                                                    query.tableoptions.filtered.push(dato);
                                                }
                                            }
                                        });
                                    }else{
                                        query.gridOptions.data = result;

                                        // Oggetto per tabella responsiva
                                        result.forEach(function (element) {
                                            dato = {
                                              gemssQueryIndex: query.gridOptions.gemssQueryIndex,
                                              item: element,
                                              r_key: element.r_key,
                                              r_plantid: element.r_plantid,
                                              r_number: element.r_number,
                                              r_description: element.r_description,
                                              r_command: element.r_command,
                                              r_userinfo: element.r_userinfo,
                                              r_videocloudregid: element.r_videocloudregid
                                            };
                                            query.tableoptions.data.push(dato);
                                            query.tableoptions.filtered.push(dato);
                                          });
                                    }

                                    /* Applico i filtri iniziali sui dati */
                                    if (filters) {
                                        $rootScope.$emit('CallApplySelectionFilters', {
                                            idview: query.Id,
                                            datum: query.tableoptions.data
                                        });
                                        query.tableoptions.filtered = $rootScope.filtered;
                                        $scope.numFilteredItems  = query.tableoptions.filtered.length;
                                    }

                                    // Gestione ordinamento iniziale //
                                    // (MD, 13/11/2020 - 19/11/2020)
                                    var ord = 'r_description';
                                    if (query.tableoptions.filtered.length > 0)  {
                                        itm = query.tableoptions.filtered[0].item;

                                        if (itm.r_surnamename !== undefined) {
                                            ord = 'r_surnamename';
                                        } else {
                                            if (itm.r_datetime !== undefined)
                                                ord = 'r_datetime';
                                        }
                                    }

                                    $scope.manageSortingView(query, ord);

                                    blockUI.stop();
                                }).catch(function(error){
                                    blockUI.stop();
                                    $log.error(error);
                                });
                            }
                        });
                    }
                }
                return $http.get(BASE_PATH.API_URL + 'company/' + $scope.installationInfo.customerId);
            }).then(function(response){
                $scope.customerInfo = response.data;
                $scope.subribedTopic = socketService.subscribe('Installation.' + $scope.installationId);
            }).catch(function(error){
                blockUI.stop();
                $log.error(error);
            });
        };
        //Opens New Tab with Cloud90
        $scope.managePlant = function(p){
            window.open(window.location.origin + window.location.pathname + '/#/plant/' + p.MAC, '_blank');
            // $window.location.href = $window.location.hostname + ':9080/#/plant/'+p.MAC;
        };

        var socketListener = $rootScope.$on('socket:refresh', function(){
            ViewPluginService.getInstallationViewsCounters($scope.installationId).then(function(counters){
                $scope.queryCounters = {};
                angular.forEach(counters, function(counter){
                    $scope.queryCounters[ counter.viewId ] = counter.count;
                });
            });
            ViewPluginService.getInstallationViewsInfo($scope.installationId).then(function(instViewObj){
                var viewToUpdate = instViewObj.views.filter(function(item){
                    return item.Id === $scope.selectedView;
                })[ 0 ];
                if(viewToUpdate){
                    angular.forEach(viewToUpdate.Queries, function(query){
                        var previusQueryData = $scope.currentView.Queries.filter(function(quer){
                            return quer.Id === query.Id;
                        })[ 0 ];
                        if(previusQueryData){
                            // ensure we are not looking at more than 1 year at a time
                            // il giorno extra è per accomodare eventuali differenze in ore
                            var dateDiff = Math.floor((Math.abs($scope.filter.dateTo - $scope.filter.dateFrom)) / 1000);
                            if (dateDiff > 366 * 24 * 3600) {
                                alert('ERRORE: è possibile cercare eventi nel periodo massimo di 1 anno per volta');
                                blockUI.stop();
                                return;
                            }
                            ViewPluginService.getQueryData($scope.installationId, query, UserService.getGroupIdByInstallationId($scope.installationId), $scope.filter.dateTo, $scope.filter.dateFrom, ($scope.filter.selectedPlant||{}).MAC).then(function(result){
                                previusQueryData.gridOptions.data = [];
                                previusQueryData.tableoptions.data = [];
                                previusQueryData.tableoptions.filtered= [];
                                var excludeFilter = UserService.getGroupSettingsByInstallationId($scope.installationId).history_exclude_filter;
                                if(previusQueryData.Data.Url === './query/events'){
                                    angular.forEach(result, function(item){
                                        if(excludeFilter){
                                            if(!isInSTRlistFilter(item.r_description, excludeFilter) && !isInSTRlistFilter(item.r_info, excludeFilter)){
                                                previusQueryData.gridOptions.data.push(item);

                                                // Oggetto per tabella responsiva
                                                dato = {
                                                    gemssQueryIndex: previusQueryData.gridOptions.gemssQueryIndex,
                                                    item: item,
                                                    r_key: item.r_key,
                                                    r_plantid: item.r_plantid,
                                                    r_number: item.r_number,
                                                    r_description: item.r_description,
                                                    r_command: item.r_command,
                                                    r_userinfo: item.r_userinfo,
                                                    r_videocloudregid: item.r_videocloudregid
                                                };
                                                previusQueryData.tableoptions.data.push(dato);
                                                previusQueryData.tableoptions.filtered.push(dato);
                                            }
                                        }else{
                                            previusQueryData.gridOptions.data.push(item);

                                            // Oggetto per tabella responsiva
                                            dato = {
                                                gemssQueryIndex: previusQueryData.gridOptions.gemssQueryIndex,
                                                item: item,
                                                r_key: item.r_key,
                                                r_plantid: item.r_plantid,
                                                r_number: item.r_number,
                                                r_description: item.r_description,
                                                r_command: item.r_command,
                                                r_userinfo: item.r_userinfo,
                                                r_videocloudregid: item.r_videocloudregid
                                            };
                                            previusQueryData.tableoptions.data.push(dato);
                                            previusQueryData.tableoptions.filtered.push(dato);
                                        }
                                    });
                                }else{
                                    previusQueryData.gridOptions.data = result;

                                    result.forEach(function (element) {
                                        dato = {
                                          gemssQueryIndex: previusQueryData.gridOptions.gemssQueryIndex,
                                          item: element,
                                          r_key: element.r_key,
                                          r_plantid: element.r_plantid,
                                          r_number: element.r_number,
                                          r_description: element.r_description,
                                          r_command: element.r_command,
                                          r_userinfo: element.r_userinfo,
                                          r_videocloudregid: element.r_videocloudregid
                                        };
                                        previusQueryData.tableoptions.data.push(dato);
                                        previusQueryData.tableoptions.filtered.push(dato);
                                    });

                                    /* Applico i filtri sui dati */
                                    /* MD, 14/10/2020                     */
                                    //if (filters) {
                                    $rootScope.$emit('CallApplySelectionFilters', {
                                        idview: previusQueryData.Id,
                                        datum: previusQueryData.tableoptions.data
                                    });
                                    previusQueryData.tableoptions.filtered = $rootScope.filtered;
                                    $scope.numFilteredItems  = previusQueryData.tableoptions.filtered.length;
                                    //}
                                }
                            });
                        }
                    });

                }
            });

        });
        $scope.editPlant = function(p){
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/plants/editPlant/editPlant.tpl.html',
                controller: 'EditPlantModalCtrl',
                resolve: {
                    plantToEdit: function(){
                        return p;
                    },
                    installationId: function(){
                        return $scope.installationId;
                    }
                }
            });
            modalInstance.result.then(function(selectedItem){
                $log.info(selectedItem);
                $scope.getPlants();
            }, function(result){
                $log.info(result);
                $log.info('Modal dismissed at: ' + new Date());
            });
        };
        /**
         * @param {object} event
         * @param {Array<{Key:string}>} event.Values
         */
        var lowerEventRowFieldsIds = function(event){
            for(var i = event.Values.length - 1; i >= 0; i--){
                event.Values[ i ].Key = event.Values[ i ].Key.toLowerCase();
            }
        };

        var com90Socket = $rootScope.$on('socket:com90', function(event, data){
            try{
                /* Array degli eventi storicizzati */
                if ($scope.historyEvent.length >= 1000) // TODO: Impostare il valore desiderato
                    $scope.historyEvent.shift();
                console.log('MC_log da trattare come viene gestito nel com90', data)
                /*********************************************/
                /* item modificato per gestione in Hicloud 2 */
                /* (Mauro, 17/02/2021)                       */
                /*********************************************/
                item = {
                    r_datetime: data.MSG.dateTime,
                    r_description: data.MSG.eventDesc,
                    r_info: data.MSG.eventInfo,
                    r_plantdescription: data.PLANT_DESCRIPTION,
                    r_plantid: data.PLANT_ID,
                    r_source: data.USERINFO,
                    r_userinfo: data.USERINFO,
                    // r_videocloudregid: item.r_videocloudregid
                };

                $scope.historyEvent.push(item);

                if ($scope.currentView.Title.toLowerCase() === 'storico eventi' &&  $scope.historyRealtime == true) {

                    //$scope.historyRealtime == true
                    // && $scope.currentView.Title.toLowerCase() == 'storico eventi'
                    selectedQuery = $scope.currentView.Queries[0];

                    selectedQuery.tableoptions = {
                        data: [],
                        filtered: [],
                        command: false
                    };

                    angular.forEach($scope.historyEvent, function(hev){
                        dato = {
                            gemssQueryIndex: selectedQuery.gridOptions.gemssQueryIndex,
                            item: hev
                        };

                        selectedQuery.tableoptions.data.push(dato);
                    });

                    /* Applico i filtri per evitare visualizzazioni di dati non previsti */
                    /* MD, 26/10/2020 */
                    $rootScope.$emit('CallApplySelectionFilters', {
                        idview: selectedQuery.Id,
                        datum: selectedQuery.tableoptions.data
                        });
                    selectedQuery.tableoptions.filtered = $rootScope.filtered;
                    $scope.numFilteredItems  = selectedQuery.tableoptions.filtered.length;
                }

            }catch(err){
                $log.error(err);
                //$scope.eventList.push({ tokens: ['MSG_DECODE_ERR', data]})
            }
        });

        /**
         * @param {object} event
         * @param {Array<{ViewId:string|undefined}>} data
         */
        var socketListenerRefresh = $rootScope.$on('socket:view', function(event, data){
            var j;
            try{
                var toRemoveItemList, toRemoveItem, removeIndex, qId, queryData;
                var selectedQuery = undefined;
                var expression = {};

                /* Non ci fidiamo e cancelliamo la selezione se arrivano degli eventi */
                /* MD, 5/10/2020                                                      */
                /* (commentato il 28/10/2020 perchè impedisce il corretto funzionamento dei comandi se eventi molto frequenti) */
                /*
                if ($scope.respselectionvalue != 0) {
                    $scope.cleanCommandList($scope.respselectionvalue, $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ]);
                    $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ].selectedItems = [];
                    $scope.respselectionvalue = 0;
                }
                */

                $log.debug('incoming message from ws service', data);
                // noinspection EqualityComparisonWithCoercionJS
                // noinspection EqualityComparisonWithCoercionJS
                if(!data.installationId || data.installationId != $scope.installationId){
                    $log.error('Rejected message from ws service, no match with current installation', data);
                    return;
                }
                data.data.forEach(function(eventData){
                    $log.debug('update event : ', eventData);
                    /** @namespace eventData.ViewId */
                    if(!eventData.ViewId && !eventData.Type){
                        return;
                    }
                    // Find Counter to be updated
                    var toUpdateView = $filter('filter')($scope.views, { 'Id': eventData.ViewId }, true)[ 0 ];
                    if(toUpdateView){
                        /** @namespace eventData.QueryId */
                        /** @namespace toUpdateView.Queries */
                        var toUpdateQuery = $filter('filter')(toUpdateView.Queries, { 'Id': eventData.QueryId }, true)[ 0 ];
                        /** @namespace toUpdateQuery.Counter */
                        if(toUpdateQuery && toUpdateQuery.Counter){
                            switch(eventData.Type){
                                case 0: //'ADD'
                                    $scope.queryCounters[ eventData.ViewId ] = $scope.queryCounters[ eventData.ViewId ] + 1;
                                    break;
                                case 1: //'REMOVE'
                                    $scope.queryCounters[ eventData.ViewId ] = $scope.queryCounters[ eventData.ViewId ] - 1;
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                    //  Current Queries update
                    if(eventData.ViewId === $scope.currentView.Id){
                        qId = eventData.QueryId;
                        for(i = $scope.currentView.Queries.length - 1; i >= 0; i--){
                            // noinspection EqualityComparisonWithCoercionJS
                            if($scope.currentView.Queries[ i ].Id == qId){
                                selectedQuery = $scope.currentView.Queries[ i ];
                                break;
                            }
                        }
                        if(angular.isUndefined(selectedQuery) || angular.isUndefined(selectedQuery.gridOptions) || angular.isUndefined(selectedQuery.gridOptions.data)){
                            return;
                        }
                        //queryData = selectedQuery.gridOptions.data;
                        queryData = selectedQuery.tableoptions.filtered; // MD, 5/10/2020

                        lowerEventRowFieldsIds(eventData);
                        var objToAdd = {};
                        var matchItems;
                        switch(eventData.Type){
                            case 0: //'ADD':
                                try{
                                    if(!eventData.Values){
                                        $log.error('update request with no data');
                                        throw 'update request with no data';
                                    }

                                    //TODO convert to reduce function
                                    for(j in eventData.Values){
                                        // noinspection JSUnfilteredForInLoop
                                        objToAdd[ eventData.Values[ j ].Key ] = eventData.Values[ j ].Value;
                                    }
                                    /*Find element if exists */
                                    /** @namespace selectedQuery.Columns */
                                    for(i in selectedQuery.Columns){
                                        // noinspection JSUnfilteredForInLoop
                                        if(selectedQuery.Columns[ i ].Key){
                                            // noinspection JSUnfilteredForInLoop
                                            $log.debug(selectedQuery.Columns[ i ].Key, selectedQuery.Columns[ i ]);
                                            // noinspection JSUnfilteredForInLoop
                                            /** @namespace eventData.RowKey */
                                            expression[ selectedQuery.Columns[ i ].MappingName ] = eventData.RowKey.toLowerCase();
                                            break;
                                        }
                                    }
                                    //toRemoveItemList = $filter('filter')(queryData, expression, true);

                                    // MD, 5/10/2020 (.item)
                                    toRemoveItemList = queryData.filter(function (x) {
                                        keyObj1 = Object.keys(x.item);
                                        keyObj2 = Object.keys(expression);
                                        valueObj1 = Object.values(x.item);
                                        valueObj2 = Object.values(expression);

                                        return x.item[keyObj2[0]] == valueObj2[0];
                                    });

                                    findCompleteItemList = selectedQuery.tableoptions.data.filter(function (x) {
                                        keyObj1 = Object.keys(x.item);
                                        keyObj2 = Object.keys(expression);
                                        valueObj1 = Object.values(x.item);
                                        valueObj2 = Object.values(expression);

                                        return x.item[keyObj2[0]] == valueObj2[0];
                                    });

                                    matchItems = toRemoveItemList.length;
                                    matchItemsComplete = findCompleteItemList.length;
                                    if(matchItems !== 0){
                                        toRemoveItem = toRemoveItemList[ 0 ];
                                        removeIndex = queryData.indexOf(toRemoveItem);
                                        queryData[ removeIndex ].item = objToAdd; // MD, 5/10/2020 (.item)
                                        itemComplete = findCompleteItemList[0];
                                        indexComplete = selectedQuery.tableoptions.data.indexOf(itemComplete);
                                        selectedQuery.tableoptions.data[indexComplete].item = objToAdd;
                                    }else{
                                        if(matchItems > 1){
                                            $log.error('multiple matching');
                                            throw 'multiple matching';
                                        }else{
                                            //queryData.push(objToAdd);
                                            // Oggetto per tabella responsiva
                                            dato = {
                                                gemssQueryIndex: selectedQuery.gridOptions.gemssQueryIndex,
                                                item: objToAdd
                                            };
                                            selectedQuery.tableoptions.data.push(dato);
                                            if (selectedQuery.tableoptions.data !== queryData)
                                                queryData.push(dato);
                                        }
                                    }
                                }catch(error){
                                    $log.error(error);
                                }
                                break;
                            case 'UPDATE':
                                try{
                                    if(!eventData.Values){
                                        $log.error('update request with no data');
                                        throw 'update request with no data';
                                    }
                                    for(j in eventData.Values){
                                        // noinspection JSUnfilteredForInLoop
                                        objToAdd[ eventData.Values[ j ].Key ] = eventData.Values[ j ].Value;
                                    }
                                    //Find element if exists
                                    for(i in selectedQuery.Columns){
                                        // noinspection JSUnfilteredForInLoop
                                        if(selectedQuery.Columns[ i ].Key){
                                            // noinspection JSUnfilteredForInLoop
                                            $log.debug(selectedQuery.Columns[ i ].Key, selectedQuery.Columns[ i ]);
                                            // noinspection JSUnfilteredForInLoop
                                            expression[ selectedQuery.Columns[ i ].MappingName ] = eventData.RowKey.toLowerCase();
                                            break;
                                        }
                                    }
                                    //toRemoveItemList = $filter('filter')(queryData, expression, true);

                                     // MD, 5/10/2020 (.item)
                                     toRemoveItemList = queryData.filter(function (x) {
                                        keyObj1 = Object.keys(x.item);
                                        keyObj2 = Object.keys(expression);
                                        valueObj1 = Object.values(x.item);
                                        valueObj2 = Object.values(expression);

                                        return x.item[keyObj2[0]] == valueObj2[0];
                                    });

                                    findCompleteItemList = selectedQuery.tableoptions.data.filter(function (x) {
                                        keyObj1 = Object.keys(x.item);
                                        keyObj2 = Object.keys(expression);
                                        valueObj1 = Object.values(x.item);
                                        valueObj2 = Object.values(expression);

                                        return x.item[keyObj2[0]] == valueObj2[0];
                                    });

                                    // Oggetto per tabella responsiva
                                    dato = {
                                        gemssQueryIndex: selectedQuery.gridOptions.gemssQueryIndex,
                                        item: objToAdd
                                    };

                                    //replace or insert
                                    matchItems = toRemoveItemList.length;
                                    if(matchItems !== 0){
                                        toRemoveItem = toRemoveItemList[ 0 ];
                                        itemComplete = findCompleteItemList[0];
                                        // if updating element is selected and has commands array available, clean it all
                                        if(selectedQuery.selectedItems.indexOf(toRemoveItem) !== -1){
                                            // MD, 5/10/2020
                                            if ($scope.respselectionvalue != 0) {
                                                $scope.cleanCommandList($scope.respselectionvalue, $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ]);
                                                $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ].selectedItems = [];
                                                $scope.respselectionvalue = 0;
                                            }
                                        }
                                        removeIndex = queryData.indexOf(toRemoveItem);
                                        indexComplete = selectedQuery.tableoptions.data.indexOf(itemComplete);

                                        queryData[ removeIndex ] = dato;
                                        selectedQuery.tableoptions.data[indexComplete] = dato;
                                    }else{
                                        if(matchItems > 1){
                                            throw 'multiple matching';
                                        }else{
                                            queryData.push(dato);
                                            selectedQuery.tableoptions.data.push(dato);
                                        }
                                    }
                                }catch(error){
                                    $log.error(error);
                                }
                                break;
                            case 1: //'REMOVE':
                                //Find column key and push to search expression
                                try{
                                    for(var i in selectedQuery.Columns){
                                        // noinspection JSUnfilteredForInLoop
                                        if(selectedQuery.Columns[ i ].Key){
                                            // noinspection JSUnfilteredForInLoop
                                            $log.debug(selectedQuery.Columns[ i ].Key, selectedQuery.Columns[ i ]);
                                            // noinspection JSUnfilteredForInLoop
                                            expression[ selectedQuery.Columns[ i ].MappingName ] = eventData.RowKey.toLowerCase();
                                            break;
                                        }
                                    }
                                    //toRemoveItemList = $filter('filter')(queryData, expression, true);

                                     // MD, 5/10/2020 (.item)
                                    toRemoveItemList = queryData.filter(function (x) {
                                        keyObj1 = Object.keys(x.item);
                                        keyObj2 = Object.keys(expression);
                                        valueObj1 = Object.values(x.item);
                                        valueObj2 = Object.values(expression);

                                        return x.item[keyObj2[0]] == valueObj2[0];
                                    });

                                    findCompleteItemList = selectedQuery.tableoptions.data.filter(function (x) {
                                        keyObj1 = Object.keys(x.item);
                                        keyObj2 = Object.keys(expression);
                                        valueObj1 = Object.values(x.item);
                                        valueObj2 = Object.values(expression);

                                        return x.item[keyObj2[0]] == valueObj2[0];
                                    });

                                    // Mantis #425
                                    if(findCompleteItemList.length === 0){
                                        $log.error('no query item matching with request: ', queryData);
                                        throw 'no query matching';
                                    } else {
                                        if (toRemoveItemList.length > 0) // Mantis #425
                                            toRemoveItem = toRemoveItemList[ 0 ];
                                        itemComplete = findCompleteItemList[0];
                                        // if updating element is selected and has commands array available, clean it all
                                        if(toRemoveItemList.length > 0 && selectedQuery.selectedItems.indexOf(toRemoveItem) !== -1){ // Mantis #425
                                            // noinspection JSCheckFunctionSignatures
                                            // MD, 5/10/2020
                                            if ($scope.respselectionvalue != 0) {
                                                $scope.cleanCommandList($scope.respselectionvalue, $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ]);
                                                $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ].selectedItems = [];
                                                $scope.respselectionvalue = 0;
                                            }
                                        }
                                        if (angular.isDefined(toRemoveItem)) // Mantis #425
                                            removeIndex = queryData.indexOf(toRemoveItem);
                                        else
                                            removeIndex = -1;

                                        indexComplete = selectedQuery.tableoptions.data.indexOf(itemComplete);

                                        if (removeIndex >= 0) // Mantis #425
                                            queryData.splice(removeIndex, 1);

                                        if (queryData !== selectedQuery.tableoptions.data)
                                            selectedQuery.tableoptions.data.splice(indexComplete, 1);
                                    }
                                }catch(error){
                                    $log.error(error);
                                }
                                break;
                        }
                    }
                });

                /* Applico i filtri per evitare visualizzazioni di dati non previsti */
                /* MD, 26/10/2020 */
                if (angular.isDefined(selectedQuery)) {
                    $rootScope.$emit('CallApplySelectionFilters', {
                        idview: selectedQuery.Id,
                        datum: selectedQuery.tableoptions.data
                        });
                    selectedQuery.tableoptions.filtered = $rootScope.filtered;
                    $scope.numFilteredItems  = selectedQuery.tableoptions.filtered.length;
                }
            }catch(err){
                $log.error(err); // MD, 10/12/2020
                //$scope.eventList.push({ tokens: ['MSG_DECODE_ERR', data]})
            }
            $scope.$apply();
        });

        $scope.getUrlForDownloadVideo= function(videocloudstring){
            var arrayVideoCloud = videocloudstring.split('|');
            if(arrayVideoCloud.length>1){
                var videocloudId = arrayVideoCloud[0];
                var videocloudregid = arrayVideoCloud[1];
                console.log('create url for download video', arrayVideoCloud);
                var composeKey = videocloudId+"/"+videocloudregid+'.mp4';
                if(!verifyNameStreamToBeDownloaded(composeKey)){
                    console.error('Videocloud file name non corretto', file);
                    toastr.error('Videocloud file name non corretto');
                    return;
                }
                $http.post(BASE_PATH.API_URL + '/videocloud/'+ videocloudId+'/recording/url', {"fileKey" : composeKey}).then(function(result){
                    window.location.href = result.data.signedUrl;
                });
            }else return null;
        }

        $scope.isVideoColumnPresent= function(query){
            var result = false;
            angular.forEach(query.Columns, function(col){
                if(col.MappingName === 'r_videocloudregid') result = true;
            });
            return result;
        }

        function verifyNameStreamToBeDownloaded(str){
            var m;
            var regex = /[a-z0-9]*_([0-9]*)_([0-9]*).mp4/gm;
            if(regex.test(str))
                return true;
            return false;
        }
        // Unregister listeners
        $scope.$on('$destroy', function(){
            $log.log('Unregistering listener');
            socketService.unsubscribe($scope.subribedTopic);
            socketListener();
            socketListenerRefresh();
            com90Socket();
        });
        $scope.populateViewData();
    });
