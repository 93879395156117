angular.module('scheduler')
    .service('Events', function($http, BASE_PATH){
        var factory = {};
        /**
         * @param {object} item
         * @param {string|Date} item.startsAt
         * @param {string|Date} item.endsAt
         * */
        factory.forceDataType = function(item){
            if(item.startsAt){
                try{
                    item.startsAt = new Date(item.startsAt);
                }catch(e){
                }
            }
            if(item.endsAt){
                try{
                    item.endsAt = new Date(item.endsAt);
                }catch(e){

                }
            }
            return item;
        };
        factory.saveEvents = function(installationId, events, year, month, loadedMonths){
            events = events.map(function(event){
                return {
                    startsAt: event.startsAt,
                    endsAt: event.endsAt,
                    groupId: event.groupId,
                    title:event.name
                };
            });
            return $http.post(BASE_PATH.API_URL + 'installation/' + installationId + '/schedulerConfig', {
                events: events,
                month: month,
                year: year,
                loadedMonths: loadedMonths
            }).then(function(response){
                return response.data.map(factory.forceDataType);
            });
        };
        factory.getEventsForMonth = function(installationId, year, month){
            return $http.get(BASE_PATH.API_URL + 'installation/' + installationId + '/schedulerConfig/' + year + '/' + month).then(function(response){
                return response.data.map(factory.forceDataType);
            });
        };
        return factory;
    });
