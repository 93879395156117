angular.module('auth', ['ngConstants', 'ui.bootstrap', 'ui.router', 'permission', 'permission.ui', 'shared', 'colorpicker.module'])
    .run(function (PermPermissionStore, UserService) {
        var extractInstallationIdFromOptions = function (transitionProperty) {
            var installationId;
            if (angular.isDefined(transitionProperty.toParams)) {
                installationId = transitionProperty.toParams.installationId;
            } else {
                installationId = transitionProperty.installationId;
            }
            return installationId;
        };
        var evaluatePermission = function (permissionName, transitionProperty) {
            var installationId = extractInstallationIdFromOptions(transitionProperty);
            return UserService.getPermissionByInstallationId(installationId).indexOf(permissionName) >= 0;
        };
        // noinspection JSUnusedLocalSymbols
        var onlyForCustomer = function () {
            return UserService.isCustomer();
        };
        var onlyForManager = function () {
            return !UserService.isCustomer();
        };
        var allUser = function () {
            return true;
        };
        var isAdmin = function () {
            return UserService.isAdmin();
        };
        var isAGlobalAdmin = function () {
            return UserService.isGlobalAdmin();
        };
        // noinspection JSUnusedLocalSymbols
        var onlyForManagerInstallationBased = function (permissionName, transitionProperty) {//work around for different kind of permission based on user role
            return onlyForManager() && evaluatePermission(permissionName, transitionProperty);
        };
        var isModuleEnabled = function (module, installationId) {
            return UserService.enabledModuleByInstallation(installationId).indexOf(module) >= 0;
        };
        var isModuleEnablePermissionFn = function (permNameUseModule, transitionProperty) {
            return isModuleEnabled(permNameUseModule, extractInstallationIdFromOptions(transitionProperty));
        };

        var evaluateCoreModulePermission = function (permissionName, transitionProperty) {
            var installationId = extractInstallationIdFromOptions(transitionProperty);
            return UserService.getPermissionByInstallationId(installationId).indexOf(permissionName) >= 0 && isModuleEnabled('INTRUSIONE-INCENDIO', installationId);
        };
        var evaluateSchedulerModulePermission = function (permissionName, transitionProperty) {
            // TODO improve taking data from api instead of statically defining
            var installationId = extractInstallationIdFromOptions(transitionProperty);
            return UserService.getPermissionByInstallationId(installationId).indexOf(permissionName) >= 0 && isModuleEnabled('SCHEDULER', installationId);
        };
        var evaluateAccesscontrolModulePermission = function (permissionName, transitionProperty) {
            // TODO improve taking data from api instead of statically defining
            var installationId = extractInstallationIdFromOptions(transitionProperty);
            return UserService.getPermissionByInstallationId(installationId).indexOf(permissionName) >= 0 && isModuleEnabled('ACCESS-CONTROL', installationId);
        };
        var evaluateStaffmanagementModulePermission = function (permissionName, transitionProperty) {
            // TODO che te lo dico a fare
            var installationId = extractInstallationIdFromOptions(transitionProperty);
            return UserService.getPermissionByInstallationId(installationId).indexOf(permissionName) >= 0 && isModuleEnabled('STAFF-MANAGEMENT', installationId);
        };
        var evaluateNewSuiteModulePermission = function (permissionName, transitionProperty) {
            // TODO dafuq sono questi todo
            // TODO rimuovere i TODO prima di aggiungere altri TODO
            var installationId = extractInstallationIdFromOptions(transitionProperty);
            var isSuitePermissionEnabled = (
                UserService.getPermissionByInstallationId(installationId).indexOf('NEW-GUI') >= 0 ||
                UserService.getPermissionByInstallationId(installationId).indexOf('NEW-QUERIES') >= 0 ||
                UserService.getPermissionByInstallationId(installationId).indexOf('ACCESS-CONTROL') >= 0
            );
            var isSuiteModuleEnabled = (
                isModuleEnabled('NEW-GUI', installationId) ||
                isModuleEnabled('NEW-QUERIES', installationId) ||
                isModuleEnabled('ACCESS-CONTROL', installationId)
            );
            return isSuitePermissionEnabled && isSuiteModuleEnabled;
        };
        // work around to hide/show ui element based on module
        PermPermissionStore.definePermission('SCHEDULER', isModuleEnablePermissionFn);
        PermPermissionStore.definePermission('MENU-UTENTI', allUser);
        PermPermissionStore.definePermission('MENU-INSTALLATORI', isAGlobalAdmin);
        PermPermissionStore.definePermission('BUTTON-INSTALLAZIONI-AGGIUNGI', onlyForManager);
        PermPermissionStore.definePermission('BUTTON-CLIENTI-AGGIUNGI', onlyForManager);
        PermPermissionStore.definePermission('BUTTON-CLIENTI-MODIFICA', onlyForManager);
        PermPermissionStore.definePermission('BUTTON-CLIENTI-ELIMINA', onlyForManager);
        PermPermissionStore.definePermission('UTENTI-AGGIUNGI', isAdmin);
        PermPermissionStore.definePermission('BUTTON-UTENTI-ELIMINA', isAdmin);
        PermPermissionStore.definePermission('BUTTON-UTENTI-EDIT', isAdmin);
        PermPermissionStore.definePermission('BUTTON-INSTALLAZIONI-VISUALIZZA_IMPIANTI', evaluateCoreModulePermission);
        PermPermissionStore.definePermission('BUTTON-INSTALLAZIONI-APRIVISTE', evaluateCoreModulePermission);
        PermPermissionStore.definePermission('BUTTON-INSTALLAZIONI-APRIMAPPE', evaluateCoreModulePermission);
        PermPermissionStore.definePermission('BUTTON-INSTALLAZIONI-DASHBOARD', evaluateCoreModulePermission);
        PermPermissionStore.definePermission('BUTTON-INSTALLAZIONI-MODIFICAINSTALLAZIONE', onlyForManager);
        PermPermissionStore.definePermission('BUTTON-INSTALLAZIONI-ELIMINA', evaluatePermission);
        PermPermissionStore.definePermission('BUTTON-INSTALLAZIONI-UPLOADMAPPE', evaluatePermission);
        PermPermissionStore.definePermission('BUTTON-IMPIANTI-AGGIUNGI', evaluatePermission);
        PermPermissionStore.definePermission('BUTTON-IMPIANTI-MODIFICA', evaluatePermission);
        PermPermissionStore.definePermission('BUTTON-IMPIANTI-MANUTENZIONE', evaluateCoreModulePermission);
        PermPermissionStore.definePermission('TEBE-SMALL-SETTING', evaluateCoreModulePermission);
        PermPermissionStore.definePermission('BUTTON-IMPIANTI-ELIMINA', evaluatePermission);
        PermPermissionStore.definePermission('BUTTON-IMPIANTI-UPLOAD', evaluatePermission);
        PermPermissionStore.definePermission('GROUP-MANAGEMENT', evaluatePermission);
        PermPermissionStore.definePermission('MANUTENZIONEIMPIANTO-COMANDI', evaluateCoreModulePermission);
        PermPermissionStore.definePermission('VISTE-COMANDI', evaluateCoreModulePermission);
        PermPermissionStore.definePermission('MANAGE-CALENDAR', evaluateSchedulerModulePermission);
        PermPermissionStore.definePermission('SEE-PENDING-NOTIFICATION', evaluateSchedulerModulePermission);
        PermPermissionStore.definePermission('NOTIFICATION-HISTORY', evaluateSchedulerModulePermission);
        PermPermissionStore.definePermission('MANAGE-PLANT-NOTIFICATION-FILTER', evaluateSchedulerModulePermission);
        PermPermissionStore.definePermission('MANAGE-HISTORY-FILTER', evaluateCoreModulePermission);
        PermPermissionStore.definePermission('ACCESS-CONTROL', evaluateAccesscontrolModulePermission);
        PermPermissionStore.definePermission('SCHEDULER-RULE-EDIT', evaluateSchedulerModulePermission);
        PermPermissionStore.definePermission('STAFF-MANAGEMENT', evaluateStaffmanagementModulePermission);
        PermPermissionStore.definePermission('HICLOUD-SUITE', evaluateNewSuiteModulePermission);
    });
