angular.module('views')
    .directive('viewQuery', function () {
        return {
            restrict: 'AEC',
            templateUrl: 'app/views/view/view.tpl.html',
            scope: {
                installationId: '=',
                selectedView: '=',
                plants: '=',
                viewFilters: '=?'
            },
            controller: function ($filter, $rootScope, $window, $uibModal, $log, $scope, BASE_PATH, $location, modalService, socketService, ViewPluginService, $http, $state, toastr, UserService, blockUI,uiGridExporterConstants) {
                $scope.isDashboard=true;
                function isInSTRlistFilter(toBeChecked, stringList) {
                    toBeChecked = toBeChecked.toLowerCase();
                    stringList = stringList.toLowerCase().split(/[,]/);
                    return stringList.reduce(function (acc, item) {
                        return acc || toBeChecked.includes(item.trim().toLowerCase());
                    }, false);
                }

                $scope.popup1 = {
                    opened: false
                };

                $scope.open1 = function () {
                    $scope.popup1.opened = true;
                };
                $scope.timespan = [
                    {hours: 2, description: '2 ore'},
                    {hours: 4, description: '4 ore'},
                    {hours: 6, description: '6 ore'}
                ];

                $scope.$watch('filter.selectedPlant', function (value) {
                    if (value && !angular.isString(value)) {
                        $scope.timespan.push({hours: 24, description: '1 giorno'});
                        $scope.timespan.push({hours: 48, description: '2 giorni'});
                    } else {
                        if ($scope.timespan.length === 5) {
                            $scope.timespan.pop();
                            $scope.timespan.pop();
                            $scope.filter.selectedTimeSpan = $scope.timespan[0].hours;
                            $scope.updateFilter($scope.filter.dateFrom, $scope.filter.selectedTimeSpan);
                        }
                    }
                });
                $scope.filters = {};
                if ($scope.viewFilters) {
                    try {
                        $scope.viewFilters = decodeURIComponent(escape(window.atob($scope.viewFilters)));
                        angular.forEach(($scope.viewFilters).split('|'), function (filter, index) {
                            var items = filter.split('(');
                            var filterValues = items.pop().replace(/“/g, '').replace(/"/g, '').replace(')', '').split(',');
                            var filterName = items.pop().replace(/“/g, '').replace(/"/g, '').replace(/”/g, '');
                            $scope.filters[filterName] = filterValues;
                         });
                    } catch (e) {
                        $location.path('installations/');
                    }
                 }
            var today = new Date();
            today.setHours(today.getHours() - 1);
            $scope.filter = {
                selectedTimeSpan: $scope.timespan[0].hours,
                dateTo: new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours() + $scope.timespan[0].hours, today.getMinutes(), today.getSeconds()),
                dateFrom: today,
                hourFrom: today.getHours(),
                minuteFrom: today.getMinutes()
            };
            $scope.dateOptions = {
                formatYear: 'yyyy',
                maxDate: new Date(),
                startingDay: 1
            };
            $scope.updateFilter = function (dateFrom, selectedHour) {
                $scope.filter.dateTo = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate(), dateFrom.getHours() + selectedHour, dateFrom.getMinutes(), dateFrom.getSeconds());
            };
            $scope.changeDateFrom=function(dateFrom, hourFrom, minuteFrom){
                if (angular.isDefined(hourFrom) && angular.isDefined(minuteFrom)) {
                    if (hourFrom < 0 || hourFrom > 23) {
                        hourFrom = 0;
                        $scope.filter.hourFrom = 0;
                    }
                    if (minuteFrom < 0 || minuteFrom > 59) {
                        minuteFrom = 0;
                        $scope.filter.minuteFrom = 0;
                    }
                    dateFrom.setHours(hourFrom);
                    dateFrom.setMinutes(minuteFrom);
                }
                if (dateFrom) {
                    $scope.updateFilter(dateFrom, $scope.filter.selectedTimeSpan);
                }
            };
            $scope.changeTimespan = function (selectedTimeSpan) {
                $scope.updateFilter($scope.filter.dateTo, selectedTimeSpan);
            };
            /**
             * @description return the color of the view based on counters value
             * @param {viewQuery} v the view to be colored
             * @param {Number} v.Id the id of the view
             * @param {String} v.WebColorWithRows  color to use if counter > 0
             * @param {String} v.WebColorWithNoRows  color to use if counter <= 0
             *
             */
            $scope.getColorForView = function (v) {
                var counter;
                if (angular.isUndefined($scope.queryCounters) || angular.isUndefined(v)) {
                    //FIXME do not render if loading is not completed
                    return '';
                }
                counter = $scope.queryCounters[v.Id];
                if (angular.isDefined(counter)) {
                    if (counter > 0) {
                        return v.WebColorWithRows;
                    } else {
                        return v.WebColorWithNoRows;
                    }
                } else {
                    //TODO insert a default
                    return '';
                }
            };

            /**
             * @param {viewQuery} v the view to be colored
             * @param {Number} v.WebIcon char to get
             *
             */
            $scope.getCharFromIconCode = function (v) {
                return String.fromCharCode(v.WebIcon);
            };
            /**
             * @param {viewQuery} v the view to be colored
             * @param {Number} v.Id the id of the view
             *
             */
            $scope.navigateToView = function (v) {
                $location.path('installation/' + $scope.installationId + '/view/' + v.Id);
            };
            $scope.isSelectedView = function (v) {
                return v.Id === $scope.selectedView;
            };

            /**
             * On Grid Row selection Handler
             * @param  {object} entity [description]
             * @param  {object} query  [description]
             */
            $scope.onItemSelection = function (entity, query) {
                // $log('on item selection handling.');
                // clean current Selected Items List
                query.selectedItemsCommand = 'LOADING';
                $scope.querySelected = query.Id;
                $scope.getCommand(entity, query);
                $scope.getMapSymbol(entity, query);
            };

            /**
             * Clean command list by given querylist
             */
            $scope.cleanCommandList = function (item, q) {
                q.selectedItemsCommand = [];
                q.mapUrl = null;
            };
            /**
             * Initiate Ws call for command string - command key is contained in selected item at specified field
             * @param  {object} item      [description]
             * @param  {object} queryList [description]
             */
            $scope.getCommand = function (item, queryList) {
                var qL = queryList;
                var command = item.item[queryList.commandColumnKey];
                if (command) {
                    ViewPluginService.getCommand(command, $scope.installationId).then(function (data) {
                        qL.selectedItemsCommand = data;
                    }, function () {
                        qL.selectedItemsCommand = 'ERROR';
                    });
                } else {
                    // no command string available
                    qL.selectedItemsCommand = [];
                }
            };
            $scope.getMapSymbol = function (item, queryList) {
                var qL = queryList;
                var command = item[queryList.commandColumnKey];
                if (command) {
                    ViewPluginService.getMapSymbol(command, $scope.installationId).then(function (data) {
                        try {
                            data.r_hierarchy = data.r_hierarchy.split('.');
                            qL.maSymbolParent = data.r_hierarchy[data.r_hierarchy.length - 1];
                            qL.mapSymbol = data.r_symbol;
                            qL.mapUrl = $state.href('standAloneMap', {
                                installationId: $scope.installationId,
                                mapId: qL.maSymbolParent
                            });
                            if (qL.mapUrl) {
                                qL.mapUrl = qL.mapUrl + '?symbolId=' + qL.mapSymbol;
                            }
                        } catch (e) {
                            qL.mapUrl = undefined;
                        }
                    }).catch(function (error) {
                        $log.error(error);
                        qL.mapUrl = undefined;
                    });
                } else {
                    qL.mapUrl = undefined;
                }
            };
            $scope.goToMap = function (url, id) {
                $window.open(url, '_blank_' + id, 'top=20');
            };
            /**
             * Send command object to gemss
             */
            $scope.sendCommand = function (item, command) {
                var destinationRole = item.item['r_command'].split('|')[0],
                    plantId = item.item['r_plantid'], descrizione = item.item['r_plantdescription'];
                if (angular.isDefined(plantId) && destinationRole) {
                    $log.info('send command', command['r_protocol'], plantId);
                    return ViewPluginService.sendCommand(command['r_protocol'], plantId, destinationRole, $scope.installationId, {'PLANT_DESCRIPTION': descrizione});
                } else {
                    throw new Error('no plantid for item command');
                }
            };

            /********************************************/
            /* Funzioni di gestione tabella responsiva  */
            /* (Mauro, 21 settembre 2020)               */
            /********************************************/
            if ($location.absUrl().toLowerCase().indexOf('views') < 0)
                $scope.leftMenu = false;
            else
                $scope.leftMenu = true;
            $scope.windowWidth = window.innerWidth;
            $scope.respselectionvalue = 0;
            $scope.querySelected = 0;
            $scope.respselectionFilters = [];
            $scope.numFilteredItems = 0;
            $scope.respsortView = [];
            $scope.numViewColumns = 1;
            $scope.historyEvent = [];
            $scope.historyRealtime = false;
            $scope.numItemsSelection = [
                {
                    num: 10,
                    desc: '10 elementi'
                },
                {
                    num: 20,
                    desc: '20 elementi'
                },
                {
                    num: 40,
                    desc: '40 elementi'
                },
                {
                    num: 50,
                    desc: '50 elementi'
                },
                {
                    num: 100,
                    desc: '100 elementi'
                }]; // Angular js ama le tendine fatte con oggetti complessi
            $scope.numItemViews = $scope.numItemsSelection[3];

            appWindow = angular.element($window);

            appWindow.on('resize', function () {
                $scope.windowWidth = window.innerWidth;
                $scope.$apply();
            });

            $scope.getSelectionButtonDetails = function (value) {
                if (value != $scope.respselectionvalue) {
                    $scope.respselectionvalue = value;

                    json = value;

                if (json.item.r_number >= 0) {
                    $scope.onItemSelection(json, $scope.currentView.Queries[ json.gemssQueryIndex ]);
                    $scope.currentView.Queries[ json.gemssQueryIndex ].selectedItems[ 0 ] = json;
                } else {
                    $scope.cleanCommandList(json, $scope.currentView.Queries[ json.gemssQueryIndex ]);
                    $scope.currentView.Queries[ json.gemssQueryIndex ].selectedItems = [];
                }
            }
            else {
                $scope.respselectionvalue = 0;
                $scope.cleanCommandList(json, $scope.currentView.Queries[ json.gemssQueryIndex ]);
                $scope.currentView.Queries[ json.gemssQueryIndex ].selectedItems = [];
            }
        };
        $scope.manageSelectionFilters = function (view, field, value) {
            idview = view.Id;
            datum = view.tableoptions.data;
            field = field.toLowerCase();
            dato = {
                'idview': idview,
                'field': field,
                'value': value
            };

                //console.log ("Manage " + idview);

                // es6
                //found = $scope.respselectionFilters.find(x => x.idview == dato.idview && x.field == dato.field);

                // es5
                found = $scope.respselectionFilters.find(function (x) {
                    return x.idview == dato.idview && x.field == dato.field;
                  });

                if (!found || $scope.respselectionFilters.length == 0) {
                    $scope.respselectionFilters.push(dato);
                }
                else {
                    if (dato.value.length > 0) {
                        //$scope.respselectionFilters.find(x => x.idview == dato.idview && x.field == dato.field).value = dato.value;
                        // ES5
                        $scope.respselectionFilters.find(function (x) {
                            return x.idview == dato.idview && x.field == dato.field;
                          }).value = dato.value;
                    } else {
                        //$scope.respselectionFilters = $scope.respselectionFilters.filter(x => x.idview != dato.idview || x.field != dato.field);
                        // ES5
                        $scope.respselectionFilters = $scope.respselectionFilters.filter(function (x) {
                            return x.idview != dato.idview || x.field != dato.field;
                          });
                    }
                }

                $rootScope.$emit('CallApplySelectionFilters', {
                    idview: idview,
                    datum: datum
                  });
                view.tableoptions.filtered = $rootScope.filtered;
                $scope.numFilteredItems  = view.tableoptions.filtered.length;

                /* Disabilito comandi quando si eseguono filtri perché potrei nascondere il record selezionato */
            if ($scope.respselectionvalue != 0) {
                $scope.cleanCommandList($scope.respselectionvalue, $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ]);
                $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ].selectedItems = [];
                $scope.respselectionvalue = 0;
            }
        };
        $scope.searchSelectionFilters = function (idview) {
            // Funzione non implementata, qui sarà la logica del bottone di ricerca quando si vorrà farlo apparire
            target = document.getElementsByName('fTT_' + idview);
            //console.log("Filtri: ", $scope.respselectionFilters);
        };
        $scope.resetSelectionFilters = function (idview) {
            target = document.getElementsByName('fTT_' + idview);
            /*target.forEach(element => {
                element.value = "";
            });*/
            // ES5
            target.forEach(function (element) {
                element.value = '';
              });
            $scope.respselectionFilters = [];
        };
        $scope.displaySelectionFilters  = function (view) {
            if (view.respViewFilters == 'display: block;')
                view.respViewFilters = 'display: none;';
            else
                view.respViewFilters = 'display: block;';

            $rootScope.$emit('CallApplySelectionFilters', {
                idview: idview,
                datum: datum
              });
            view.tableoptions.filtered = $rootScope.filtered;
            $scope.numFilteredItems  = view.tableoptions.filtered.length;

            /* Disabilito comandi quando si eseguono filtri perché potrei nascondere il record selezionato */
            if ($scope.respselectionvalue != 0) {
                $scope.cleanCommandList($scope.respselectionvalue, $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ]);
                $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ].selectedItems = [];
                $scope.respselectionvalue = 0;
            }
        };
        $scope.searchSelectionFilters = function (idview) {
            // Funzione non implementata, qui sarà la logica del bottone di ricerca quando si vorrà farlo apparire
            target = document.getElementsByName('fTT_' + idview);
            //console.log('Filtri: ', $scope.respselectionFilters);
        };
        $scope.resetSelectionFilters = function (view) {
            target = document.getElementsByName('fTT_' + idview);
            /*target.forEach(element => {
                element.value = '';
            });*/
            // ES5
            target.forEach(function (element) {
                element.value = '';
              });
            $scope.respselectionFilters = [];

                idview = view.Id;
                datum = view.tableoptions.data;
                $rootScope.$emit('CallApplySelectionFilters', {
                    idview: idview,
                    datum: datum
                  });
                view.tableoptions.filtered = $rootScope.filtered;
                $scope.numFilteredItems  = view.tableoptions.filtered.length;

                /* Disabilito comandi quando si eseguono filtri perché potrei nascondere il record selezionato */
            if ($scope.respselectionvalue != 0) {
                $scope.cleanCommandList($scope.respselectionvalue, $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ]);
                $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ].selectedItems = [];
                $scope.respselectionvalue = 0;
            }
        };
        $scope.displaySelectionFilters  = function (view) {
            if (view.respViewFilters == 'display: block;')
                view.respViewFilters = 'display: none;';
            else
                view.respViewFilters = 'display: block;';

                $scope.respselectionFilters = [];
            };
            $scope.manageSortingView = function(view, field) {
                idview = view.Id;
                datum = view.tableoptions.data;
                field = field.toLowerCase();
                dato = {
                    'idview': idview,
                    'field': field,
                    'order': null
                };

                //console.log("Sorting view " + idview);
                //found = $scope.respsortView.find(x => x.idview == dato.idview);
                // ES5
                found = $scope.respsortView.find(function (x) {
                    return x.idview == dato.idview;
                  });

                if (!found || $scope.respsortView.length == 0) {
                    dato.order = 'DESC';
                    $scope.respsortView.push(dato);
                }
                else {
                    if (found.field == dato.field) {
                        if (found.order == 'ASC')
                            dato.order = 'DESC';
                        else
                            dato.order = 'ASC';
                        //$scope.respsortView.find(x => x.idview == dato.idview).order = dato.order;
                        // ES5
                        $scope.respsortView.find(function (x) {
                            return x.idview == dato.idview;
                          }).order = dato.order;
                    } else {
                        dato.order = 'DESC';
                        //$scope.respsortView = $scope.respsortView.filter(x => x.idview != dato.idview);
                        // ES5
                        $scope.respsortView = $scope.respsortView.filter(function (x) {
                            return x.idview != dato.idview;
                          });
                        $scope.respsortView.push(dato);
                    }
                }

                $rootScope.$emit('CallApplySelectionFilters', {
                    idview: idview,
                    datum: datum
                  });
                view.tableoptions.filtered = $rootScope.filtered;
                $scope.numFilteredItems  = view.tableoptions.filtered.length;

                /* Disabilito comandi quando si eseguono filtri perché potrei nascondere il record selezionato */
            if ($scope.respselectionvalue != 0) {
                $scope.cleanCommandList($scope.respselectionvalue, $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ]);
                $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ].selectedItems = [];
                $scope.respselectionvalue = 0;
            }
        };
        $scope.exportCsvView = function(query, data) {
            var table = document.getElementById('table_' + query.Id);
            var csvString = '';

                // Prendo solo il primo record della tabella, quello con il nome dei campi.
                i = 0;
                var rowData = table.rows[i].cells;
                for(var j=1; j<rowData.length;j++){
                    s = rowData[j].innerText.trim();

                    csvString = csvString + s + ',';
                }
                csvString = csvString.substring(0,csvString.length - 1);
                csvString = csvString + '\n';

                for(var i=0; i<data.length;i++) {
                    for(var j=1; j<rowData.length;j++){
                        s2 = rowData[j].title.trim().toLowerCase();
                        x = data[i].item[s2];

                        if (x == null)
                            x = '';
                        s = x.toString().trim();
                        csvString = csvString + s + ',';
                    }

                    csvString = csvString.substring(0,csvString.length - 1);
                    csvString = csvString + '\n';
                }

                var a = $('<a/>', {
                    style:'display:none',
                    href:'data:application/octet-stream;base64,'+btoa(csvString),
                    download: query.Title + '.csv'
                }).appendTo('body');
                a[0].click();
                a.remove();
            };
            $scope.visibleColumn = function(columnsAllowed, columnName) {
                var ret = true;

                found = columnsAllowed.find(function (x) {
                    return x.MappingName.toLowerCase() == columnName.toLowerCase();
                });

                if (!found)
                    ret = false;

                return ret;
            };
            $scope.historyRealtimeSelection = function(view) {
                view.tableoptions = {
                    data: [],
                    filtered: [],
                    command: false
                 };

                if ($scope.historyRealtime) {
                    $scope.historyRealtime = false;
                    $scope.populateViewData();
                }
                else {
                    $scope.historyRealtime = true;

                    angular.forEach($scope.historyEvent, function(hev){
                        dato = {
                            gemssQueryIndex: view.gridOptions.gemssQueryIndex,
                            item: hev
                        };

                        view.tableoptions.data.push(dato);
                        view.tableoptions.filtered.push(dato);
                    });

                    $scope.manageSortingView(view, 'r_datetime');
                }
            };
            /*********************************************************************************************************************************/

            /**
             * populateViewData description
             */
            $scope.populateViewData = function () {
                blockUI.start();
                ViewPluginService.getInstallationViewsInfo($scope.installationId).then(function (instViewObj) {
                    var selectedVIdx = 0;
                    $scope.installationInfo = instViewObj.installationInfo;
                    $scope.views = instViewObj.views;
                    // retrieve selected view index
                    for (var i = $scope.views.length - 1; i >= 0; i--) {
                        if ($scope.isSelectedView($scope.views[i])) {
                            selectedVIdx = i;
                            break;
                        }
                    }
                    /*Retrieve info of Views/Query for current installation*/
                    if ($scope.installationInfo && $scope.views) {
                        $scope.currentView = $scope.views[selectedVIdx];
                        if ($scope.currentView.Queries && $scope.currentView.Queries.length > 0) {
                            /*Retrieve info and data for each query*/
                            $scope.currentView.Queries.forEach(function (query, i) {
                                query.selectedItems = [];
                                query.selectedItemsCommand = [];
                                query.respViewFilters = 'display: none;'; //Responsive design

                                // Data Grid Options
                                query.gridOptions = {
                                    exporterCsvFilename:query.Title+'_'+(new Date().toLocaleDateString()),
                                    gemssQueryIndex: i,
                                    //onRegisterApi: onRegisterApiF,
                                    enableSelectionBatchEvent: false,
                                    enableFiltering: true,
                                    enableRowSelection: true,
                                    enableSelectAll: false,
                                    selectionRowHeaderWidth: 35,
                                    rowHeight: 35,
                                    showGridFooter: true,
                                    columnDefs: [],
                                    selectedItems: query.selectedItems,
                                    multiSelect: false,
                                    enableRowHeaderSelection: true,
                                    enableColumnResize: true,
                                    filterOptions: {
                                        filterText: '',
                                        useExternalFilter: false
                                    },
                                    afterSelectionChange: function (data, a, b, c) {
                                        $log.log('selected data change');
                                    },
                                    showFilter: true,
                                    data: []
                                };

                                /************************************************/
                                /* Inizializzazione gestione tabella responsiva */
                                /* (Mauro, 4 settembre 2020)                    */
                                /************************************************/
                                query.tableoptions = {
                                    data: [],
                                    filtered: [],
                                    command: false
                                };

                                /* Se tra le colonne è presente r_number significa che posso inviare comandi */
                                commandPresent = query.Columns.filter(function(quer){
                                    return quer.MappingName === 'r_number';}).length;
                                query.tableoptions.command = (commandPresent > 0);
                                $scope.numViewColumns = query.Columns.filter(function(quer){
                                    return (quer.MappingName != 'r_key' && quer.MappingName != 'r_command');}).length;

                                $scope.currentView.Queries[ i ].selectedItemsCommand = '';

                                query.gridOptions.onRegisterApi = function (gridApi) {
                                    //set gridApi on scope
                                    query.gridApi = gridApi;
                                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                                        var msg = 'row selected ' + row.isSelected;
                                        $log.log(msg);
                                        if (row.isSelected) {
                                            $scope.onItemSelection(row.entity, $scope.currentView.Queries[this.grid.options.gemssQueryIndex]);
                                            $scope.currentView.Queries[this.grid.options.gemssQueryIndex].selectedItems[0] = row.entity;
                                        } else {
                                            $scope.cleanCommandList(row.entity, $scope.currentView.Queries[this.grid.options.gemssQueryIndex]);
                                            $scope.currentView.Queries[this.grid.options.gemssQueryIndex].selectedItems = [];//todo add close  dropdown
                                        }
                                    });
                                    query.exportCsv=function(){
                                        var rowTypes = uiGridExporterConstants.ALL;
                                        var colTypes = uiGridExporterConstants.ALL;
                                        query.gridApi.exporter.csvExport( rowTypes, colTypes );
                                    };

                                    gridApi.selection.on.rowSelectionChangedBatch($scope, function (rows) {
                                        var msg = 'rows changed ' + rows.length;
                                        $log.log(msg);
                                    });
                                };
                                /*Column formatting data grid*/
                                var createCol = function (colDescr) {
                                    if (colDescr.Command && colDescr.Command) {
                                        query.commandColumnKey = colDescr.MappingName;
                                    }
                                    if (!colDescr.Width) {
                                        return;
                                    }
                                    var columnFormat = {};
                                    colDescr.MappingName = colDescr.MappingName.toLowerCase();
                                    //popolo l'array delle chiavi della query
                                    if (angular.isString(colDescr.Key) || colDescr.Key instanceof String) {
                                        query.filterKeys.push(colDescr.MappingName);
                                    }
                                    /*Set Sorting Alg for column*/
                                    columnFormat.sortingAlgorithm = function (a, b, rowA, rowB, direction) {
                                        var nulls = rowA.grid.api.core.sortHandleNulls(a, b);
                                        if (nulls !== null) {
                                            return nulls;
                                        } else {

                                            if (angular.isString(a)) {
                                                if (a > b) {
                                                    return 1;
                                                } else {
                                                    if (a === b) {
                                                        return 0;
                                                    } else {
                                                        return -1;
                                                    }
                                                }
                                            } else {
                                                if (angular.isNumber(a)) {
                                                    if (a > b) {
                                                        return 1;
                                                    } else {
                                                        if (a === b) {
                                                            return 0;
                                                        } else {
                                                            return -1;
                                                        }
                                                    }
                                                }
                                            }

                                        }
                                    };
                                    //POPOLAMENTO COLONNE
                                    columnFormat.field = colDescr.MappingName;
                                    /* DATE TIME COLUMN FORMAT / formattazione datetime*/
                                    if (colDescr.format && colDescr.format === 'DATETIME') {
                                        columnFormat.cellFilter = 'date:\'dd/MM/yyyy H:mm:ss\'';
                                    }
                                    if (colDescr.HeaderText) {
                                        columnFormat.displayName = colDescr.HeaderText;
                                    }
                                    if (colDescr.Width !== 0) {
                                        columnFormat.width = colDescr.Width;
                                    }
                                    var colFilterValues=$scope.filters[columnFormat.displayName];
                                    if(colFilterValues && (query.gridOptions.enableFiltering||columnFormat.enableFiltering)) {
                                        /* Gestione filtri da querystring con tabella */
                                        var f = '';
                                        angular.forEach(colFilterValues, function (x, index) {
                                            f = f + x + '|';
                                        });

                                        if (f.length > 0)
                                            f = f.substring(0, f.length - 1);

                                        dato = {
                                            'idview': query.Id,
                                            'field': columnFormat.field,
                                            'value': f
                                        };

                                        found = $scope.respselectionFilters.find(function (x) {
                                            return x.idview == dato.idview && x.field == dato.field;
                                        });

                                        if (!found)
                                            $scope.respselectionFilters.push(dato);
                                        colDescr.filterValue = f.replace('|', '/');
                                        /* Fine gestione filtri da querystring con tabella */

                                        columnFormat.filterHeaderTemplate= '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters"><div custom-filter-dropdown></div></div>';
                                            columnFormat.filter = {
                                                options: colFilterValues.map(function (item) {
                                                    return {id: item, value: item};
                                                })
                                            };
                                        if (columnFormat.filter.options.length){
                                            columnFormat.filter.term=columnFormat.filter.options[0].id;
                                        }
                                    }
                                    else {
                                        colDescr.filterValue = '';
                                    }
                                    query.gridOptions.columnDefs.push(columnFormat);
                                };
                                for (var j in query.Columns) {
                                    createCol(query.Columns[j]);
                                }

                                function isInSTRlistFilter(toBeChecked, stringList) {
                                    toBeChecked = toBeChecked.toLowerCase();
                                    stringList = stringList.toLowerCase().split(/[,]/);
                                    return stringList.reduce(function (acc, item) {
                                        return acc || toBeChecked.includes(item.trim().toLowerCase());
                                    }, false);
                                }


                                /*Data Retrieve*/
                                if (query.Data && query.Data.Url) {
                                    ViewPluginService.getQueryData($scope.installationId, query, UserService.getGroupIdByInstallationId($scope.installationId), $scope.filter.dateTo, $scope.filter.dateFrom, ($scope.filter.selectedPlant || {}).MAC).then(function (result) {
                                        var excludeFilter = UserService.getGroupSettingsByInstallationId($scope.installationId).history_exclude_filter;
                                        if (query.Data.Url === './query/events') {
                                            query.gridOptions.data = [];
                                            angular.forEach(result, function (item) {
                                                if (excludeFilter) {
                                                    if (!isInSTRlistFilter(item.r_description, excludeFilter) && !isInSTRlistFilter(item.r_info, excludeFilter)) {
                                                        query.gridOptions.data.push(item);

                                                        // Oggetto per tabella responsiva
                                                        dato = {
                                                            gemssQueryIndex: query.gridOptions.gemssQueryIndex,
                                                            item: item,
                                                            r_key: item.r_key,
                                                            r_plantid: item.r_plantid,
                                                            r_number: item.r_number,
                                                            r_description: item.r_description,
                                                            r_command: item.r_command
                                                        };
                                                        query.tableoptions.data.push(dato);
                                                        query.tableoptions.filtered.push(dato);
                                                    }
                                                } else {
                                                    // Oggetto per tabella responsiva
                                                    dato = {
                                                        gemssQueryIndex: query.gridOptions.gemssQueryIndex,
                                                        item: item,
                                                        r_key: item.r_key,
                                                        r_plantid: item.r_plantid,
                                                        r_number: item.r_number,
                                                        r_description: item.r_description,
                                                        r_command: item.r_command
                                                    };
                                                    query.tableoptions.data.push(dato);
                                                    query.tableoptions.filtered.push(dato);
                                                }
                                            });
                                        } else {
                                            query.gridOptions.data = result;

                                            result.forEach(function (element) {
                                                dato = {
                                                  gemssQueryIndex: query.gridOptions.gemssQueryIndex,
                                                  item: element,
                                                  r_key: element.r_key,
                                                  r_plantid: element.r_plantid,
                                                  r_number: element.r_number,
                                                  r_description: element.r_description,
                                                  r_command: element.r_command
                                                };
                                                query.tableoptions.data.push(dato);
                                                query.tableoptions.filtered.push(dato);
                                            });
                                        }

                                        /* Applico i filtri iniziali sui dati */
                                        if ($scope.viewFilters) {
                                            /* Se ci sono i filtri preimpostati non mostro il menù di sinistra */
                                            $scope.leftMenu = false;
                                            $rootScope.$emit('CallApplySelectionFilters', {
                                                idview: query.Id,
                                                datum: query.tableoptions.data
                                            });
                                            query.tableoptions.filtered = $rootScope.filtered;
                                            $scope.numFilteredItems  = query.tableoptions.filtered.length;
                                        }

                                        // Gestione ordinamento iniziale //
                                        // (MD, 13/11/2020 - 19/11/2020)
                                        var ord = 'r_description';
                                        if (query.tableoptions.filtered.length > 0)  {
                                            itm = query.tableoptions.filtered[0].item;

                                            if (itm.r_surnamename !== undefined) {
                                                ord = 'r_surnamename';
                                            } else {
                                                if (itm.r_datetime !== undefined)
                                                    ord = 'r_datetime';
                                            }
                                        }

                                        $scope.manageSortingView(query, ord);

                                        blockUI.stop();
                                    }).catch(function (error) {
                                        blockUI.stop();
                                        $log.error(error);
                                    });
                                }
                            });
                        }
                    }
                    return $http.get(BASE_PATH.API_URL + 'company/' + $scope.installationInfo.customerId);
                }).then(function (response) {
                    $scope.subribedTopic = socketService.subscribe('Installation.' + $scope.installationId);
                    $scope.customerInfo = response.data;
                }).catch(function (error) {
                    blockUI.stop();
                    $log.error(error);
                });
            };
            /*Opens New Tab with Cloud90*/
            $scope.managePlant = function (p) {
                // eslint-disable-next-line angular/window-service
                window.open(window.location.origin + window.location.pathname + '/#/plant/' + p.MAC, '_blank');
            };

            $scope.editPlant = function (p) {
                var modalInstance = $uibModal.open({
                    animation: $scope.animationsEnabled,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'app/plants/editPlant/editPlant.tpl.html',
                    controller: 'EditPlantModalCtrl',
                    resolve: {
                        plantToEdit: function () {
                            return p;
                        },
                        installationId: function () {
                            return $scope.installationId;
                        }
                    }
                });
                modalInstance.result.then(function (selectedItem) {
                    $log.info(selectedItem);
                    $scope.getPlants();
                }, function (result) {
                    $log.info(result);
                    $log.info('Modal dismissed at: ' + new Date());
                });
            };

            var lowerEventRowFieldsIds = function (event) {
                for (var i = event.Values.length - 1; i >= 0; i--) {
                    event.Values[i].Key = event.Values[i].Key.toLowerCase();
                }
            };
            var socketListener = $rootScope.$on('socket:view', function (event, data) {
                try {
                    var toRemoveItemList,
                        toRemoveItem,
                        removeIndex,
                        qId,
                        selectedQuery = undefined,
                        queryData, i, j,
                        expression = {};
                    $log.debug('incoming message from ws service', data);

                    /* Non ci fidiamo e cancelliamo la selezione se arrivano degli eventi */
                    /* MD, 5/10/2020
                    /* (commentato il 28/10/2020 perchè impedisce il corretto funzionamento dei comandi se eventi molto frequenti) */
                    /*
                    if ($scope.respselectionvalue != 0) {
                        $scope.cleanCommandList($scope.respselectionvalue, $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ]);
                        $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ].selectedItems = [];
                        $scope.respselectionvalue = 0;
                    }
                    */

                    if (!data.installationId || data.installationId != $scope.installationId) {
                        $log.error('Rejected message from ws service, no match with current installation', data);
                        return;
                    }
                    data.data.forEach(function (eventData) {
                        $log.debug('update event : ', eventData);
                        if (!eventData.ViewId) {
                            return;
                        }
                        /* Update counter logic*/
                        if (!eventData.Type) {
                            return;
                        }
                        /**
                         * Current Queries update
                         */
                        if (eventData.ViewId === $scope.currentView.Id) {
                            qId = eventData.QueryId;
                            for (i = $scope.currentView.Queries.length - 1; i >= 0; i--) {
                                if ($scope.currentView.Queries[i].Id == qId) {
                                    selectedQuery = $scope.currentView.Queries[i];
                                    break;
                                }
                            }
                            if (angular.isUndefined(selectedQuery) || angular.isUndefined(selectedQuery.gridOptions) || angular.isUndefined(selectedQuery.gridOptions.data)) {
                                return;
                            }
                            //queryData = selectedQuery.gridOptions.data;
                            queryData = selectedQuery.tableoptions.filtered; // MD, 5/10/2020

                            lowerEventRowFieldsIds(eventData);
                            var objToAdd = {};
                            var matchItems;
                            switch (eventData.Type) {
                                case 'ADD':
                                    try {
                                        if (!eventData.Values) {
                                            $log.error('update request with no data');
                                            throw 'update request with no data';
                                        }
                                        for (j in eventData.Values) {
                                            objToAdd[eventData.Values[j].Key] = eventData.Values[j].Value;
                                        }
                                        /*Find element if exists*/
                                        for (i in selectedQuery.Columns) {
                                            if (selectedQuery.Columns[i].Key) {
                                                $log.debug(selectedQuery.Columns[i].Key, selectedQuery.Columns[i]);
                                                expression[selectedQuery.Columns[i].MappingName] = eventData.RowKey.toLowerCase();
                                                break;
                                            }
                                        }
                                        //toRemoveItemList = $filter('filter')(queryData, expression, true);

                                        // MD, 6/10/2020 (.item)
                                        toRemoveItemList = queryData.filter(function (x) {
                                            keyObj1 = Object.keys(x.item);
                                            keyObj2 = Object.keys(expression);
                                            valueObj1 = Object.values(x.item);
                                            valueObj2 = Object.values(expression);

                                            return x.item[keyObj2[0]] == valueObj2[0];
                                        });

                                        findCompleteItemList = selectedQuery.tableoptions.data.filter(function (x) {
                                            keyObj1 = Object.keys(x.item);
                                            keyObj2 = Object.keys(expression);
                                            valueObj1 = Object.values(x.item);
                                            valueObj2 = Object.values(expression);

                                            return x.item[keyObj2[0]] == valueObj2[0];
                                        });

                                        /*replace or insert*/
                                        matchItems = toRemoveItemList.length;
                                        matchItemsComplete = findCompleteItemList.length;
                                        if (matchItems !== 0) {
                                            toRemoveItem = toRemoveItemList[0];
                                            removeIndex = queryData.indexOf(toRemoveItem);
                                            queryData[removeIndex].item = objToAdd; // MD, 5/10/2020 (.item)
                                            itemComplete = findCompleteItemList[0];
                                            indexComplete = selectedQuery.tableoptions.data.indexOf(itemComplete);
                                            selectedQuery.tableoptions.data[indexComplete].item = objToAdd;
                                        } else {
                                            if (matchItems > 1) {
                                                $log.error('multiple matching');
                                                throw 'multiple matching';
                                            } else {
                                                //queryData.push(objToAdd);

                                                // Oggetto per tabella responsiva
                                                dato = {
                                                    gemssQueryIndex: selectedQuery.gridOptions.gemssQueryIndex,
                                                    item: objToAdd
                                                };
                                                //console.log (selectedQuery.tableoptions.data === queryData);
                                                selectedQuery.tableoptions.data.push(dato);
                                                if (selectedQuery.tableoptions.data !== queryData)
                                                    queryData.push(dato);
                                            }
                                        }
                                    } catch (error) {
                                        $log.error(error);
                                    }
                                    break;
                                case 'UPDATE':
                                    try {
                                        if (!eventData.Values) {
                                            $log.error('update request with no data');
                                            throw 'update request with no data';
                                        }
                                        for (j in eventData.Values) {
                                            objToAdd[eventData.Values[j].Key] = eventData.Values[j].Value;
                                        }
                                        /*Find element if exists*/
                                        for (i in selectedQuery.Columns) {
                                            if (selectedQuery.Columns[i].Key) {
                                                $log.debug(selectedQuery.Columns[i].Key, selectedQuery.Columns[i]);
                                                expression[selectedQuery.Columns[i].MappingName] = eventData.RowKey.toLowerCase();
                                                break;
                                            }
                                        }
                                        //toRemoveItemList = $filter('filter')(queryData, expression, true);

                                        // MD, 5/10/2020 (.item)
                                        toRemoveItemList = queryData.filter(function (x) {
                                            keyObj1 = Object.keys(x.item);
                                            keyObj2 = Object.keys(expression);
                                            valueObj1 = Object.values(x.item);
                                            valueObj2 = Object.values(expression);

                                            return x.item[keyObj2[0]] == valueObj2[0];
                                        });

                                        findCompleteItemList = selectedQuery.tableoptions.data.filter(function (x) {
                                            keyObj1 = Object.keys(x.item);
                                            keyObj2 = Object.keys(expression);
                                            valueObj1 = Object.values(x.item);
                                            valueObj2 = Object.values(expression);

                                            return x.item[keyObj2[0]] == valueObj2[0];
                                        });

                                        // Oggetto per tabella responsiva
                                        dato = {
                                            gemssQueryIndex: selectedQuery.gridOptions.gemssQueryIndex,
                                            item: objToAdd
                                        };

                                        /*replace or insert*/
                                        matchItems = toRemoveItemList.length;
                                        if (matchItems !== 0) {
                                            toRemoveItem = toRemoveItemList[0];
                                            itemComplete = findCompleteItemList[0];
                                            // if updating element is selected and has commands array available, clean it all
                                            if (selectedQuery.selectedItems.indexOf(toRemoveItem) !== -1) {
                                                // MD, 5/10/2020
                                                if ($scope.respselectionvalue != 0) {
                                                    $scope.cleanCommandList($scope.respselectionvalue, $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ]);
                                                    $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ].selectedItems = [];
                                                    $scope.respselectionvalue = 0;
                                                }
                                            }
                                            removeIndex = queryData.indexOf(toRemoveItem);
                                            indexComplete = selectedQuery.tableoptions.data.indexOf(itemComplete);
                                            queryData[ removeIndex ] = dato;
                                            selectedQuery.tableoptions.data[indexComplete] = dato;
                                        } else {
                                            if (matchItems > 1) {
                                                //FIXME eliminare alertt
                                                throw 'multiple matching';
                                            } else {
                                                queryData.push(dato);
                                                selectedQuery.tableoptions.data.push(dato);
                                            }
                                        }
                                    } catch (error) {
                                        $log.error(error);
                                    }
                                    break;
                                case 'REMOVE':
                                    /*Find column key and push to search expression*/
                                    try {
                                        for (i in selectedQuery.Columns) {
                                            if (selectedQuery.Columns[i].Key) {
                                                $log.debug(selectedQuery.Columns[i].Key, selectedQuery.Columns[i]);
                                                expression[selectedQuery.Columns[i].MappingName] = eventData.RowKey.toLowerCase();
                                                break;
                                            }
                                        }
                                        //toRemoveItemList = $filter('filter')(queryData, expression, true);

                                        // MD, 5/10/2020 (.item)
                                        toRemoveItemList = queryData.filter(function (x) {
                                            keyObj1 = Object.keys(x.item);
                                            keyObj2 = Object.keys(expression);
                                            valueObj1 = Object.values(x.item);
                                            valueObj2 = Object.values(expression);

                                            return x.item[keyObj2[0]] == valueObj2[0];
                                        });

                                        findCompleteItemList = selectedQuery.tableoptions.data.filter(function (x) {
                                            keyObj1 = Object.keys(x.item);
                                            keyObj2 = Object.keys(expression);
                                            valueObj1 = Object.values(x.item);
                                            valueObj2 = Object.values(expression);

                                            return x.item[keyObj2[0]] == valueObj2[0];
                                        });

                                        // Mantis #425
                                        if (findCompleteItemList.length === 0) {
                                            $log.error('no query item matching with request: ', queryData);
                                            //FIXME
                                            throw 'no query matching';
                                        } else {
                                            if (toRemoveItemList.length > 0) // Mantis #425
                                                toRemoveItem = toRemoveItemList[0];
                                            itemComplete = findCompleteItemList[0];
                                            // if updating element is selected and has commands array available, clean it all
                                            if (toRemoveItemList.length > 0 && selectedQuery.selectedItems.indexOf(toRemoveItem) !== -1) { // Mantis #425
                                                // MD, 5/10/2020
                                                if ($scope.respselectionvalue != 0) {
                                                    $scope.cleanCommandList($scope.respselectionvalue, $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ]);
                                                    $scope.currentView.Queries[ $scope.respselectionvalue.gemssQueryIndex ].selectedItems = [];
                                                    $scope.respselectionvalue = 0;
                                                }
                                            }
                                            if (angular.isDefined(toRemoveItem)) // Mantis #425
                                                removeIndex = queryData.indexOf(toRemoveItem);
                                            else
                                                removeIndex = -1;

                                            indexComplete = selectedQuery.tableoptions.data.indexOf(itemComplete);

                                            if (removeIndex >= 0) // Mantis #425
                                                queryData.splice(removeIndex, 1);

                                            if (queryData !== selectedQuery.tableoptions.data)
                                                selectedQuery.tableoptions.data.splice(indexComplete, 1);
                                        }
                                    } catch (error) {
                                        $log.error(error);
                                    }
                                    break;
                            }

                            /* Applico i filtri per evitare visualizzazioni di dati non previsti */
                            /* MD, 26/10/2020 */
                            $rootScope.$emit('CallApplySelectionFilters', {
                                idview: selectedQuery.Id,
                                datum: selectedQuery.tableoptions.data
                            });
                            selectedQuery.tableoptions.filtered = $rootScope.filtered;
                            $scope.numFilteredItems  = selectedQuery.tableoptions.filtered.length;
                        }
                    });
                } catch (err) {
                    //$scope.eventList.push({ tokens: ['MSG_DECODE_ERR', data]})
                }
                $scope.$apply();
            });

            var com90Socket = $rootScope.$on('socket:com90', function(event, data){
                try{
                    /* Array degli eventi storicizzati */
                    if ($scope.historyEvent.length >= 1000) // TODO: Impostare il valore desiderato
                        $scope.historyEvent.shift();

                    /*********************************************/
                    /* item modificato per gestione in Hicloud 2 */
                    /* (Mauro, 17/02/2021)                       */
                    /*********************************************/
                    item = {
                        r_datetime: data.MSG.dateTime,
                        r_description: data.MSG.eventDesc,
                        r_info: data.MSG.eventInfo,
                        r_plantdescription: data.PLANT_DESCRIPTION,
                        r_plantid: data.PLANT_ID,
                        r_source: data.USERINFO
                    };

                    $scope.historyEvent.push(item);

                    if ($scope.currentView.Title.toLowerCase() === 'storico eventi' &&  $scope.historyRealtime == true) {

                        //$scope.historyRealtime == true
                        // && $scope.currentView.Title.toLowerCase() == 'storico eventi'
                        selectedQuery = $scope.currentView.Queries[0];

                        selectedQuery.tableoptions = {
                            data: [],
                            filtered: [],
                            command: false
                        };

                        angular.forEach($scope.historyEvent, function(hev){
                            dato = {
                                gemssQueryIndex: selectedQuery.gridOptions.gemssQueryIndex,
                                item: hev
                            };

                            selectedQuery.tableoptions.data.push(dato);
                        });

                        /* Applico i filtri per evitare visualizzazioni di dati non previsti */
                        /* MD, 26/10/2020 */
                        $rootScope.$emit('CallApplySelectionFilters', {
                            idview: selectedQuery.Id,
                            datum: selectedQuery.tableoptions.data
                            });
                        selectedQuery.tableoptions.filtered = $rootScope.filtered;
                        $scope.numFilteredItems  = selectedQuery.tableoptions.filtered.length;
                    }

                }catch(err){
                    $log.error(err);
                    //$scope.eventList.push({ tokens: ['MSG_DECODE_ERR', data]})
                }
            });

            var socketListenerRefresh = $rootScope.$on('socket:refresh', function () {
                ViewPluginService.getInstallationViewsCounters($scope.installationId).then(function (counters) {
                    $scope.queryCounters = {};
                    angular.forEach(counters, function (counter) {
                        $scope.queryCounters[counter.viewId] = counter.count;
                    });
                });
                ViewPluginService.getInstallationViewsInfo($scope.installationId).then(function (instViewObj) {
                    var viewToUpdate = instViewObj.views.filter(function (item) {
                        return item.Id === $scope.selectedView;
                    })[0];
                    if (viewToUpdate) {
                        angular.forEach(viewToUpdate.Queries, function (query) {
                            var previusQueryData = $scope.currentView.Queries.filter(function (quer) {
                                return quer.Id === query.Id;
                            })[0];
                            if (previusQueryData) {
                                ViewPluginService.getQueryData($scope.installationId, query, UserService.getGroupIdByInstallationId($scope.installationId)).then(function (result) {
                                    previusQueryData.gridOptions.data = [];
                                    previusQueryData.tableoptions.data = [];
                                    previusQueryData.tableoptions.filtered = [];
                                    var excludeFilter = UserService.getGroupSettingsByInstallationId($scope.installationId).history_exclude_filter;
                                    if (previusQueryData.Data.Url === './query/events') {
                                        angular.forEach(result, function (item) {
                                            if (excludeFilter) {
                                                if (!isInSTRlistFilter(item.r_description, excludeFilter) && !isInSTRlistFilter(item.r_info, excludeFilter)) {
                                                    previusQueryData.gridOptions.data.push(item);

                                                    // Oggetto per tabella responsiva
                                                    dato = {
                                                        gemssQueryIndex: previusQueryData.gridOptions.gemssQueryIndex,
                                                        item: item,
                                                        r_key: item.r_key,
                                                        r_plantid: item.r_plantid,
                                                        r_number: item.r_number,
                                                        r_description: item.r_description,
                                                        r_command: item.r_command
                                                    };
                                                    previusQueryData.tableoptions.data.push(dato);
                                                    previusQueryData.tableoptions.filtered.push(dato);
                                                }
                                            } else {
                                                previusQueryData.gridOptions.data.push(item);

                                                // Oggetto per tabella responsiva
                                                dato = {
                                                    gemssQueryIndex: previusQueryData.gridOptions.gemssQueryIndex,
                                                    item: item,
                                                    r_key: item.r_key,
                                                    r_plantid: item.r_plantid,
                                                    r_number: item.r_number,
                                                    r_description: item.r_description,
                                                    r_command: item.r_command
                                                };
                                                previusQueryData.tableoptions.data.push(dato);
                                                previusQueryData.tableoptions.filtered.push(dato);
                                            }
                                        });
                                    } else {
                                        previusQueryData.gridOptions.data = result;

                                        result.forEach(function (element) {
                                            dato = {
                                              gemssQueryIndex: previusQueryData.gridOptions.gemssQueryIndex,
                                              item: element,
                                              r_key: element.r_key,
                                              r_plantid: element.r_plantid,
                                              r_number: element.r_number,
                                              r_description: element.r_description,
                                              r_command: element.r_command
                                            };
                                            previusQueryData.tableoptions.data.push(dato);
                                            previusQueryData.tableoptions.filtered.push(dato);
                                        });

                                         /* Applico i filtri iniziali sui dati */
                                        /* MD, 14/10/2020                     */
                                        if ($scope.viewFilters) {
                                            /* Se ci sono i filtri preimpostati non mostro il menù di sinistra */
                                            $scope.leftMenu = false;
                                            $rootScope.$emit('CallApplySelectionFilters', {
                                                idview: previusQueryData.Id,
                                                datum: previusQueryData.tableoptions.data
                                            });
                                            previusQueryData.tableoptions.filtered = $rootScope.filtered;
                                            $scope.numFilteredItems  = previusQueryData.tableoptions.filtered.length;
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
            });

            /**
             * Unregister listeners
             */
            $scope.$on('$destroy', function () {
                socketListener();
                socketListenerRefresh();
                com90Socket();
                socketService.unsubscribe($scope.subscribedTopic);

            });
            $scope.$watch('selectedView', function () {
                $scope.populateViewData();
            });
            $scope.populateViewData();
        }
    };
})
;
