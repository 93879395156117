angular.module('installations')
    .component('installationListItem',{
        bindings:{
            installation:'=',
            index:'=',
            onUpdateInstallation:'&'
        },
        templateUrl:'app/installations/installationList/installationListItem/installationListItem.tpl.html',
        controller:function ($uibModal,$log,$window,BASE_PATH) {
            var $ctrl = this;
            $ctrl.$onInit=function(){
                $ctrl.installationButtons=[
                    {permissionSref:'dashboard',tooltip:'Dashboard',state:'dashboard' ,icon:'fa fa-tachometer-alt'},
                    {permissionSref:'plants',tooltip:'Visualizza gli impianti',state:'plants' ,icon:'glyphicon glyphicon-eye-open'},
                    {permissionSref:'views',tooltip:'Apri Viste dell\'installazione',state:'views' ,icon:'fa fa-table'},
                    {permissionSref:'maps',tooltip:'Apri Mappe dell\'installazione',state:'maps' ,icon:'glyphicon glyphicon-globe'},
                    {permissionSref:'calendarManagement',tooltip:'Scheduler',state:'calendarManagement' ,icon:'fa fa-calendar-alt'},
                    {permissionSref:'pendingNotification',tooltip:'Notifiche Aperte',state:'pendingNotification' ,icon:'fa fa-exclamation-triangle'},
                    {permissionSref:'historyNotification',tooltip:'Storico Notifiche',state:'historyNotification' ,icon:'fa fa-history'}
                ];
            };
            $ctrl.editInstallation=function(){
                    var modalInstance = $uibModal.open({
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        backdrop: 'static',
                        templateUrl: 'app/installations/editInstallation/editInstallation.tpl.html',
                        controller: 'EditInstallationCtrl',
                        resolve: {
                            installationToEdit: function () {
                                return  $ctrl.installation;
                            }
                        }
                    });
                    modalInstance.result.then(function () {
                        $ctrl.onUpdateInstallation();
                    });
                };
            $ctrl.mapUploaderOpen = function() {
                var modalInstance = $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    backdrop: 'static',
                    templateUrl: 'app/maps/mapUploader/mapUploader.html',
                    controller: 'MapUploaderInstanceCtrl',
                    resolve: {
                        installation: function () {
                            return $ctrl.installation;
                        }
                    }
                });
                modalInstance.result.then(function (selectedItem) {
                    $log.info(selectedItem);
                    $ctrl.onUpdateInstallation();
                });
            };
            //installation({installationId:$ctrl.installation.installationId})
            $ctrl.goToInstallation=function(){
                localStorage.setItem('ba_inst_access',$ctrl.installation.installationId);
                $window.location.href=BASE_PATH.ACCESS_CONTROL||'/access-control';
            };
            $ctrl.goToAccessControl=function(){
                localStorage.setItem('ba_inst_access',$ctrl.installation.installationId);
                $window.location.href=BASE_PATH.ACCESS_CONTROL||'/access-control';
            };
            $ctrl.getUrl=function(url){
                return $ctrl.installation.customParameters.logo||mediaPath;
            };
        }
});
