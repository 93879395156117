/* eslint-disable angular/controller-as */

angular.module('maps')
    .controller('TotalsModalCtrl', function ($uibModalInstance, $scope, $uibModal, $log, blockUI, toastr, ViewPluginService, symbol, installationId, $filter,command) {
        $scope.symbol = symbol;
        $scope.installationId = installationId;
        $scope.filter={
            period  :'month',
            year:new Date().getFullYear(),
            month:new Date().getMonth()+1
        };
        $scope.years=[$scope.filter.year];
        $scope.months=[
            {number:1,label:'Gennaio'},
            {number:2,label:'Febbraio'},
            {number:3,label:'Marzo'},
            {number:4,label:'Aprile'},
            {number:5,label:'Maggio'},
            {number:6,label:'Giugno'},
            {number:7,label:'Luglio'},
            {number:8,label:'Agosto'},
            {number:9,label:'Settembre'},
            {number:10,label:'Ottobre'},
            {number:11,label:'Novembre'},
            {number:12,label:'Dicembre'}
        ];
        for (var i = $scope.filter.year-1; i >=2019;i--){
            $scope.years.push(i);
        }
        var yUnit = symbol.r_symbolconfiguration.unit.split('/')[0];
        $scope.chartOptions = {
            elements: {line: {fill: false},point:{radius:0}},
            pan: {enabled: true, mode: 'xy'},
            zoom: {enabled: true, mode: 'xy'},
            scales: {
                yAxes: [{
                    ticks: {
                      //  min: $scope.symbol.r_symbolconfiguration.min,
                     //   max: $scope.symbol.r_symbolconfiguration.max,
                        callback: function (value, index, values) {
                            return value + ' ' + yUnit;
                        }
                    }
                }]
            }
        };
        //$scope.data=[[]];
        //$scope.labels=[];
        $scope.retrieveData = function () {
            blockUI.start();
            ViewPluginService.getTotalsData(command.r_protocol, $scope.installationId, $scope.filter.year,$scope.filter.period==='month'?$scope.filter.month:null).then(function (result) {
                $scope.data = [[]];//[0].length=0;
                $scope.labels = [];//.length=0;
                $scope.series = [$scope.symbol.name];
                $scope.totals=0;
                var  labelFilter=$scope.filter.period==='month'?'dd/MM//yyyy':'MM/yyyy';
                result.forEach(function (element) {
                    var date=new Date(element[0]);
                    date = new Date(date.getTime() + date.getTimezoneOffset()*60*1000);
                    // noinspection JSUnresolvedVariable
                    $scope.labels.push($filter('date')(date, labelFilter));
                    $scope.data[0].push(element[1]);
                    $scope.totals=$scope.totals+element[1];
                });
                blockUI.stop();
            }).catch(function (err) {
                $log.error(err);
                // noinspection SpellCheckingInspection
                toastr.error('Errore', 'Errore Generico, riprovare più tardi');
                blockUI.stop();
            });
        };
        $scope.retrieveData();
        $scope.ok = function () {
            $uibModalInstance.close();
        };
    });
