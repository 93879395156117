angular.module('auth')
    .service('Group', function($resource, BASE_PATH, $http, Functionality, Command, Symbol, GroupUsers, $filter, NotificationPlant,eventCodeFilter){
        function appendTransform(defaults, transform){
            // We can't guarantee that the default transformation is an array
            defaults = angular.isArray(defaults) ? defaults : [defaults];
            // Append the new transformation to the defaults
            return defaults.concat(transform);
        }

        var initGroupData = function(data){
            // TODO improve with async loading
            if(data.functionalities){
                data.functionalities = data.functionalities.map(function(item){
                    return new Functionality(item);
                });
            }
            if(data.commands){
                data.commands = data.commands.map(function(item){
                    return new Command(item);
                });
            }
            if(data.dashboardSymbols){
                data.dashboardSymbols = data.dashboardSymbols.map(function(item){
                    return new Symbol(item);
                });
            }
            if(data.users){
                data.users = data.users.map(function(item){
                    return new GroupUsers(item);
                });
            }
            if(data.notification_plants){
                data.notification_plants = data.notification_plants.map(function(item){
                    return new NotificationPlant(item);
                });
            }
            if(data.eventCodeFilter){
                data.eventCodeFilter=data.eventCodeFilter.map(function (item) {
                    return new eventCodeFilter(item); //TODO
                });
            }
            data.color = {};
            if(data.group_color){
                data.color.primary = data.group_color;
                data.color.secondary = $filter('colorLighter')(data.color.primary);
            }
            return data;
        };
        var transformResponse = function(data){
            data = angular.fromJson(data);
            if(angular.isArray(data)){
                data = data.map(function(value){ return initGroupData(value);});
            }else{
                data = initGroupData(data);
            }
            return data;
        };
        var groupResource = $resource(BASE_PATH.API_URL + 'installation/:installationId/groups/:id', {
            installationId: '@installationId',
            id: '@id'
        }, {
            get: {
                method: 'GET',
                transformResponse: appendTransform($http.defaults.transformResponse, transformResponse)
            },
            save: {
                method: 'POST',
                transformResponse: appendTransform($http.defaults.transformResponse, transformResponse)
            },
            query: {
                method: 'GET',
                transformResponse: appendTransform($http.defaults.transformResponse, transformResponse),
                isArray: true
            }
        });
        groupResource.getUsersSettings = function(groupId, installationId){
            return $http.get(BASE_PATH.API_URL + 'installation/' + installationId + '/groups/' + groupId + '/users/settings');
        };
        groupResource.prototype.$getUsersSettings = function(){
            return groupResource.getUsersSettings(this.id, this.installationId);
        };
        groupResource.setInitialPageToAllUsers = function(groupId, initialPage, installationId){
            return $http.post(BASE_PATH.API_URL + 'installation/' + installationId + '/groups/' + groupId + '/users/settings/initialPage', {initialPage: initialPage});
        };
        groupResource.prototype.$setInitialPageToAllUsers = function(initialPage){
            return groupResource.setInitialPageToAllUsers(this.id, initialPage, this.installationId);
        };
        groupResource.setTokenDurationToAllUsers = function(groupId, tokenDuration, installationId){
            return $http.post(BASE_PATH.API_URL + 'installation/' + installationId + '/groups/' + groupId + '/users/settings/tokenDuration', {tokenDuration: tokenDuration});
        };
        groupResource.prototype.$setTokenDurationToAllUsers = function(tokenDuration){
            return groupResource.setTokenDurationToAllUsers(this.id, tokenDuration, this.installationId);
        };
        return groupResource;
    });

