angular.module('customer', ['ngConstants', 'angularFileUpload', 'ui.router', 'permission', 'permission.ui', 'ui.bootstrap', 'shared', 'auth', 'ngResource'])
  .config(function ($stateProvider) {
// eslint-disable-next-line angular/controller-as-route
    $stateProvider
      .state('customers', {
        url: '/customers',
        parent: 'withHeader',
        controller: 'CustomersCtrl',
        templateUrl: 'app/customer/customers/customers.tpl.html',
        data: {
          permissions: {
            only: ['BUTTON-CLIENTI-AGGIUNGI', 'BUTTON-CLIENTI-MODIFICA', 'BUTTON-CLIENTI-ELIMINA'],
            redirectTo: 'installations'
          }
        }
      });
  });
