angular.module('auth')
    .component('groupSettings', {
        bindings: {
            group: '=',
            onUpdate: '&',
            rootMapSymbol: '=',
            installationId: '='
        },
        controller: function ($log, $scope, HOME_PAGE_OPTIONS, MapService, toastr) {
            var $ctrl = this;
            $ctrl.homePageOptions = HOME_PAGE_OPTIONS;
            $ctrl.login_token_expire_hours = 12;
            // TODO: handle login token under Utenti
            // $scope.$watch('$ctrl.group.id', function(newValue, oldValue) {
            //     $ctrl.group.$getUsersSettings().then(function(settings){
            //         /** @namespace settings.data.token_duration */
            //         // because `settings.data.token_duration || 12` has problems when token_duration == 0 ...
            //         if (settings.data.token_duration == null) {
            //             settings.data.token_duration = 12 * 60 * 60;
            //         }
            //         if (settings.data.token_duration !== 0) {
            //             settings.data.token_duration = settings.data.token_duration / 3600;
            //         }
            //         $ctrl.login_token_expire_hours = Math.ceil(settings.data.token_duration);
            //     }).catch(function(error){
            //         $log.error(error);
            //     });
            // });
            $ctrl.saveGroup = function () {
                // TODO: handle login token under Utenti
                // $ctrl.group.$setTokenDurationToAllUsers($ctrl.login_token_expire_hours * 3600).then(function(){
                //     $log.info('successfully updated token duration');
                // }).catch(function(error){
                //     $log.error(error);
                //     toastr.error('problema imprevisto riprovare piu tardi');
                // });
                $ctrl.onUpdate();
            };
            $ctrl.$onInit = function () {
                $ctrl.changeInitialMap = false;
            };
            $ctrl.loadChildFn = function (nodeId) {
                return MapService.map.getChildrenOfTypeMaps($ctrl.installationId, nodeId);
            };
            $ctrl.initialMapChanged = function (node) {
                $ctrl.group.groupSettings.initialMapNode = node;
                $ctrl.group.groupSettings.initialMapNode.r_nodeconfiguration = $ctrl.group.groupSettings.initialMapNode.r_symbolconfiguration;
                $ctrl.group.groupSettings.initialMapSymbol = node.r_idsymbol;
                $ctrl.changeInitialMap = false;
            };
        },
        templateUrl: 'app/auth/groups/groupDetail/settings/settings.tpl.html'
    });

