/* eslint-disable angular/controller-as */
angular.module('maps')
    .controller('TimeSlotCommandPopupCtrl', function ($uibModalInstance, command, sendMsgFn,symbol, $scope) {
        $scope.commandStr = command;
        // eslint-disable-next-line angular/log
        console.log(symbol);
        $scope.slots = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
        $scope.command={timeSlot : $scope.slots[0]};
        var  a = new Date();
        a.setHours(0, 0, 0, 0);
        $scope.command.time = a;
        var plantNumber = symbol.r_idplant.split('_')[1];
        $scope.resetTime = function () {
            var b = new Date();
            a.setHours(0, 0, 0, 0);
            $scope.command.time = b;
        };
        $scope.msg = {
            cmdString: $scope.command.cmdString,
            plantNumber:plantNumber
        };
        $scope.selected = {
            item: $scope.command[0]
        };
        function pad(n, width, z) {
            z = z || '0';
            n = n + '';
            return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        }
        $scope.ok = function () {
            $scope.sendingMsg = true;
            $scope.errorMsg = null;
            var hh=pad($scope.command.time.getHours(),2);
            var mm=pad($scope.command.time.getMinutes(),2);
            var slotType;
            switch($scope.command.operation){
                case 'BTS': // inizio fascia
                    slotType='0';
                    break;
                case 'ETS': // fine  fascia
                    slotType='1';
                    break;
                case 'DBTS': // cancella inizio fascia
                    slotType='2';
                    break;
                case 'DETS': //cancella fine fascia
                    slotType='3';
                    break;
                default:
                    slotType='0';//same as BTS
                    break;
            }
            var cmdString=pad(plantNumber,4)+'/7'+pad($scope.command.timeSlot,2)+slotType+hh+mm;
            sendMsgFn(symbol, {'r_protocol':cmdString}).then(function () {
                $uibModalInstance.close($scope.msg);
            }, function (error) {
                $scope.sendingMsg = false;
                $scope.errorMsg = error.data;
            });
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    });
