angular.module('installations')
  .constant('MAPS_UPLOAD','mapsupload')
  .constant('CUSTOMER','customer')
  .constant('INSTALLATION','/installation')
  .constant('USER','user')
  .service('Installation',function($resource,$http,BASE_PATH,MAPS_UPLOAD,INSTALLATION,CUSTOMER,USER){
      var installations={};
    var factory=$resource(BASE_PATH.API_URL+'installation/:idInstallation', { idInstallation:'@id'},{
        get:{isArray:true},
        delete:{transformResponse: angular.noop,method:'DELETE'}
    });
    factory.updateImages=function(installationId,folder){
      return $http.post(BASE_PATH.API_URL+MAPS_UPLOAD, {'INSTALLATIONID' : installationId, 'FOLDER' : folder});
    };
    factory.getInstallationsByCompanyId=function(companyId){
      return $http.get(BASE_PATH.API_URL+CUSTOMER+'/'+companyId+INSTALLATION).then(function (response) {
         return response.data.map(function (item) {
             var installation=new factory(item);
             installations[item.installationId.toString()]=installation;
             return installation;
          });
      });
    };
    factory.getInstallationsByUserId=function(userId){
      return $http.get(BASE_PATH.API_URL+USER+'/'+userId+INSTALLATION).then(function (response) {
         return response.data.map(function (item) {
             var installation=new factory(item);
             installations[item.installationId.toString()]=installation;
             return installation;
          });
      });
    };
    factory.getCompanyCustomerByCompanyId=function(companyId){
       return  $http.get(BASE_PATH.API_URL + '/company/' + companyId + '/'+ CUSTOMER);
    };

    return factory;
  });
