angular.module('shared')
  .service('LocalizationStringService', function LocalizationStringService($window) {
    // AngularJS will instantiate a singleton by calling "new" on this function

    var currentUser = null;
    var currentLocStrings;
    if (currentUser === null){
      //On login page currentUser is null
      currentLocStrings = $window.localizationStrings;
    }else{
      switch (currentUser.notes.lang){
        case 'it':
          currentLocStrings = $window.localizationStrings_it;
          break;
        default:
          currentLocStrings = $window.localizationStrings;
      }
    }
    return {
      localizedStrings: currentLocStrings,
      localizedString: function(id) {
        return currentLocStrings && currentLocStrings[id] ? currentLocStrings[id] : id;
      }
    };


  });
