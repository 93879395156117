angular.module('auth')
    .component('groupDetail', {
        bindings: {
            group: '=',
            installationId: '=',
            onUpdate: '&',
            rootMapSymbol: '='
        },
        controller: function ($log, Group, $timeout, toastr) {
            var $ctrl = this;
            $ctrl.updateFunctionality = function (functionality) {
                var value = angular.copy(functionality.enabled);
                $log.info('funct changed', functionality);
                functionality.$save().then(function (response) {
                    $log.info(response);
                }).catch(function (error) {
                    functionality.enabled = value;
                    toastr.error('Problema Imprevisto, riprova piu tardi', '');
                    $log.error(error);
                });
            };
            $ctrl.updateCommand = function (command) {
                $log.info('command changed', command);
                var value = angular.copy(command.enabled);
                command.$save().then(function (response) {
                    $log.info(response);
                }).catch(function (error) {
                    command.enabled = value;
                    toastr.error('Problema Imprevisto, riprova piu tardi', '');
                    $log.error(error);
                });
            };
            $ctrl.updateSymbol = function (symbol) {
                $log.info('command changed', symbol);
                symbol.$save().then(function (response) {
                    var value = angular.copy(symbol.enabled);
                    $log.info(response);
                }).catch(function (error) {
                    $log.error(error);
                    symbol.enabled = value;
                    toastr.error('Problema Imprevisto, riprova piu tardi', '');
                });
            };
            $ctrl.saveGroup = function () {
                $ctrl.onUpdate($ctrl.group);
            };
            $ctrl.updatePlant = function (plant) {
                $log.info('plant changed', plant);
                var value = angular.copy(plant.enabled);
                plant.$save().then(function (response) {
                    $log.info(response);
                }).catch(function (error) {
                    plant.enabled = value;
                    toastr.error('Problema Imprevisto, riprova piu tardi', '');
                    $log.error(error);
                });
            };
            $ctrl.updateEventFilter = function (eventCodeFilter) {
                var value = angular.copy(eventCodeFilter.enabled);
                eventCodeFilter.$save().then(function (response) {
                    $log.info(response);
                }).catch(function (error) {
                    eventCodeFilter.enabled = value;
                    toastr.error('Problema Imprevisto, riprova piu tardi', '');
                    $log.error(error);
                });
            };
            $ctrl.$onInit = function () {
                if ($ctrl.group.users) {
                    $ctrl.group.users.forEach(function (user) {
                        user.installationId = $ctrl.installationId;
                    });
                }
            };
        },
        templateUrl: 'app/auth/groups/groupDetail/groupDetail.tpl.html'
    });
