angular.module('users', ['ngConstants', 'angularFileUpload', 'ui.router', 'permission', 'permission.ui', 'ui.bootstrap', 'shared', 'auth'])
    .config(function($stateProvider){
        // eslint-disable-next-line angular/controller-as-route
        $stateProvider
            .state('companymanagement', {
                url: '/companymanagement',
                parent: 'withHeader',
                component: 'users',
                resolve: {
                    currentCompany: function(UserService){
                        return UserService.getUserCompanyDescription();
                    },
                    employees: function(UserService, Company){
                        return Company.getEmployee(UserService.getUserManagerCompanyId());
                    }
                },
                data: {
                    permissions: {
                        only: ['MENU-UTENTI'],
                        redirectTo: 'installations'
                    }
                }
            });
    });
