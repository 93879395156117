angular.module('scheduler')
  .controller('copyPopUpCtrl', function ($uibModalInstance, event) {
    var vm = this;
    vm.event = event;
    var today=new Date();
    vm.options = {
        copyOn: 'week',
        day:new Date(today.getFullYear(),today.getMonth(),today.getDate()+1)
    };
    vm.open = function(){
          vm.popupOpened = true;
      };
    vm.dateOptions = {
          formatYear: 'yyyy',
          minDate: new Date(),
          startingDay: 1
      };
    vm.ok = function () {
      $uibModalInstance.close(vm.options);
    };
    vm.cancel =function(){ $uibModalInstance.dismiss();};
  });
