angular.module('auth')
.component('addUserGroup',{
  templateUrl:'app/auth/groups/groupDetail/users/addUser/addUser.tpl.html',
  bindings:{
    resolve:'<',
    close: '&',
    dismiss: '&'
  },
  controller:function ($log) {
    var $ctrl=this;
    $ctrl.$onInit=function () {
      $ctrl.users=$ctrl.resolve.users;
    };
    $ctrl.selectUser=function(user){
      $ctrl.close({$value:user});
    };
    $ctrl.dismiss=function(){
      $ctrl.dismiss();
    };
  }
});
