/* eslint-disable angular/controller-as */
angular.module('plants')
    .controller('EditPlantModalCtrl', function($uibModal, $log, modalService, $http, BASE_PATH, $uibModalInstance, plantToEdit, installationId, $scope, plantTemplates){
        if(plantToEdit){
            $scope.plantMaster = plantToEdit;
            $scope.plant = angular.copy($scope.plantMaster);
            $scope.plant.plantProtocol = $scope.plant.isMqtt === true ? 'MQTT' : 'SAETCOMM';
        }else{
            $scope.isNew = true;
            $scope.plant = { isNew: true, cod_tipo_impianto: 1, plantProtocol: 'SAETCOMM' };
        }
        $scope.installationId = installationId;
        $scope.types = plantTemplates.data;
        $scope.plantProtocols = ['SAETCOMM', 'MQTT'];
        $http.get(BASE_PATH.API_URL + 'installation/' + installationId + '/partitions').then(function(response){
            $scope.partitions = response.data;
            //associo di default la partizione
            if(angular.isUndefined($scope.plant.partitionId)){
                if($scope.partitions.length){
                    $scope.plant.partitionId = $scope.partitions[ 0 ].idpartition;
                }else{
                    $log.error('partition not defined');
                    $scope.errorMsg = 'Partizione Predefinita non esistente.';
                }
            }
        });
        $scope.partitionChanged = function(p){
            $scope.plant.partitionId = $scope.selectedPartId;
        };
        $scope.deletePlant = function(){
            var modalOptions = {
                closeButtonText: 'Annulla',
                actionButtonText: 'Sì',
                headerText: 'Eliminare ?',
                bodyText: 'Sicuro di volere eliminare questa centrale?'
            };
            var modalInstance = $uibModal.open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/shared/popup/confirmModal/confirmModal.tpl.html',
                controller: 'ConfirmModalInstanceCtrl',
                resolve: {
                    message: function(){
                        return modalOptions;
                    }
                }
            });

            modalInstance.result.then(function(){
                $http.delete(BASE_PATH.API_URL + 'plant/' + $scope.plant.MAC).then(function(data){
                    $uibModalInstance.close($scope.plant);
                }, function(error){
                    $scope.errorMessage = error.data.error;
                });

            }, function(result){

            });
        };
        $scope.ok = function(){
            $log.info('saving', $scope.plant);
            //TODO inserire loader
            if(!$scope.isNew){
                $http.post(BASE_PATH.API_URL + 'plant/' + $scope.plant.MAC, $scope.plant).then(function(){
                    $uibModalInstance.close($scope.plant);
                }, function(error){
                    $scope.errorMessage = error.data;
                }).catch(function (err) {
                    $log.error('unhandled error: ' + err);
                });
            }else{
                $http.post(BASE_PATH.API_URL + 'plant', $scope.plant).then(function(){
                    $uibModalInstance.close($scope.plant);
                }, function(error){
                    if (error.status === 422) {
                        if(error.data.errorCode === 1)
                            $scope.errorMessage = 'esiste già nel sistema un impianto con MAC address '+$scope.plant.MAC+' e numero '+$scope.plant.plantId;
                        else if(error.data.errorCode === 2)
                            $scope.errorMessage = 'esiste già un impianto numero '+$scope.plant.plantId+' nell\'installazione';
                        } else {
                        $scope.errorMessage = error.data;
                    }
                }).catch(function (err) {
                    $log.error('unhandled error: ' + err);
                });
            }
        };
        $scope.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };
    });
