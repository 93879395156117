angular.module('shared')
    .service('soundService', function($rootScope,toastr,$window){
        var defaultSoundUri = 'images/alert.mp3';
        var sound;
        var BUCKET_NAME = 'sound';
        var SOUND_KEY = 'soundKey';
        var SOUND_FOREVER='soundForever';
        var DEFAULT_ITERATION=2;
        var AudioContext = $window.AudioContext || $window.webkitAudioContext;
        var audioCtx = new AudioContext();
        var openAlarmSoundToastr = function(){
            toastr.error('Clicca qui per tacitazione','Allarme sonoro', {
                timeOut: 0,
                extendedTimeOut: 0,
                toastClass: 'toast no-opacity',
                onHidden:function(clicked,toastInstance){
                    stopSound();
                }
            });
        };
        var playSound = function(iteration, soundUri){
            lscache.setBucket(BUCKET_NAME);
            if(iteration===-1){
                var soundForeverSet = lscache.get(SOUND_FOREVER, true);
                if (!soundForeverSet){
                    lscache.set(SOUND_FOREVER, true);
                    lscache.set(SOUND_KEY, iteration);
                    openAlarmSoundToastr();
                }
            }else{
                iteration = iteration || DEFAULT_ITERATION;
                lscache.set(SOUND_KEY, iteration);
            }
            soundUri = soundUri || defaultSoundUri;
            if (sound != null) {
                sound.stop();
                sound.unload();
                sound = null;
            }
            sound = new Howl({
                src: [soundUri],
                preload: true,
                autoplay: true,
                onend: soundEncCallback
            });
            if(audioCtx.state === 'running') {
                sound.play();
            } else if(audioCtx.state === 'suspended'){
                audioCtx.resume().then(function(){
                    $window.addEventListener('focus', function(){
                        sound.play();
                    }, false);
                });
            }
        };
        var soundEncCallback = function(){
            lscache.setBucket(BUCKET_NAME);
            var value = lscache.get(SOUND_KEY);
            var soundForever = lscache.get(SOUND_FOREVER);
            if(soundForever){
                playSound(value);
            }else{
                if(value){
                    value = Number(value);
                    if(value && !isNaN(value)){
                        value = value - 1;
                        if(value > 0){
                            playSound(value);
                        }else{
                            stopSound();
                        }
                    }
                }
            }
        };
        var stopSound = function(){
            if(sound){
                sound.unload();
            }
            lscache.setBucket(BUCKET_NAME);
            lscache.remove(SOUND_KEY);
            lscache.remove(SOUND_FOREVER);
        };
        // eslint-disable-next-line angular/on-watch
        $rootScope.$on('socket:sound', function(event, payload){
            var data = payload.data;
            playSound(data.number); // 11/05/2021, prima era "Number"
        });
        function _init(){
                    lscache.setBucket(BUCKET_NAME);
                    lscache.remove(SOUND_FOREVER);
                    var value = lscache.get(SOUND_KEY);
                    // eslint-disable-next-line angular/definedundefined
                    if(value!==undefined && value!==null){
                        playSound(value);
                    }
                }
        _init();
    });
