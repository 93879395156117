angular.module('views', [ 'shared', 'ui.grid', 'ui.grid.selection', 'ui.grid.resizeColumns', 'ui.grid.autoResize','ui.grid.exporter','plants', 'auth' ])
    .config(function($stateProvider){
        // eslint-disable-next-line angular/controller-as-route
        $stateProvider
        // viste usate dalle mappe
            .state('views', {
                url: '/installation/:installationId/views/:viewId?filters',
                parent: 'withHeader',
                controller: 'ViewsCtrl',
                templateUrl: 'app/views/view/view.tpl.html',
                resolve: {
                    installationId: function($stateParams){
                        return $stateParams.installationId;
                    },
                    plants: function(installationId,PlantService ){
                        return PlantService.plantListByInstallationId(installationId).then(function(response){
                            return response.result;
                        });
                    },
                    viewId: function($stateParams){
                        return $stateParams.viewId;
                    },
                    filters: function($stateParams){
                        return $stateParams.filters;
                    },
                    sock: function($stateParams, socketService){
                        return socketService.connect($stateParams);
                    },
                    isDashboardViews: function($stateParams){
                        return $stateParams.isDashboardViews;
                    }
                },
                data: {
                    permissions: {
                        only: [ 'BUTTON-INSTALLAZIONI-APRIVISTE' ],
                        redirectTo: 'installations'
                    }
                }
            })
            .state('modal', {
                url: '/installation/:installationId/modal/:viewId?filters',
                parent: 'withoutHeader',
                controller: 'ViewsCtrl',
                templateUrl: 'app/views/view/view.tpl.html',
                params: {
                    isDashboardViews: true
                },
                resolve: {
                    installationId: function($stateParams){
                        return $stateParams.installationId;
                    },
                    plants: function(installationId,PlantService ){
                        return PlantService.plantListByInstallationId(installationId).then(function(response){
                            return response.result;
                        });
                    },
                    viewId: function($stateParams){
                        return $stateParams.viewId;
                    },
                    filters: function($stateParams){
                        return $stateParams.filters;
                    },
                    sock: function($stateParams, socketService){
                        return socketService.connect($stateParams);
                    },
                }
            })
            // .state('dashboardview', {
            //     url: '/installation/:installationId/dashboardview/:viewId?filters',
            //     parent: 'withoutHeader',
            //     controller: 'ViewsCtrl',
            //     templateUrl: 'app/views/view/view.tpl.html',
            //     params: {
            //         isDashboardViews: true
            //     },
            //     resolve: {
            //         installationId: function($stateParams){
            //             return $stateParams.installationId;
            //         },
            //         plants: function(installationId,PlantService ){
            //             return PlantService.plantListByInstallationId(installationId).then(function(response){
            //                 return response.result;
            //             });
            //         },
            //         viewId: function($stateParams){
            //             return $stateParams.viewId;
            //         },
            //         filters: function($stateParams){
            //             return $stateParams.filters;
            //         },
            //         sock: function($stateParams, socketService){
            //             return socketService.connect($stateParams);
            //         }
            //     },
            //     data: {
            //         permissions: {
            //             only: [ 'BUTTON-INSTALLAZIONI-APRIVISTE' ],
            //             redirectTo: 'installations'
            //         }
            //     }
            // })
            // viste usate dalla dashboard
            .state('standAloneView', {
                url: '/installation/:installationId/standAloneView/:viewId?symbolId?filters',
                parent: 'withoutHeader',
                controller: 'StandAloneViewCtrl',
                templateUrl: 'app/views/standAloneView/standAloneView.tpl.html',
                resolve: {
                    installationId: function($stateParams){
                        return $stateParams.installationId;
                    },
                    plants: function(installationId,PlantService ){
                        return PlantService.plantListByInstallationId(installationId).then(function(response){
                            return response.result;
                        });
                    },
                    viewId: function($stateParams){
                        return $stateParams.viewId;
                    },
                    filters: function($stateParams){
                        return $stateParams.filters;
                    },
                    sock: function($stateParams, socketService){
                        return socketService.connect($stateParams);
                    }
                }
            })
            // viste usate per le piscine
            .state('view', {
                url: '/installation/:installationId/view/:viewId?symbolId?filters',
                parent: 'withHeader',
                controller: 'ViewsCtrl',
                templateUrl: 'app/views/view/view.tpl.html',
                resolve: {
                    installationId: function($stateParams){
                        return $stateParams.installationId;
                    },
                    plants: function(installationId,PlantService ){
                        return PlantService.plantListByInstallationId(installationId).then(function(response){
                            return response.result;
                        });
                    },
                    viewId: function($stateParams){
                        return $stateParams.viewId;
                    },
                    filters: function($stateParams){
                        return $stateParams.filters;
                    },
                    sock: function($stateParams, socketService){
                        return socketService.connect($stateParams);
                    }
                }
            });
    });
