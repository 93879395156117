angular.module('shared')
  .component('materialCheckboxList',{
    bindings:{
      level:'<',
      items:'=',
      idField:'<',
      textField:'<',
      modelField:'<',
      onUpdate:'&'
    },
    controller:function ($log) {
      var $ctrl = this;
      $ctrl.handler=function(item){
        $ctrl.onUpdate({item:item});
      };
      $ctrl.$onInit=function () {
        $ctrl.itemClass='label-'+$ctrl.level;
      };
    },
    templateUrl:'app/shared/materialCheckBoxList/materialCheckboxList.tpl.html'
  });
