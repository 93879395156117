angular.module('shared')
  .component('materialCheckboxItem',{
    bindings:{
      item:'<',
      itemClass:'<',
      idField:'<',
      textField:'<',
      modelField:'<',
      onUpdate:'&'
    },require:{
      'parent':'^materialCheckboxList'
    },
    controller:function () {
      var $ctrl = this;
      $ctrl.changed=function(){
        $ctrl.onUpdate($ctrl.item);
      };
    },
    templateUrl:'app/shared/materialCheckBoxList/materialCheckboxItem/materialCheckboxItem.tpl.html'
  });
