/* eslint-disable angular/controller-as */

angular.module('plants')
    .controller('PlantsManagementCtrl', function($uibModal, $log, $scope, modalService, installationId, socketService, $rootScope, $state, PermPermissionMap, PermAuthorization, PlantService, Installation, Company, BASE_PATH, UserService, plantTemplates){
        $scope.installationId = installationId;
        $scope.managePlantPermissionMap = new PermPermissionMap({ only: 'BUTTON-IMPIANTI-MANUTENZIONE' });
        var sub = socketService.subscribe('Installation.' + $scope.installationId);
        $scope.TEBE_SMALL_URL = BASE_PATH.TEBE_SMALL_URL;

        /**
         *
         * @param {object} event
         * @param {object} data
         * @param {object} data.MSG
         * @param {string} data.MSG.idPlant
         */
        var com90Socket = $rootScope.$on('socket:connectionstate', function(event, data){
            $log.debug('controller Received Feedback');

            /*if(data.MSG && data.MSG.evntType){
                // Passato da dat.MSG.idPlant a data.PLANT_ID per aggiornamento Hicloud 2 (MD, 18/02/2021)
                //Provaprova prova
                if(data.PLANT_ID){
                    var idPlant = data.PLANT_ID;

                    var st = $scope.plantList.find(function(p){
                        return p.MAC.toLowerCase() === idPlant.toLowerCase();
                    }).state;

                    var evnType = data.MSG.evntType;

                    // Se è una comunicazione di mancata connessione lo segnalo, altrimenti la considero attiva.
                    if ( evnType === '248') {
                        st = st.substring(0,3) + '2' + st.substr(5);
                    } else {
                        st = st.substring(0,3) + '0' + st.substr(5);
                    }

                }
            }*/

            if (data.MSG && data.PLANT_ID) {
                var idPlant = data.PLANT_ID;

                 $scope.plantList.find(function(p){
                    return p.MAC.toLowerCase() === idPlant.toLowerCase();
                }).connected =  data.MSG.connected;
            }

            $scope.$apply();
        });
        $scope.getPlants = function(){
            PlantService.plantListByInstallationId($scope.installationId).then(function(data){
                var plist = [];
                data.result.forEach(function (rslt) {
                        rslt.connected = (rslt.state[4] !== '2');
                        plist.push(rslt);
                    });

                $scope.plantList = plist;
                $scope.token = data.tebesmallToken;
            });
        };
        $scope.getInstallationInfo = function(){
            Installation.get({ idInstallation: $scope.installationId }).$promise.then(function(data){
                $scope.installationInfo = data[ 0 ];
                return Company.get({ companyId: $scope.installationInfo.customerId }).$promise;
            }).then(function(response){
                $scope.customerInfo = response.data;
            });
        };
        $scope.managePlant = function(p, event){
            if(event){
                event.stopPropagation();
            }
            PermAuthorization.authorizeByPermissionMap($scope.managePlantPermissionMap).then(function(){
                $state.go('plant', { plantId: p.MAC, installationId: $scope.installationId });
            }).catch(function(){
                //permission denied
            });

        };
        $scope.getPlants();
        $scope.getInstallationInfo();
        $scope.viewCustomer = function(customer){
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                backdrop: 'static',
                templateUrl: 'app/customer/viewCustomer/viewCustomer.tpl.html',
                controller: 'ViewCustomerModalCtrl',
                resolve: {
                    customerToView: function(){
                        return customer;
                    },
                    type: function(){
                        return 'customer';
                    }
                }
            });

            modalInstance.result.then(function(selectedItem){
                $log.info(selectedItem);
                $scope.getCustomers();
            }, function(result){
                $log.info(result);
                $log.info('Modal dismissed at: ' + new Date());
            });
        };
        $scope.plantUploaderOpen = function(p, event){
            if(event){
                event.stopPropagation();
            }
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                backdrop: 'static',
                templateUrl: 'app/plants/plantUploaderOpen/plantUploaderOpen.tpl.html',
                controller: 'PlantConfigUploadCtrl',
                resolve: {
                    installationId: function(){
                        return $scope.installationId;
                    }
                }
            });

            modalInstance.result.then(function(selectedItem){
                $log.info(selectedItem);
                $scope.getInstallations();
            }, function(result){
                $log.info(result);
                $log.info('Modal dismissed at: ' + new Date());
            });

        };
        $scope.editPlant = function(p, event){
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                backdrop: 'static',
                templateUrl: 'app/plants/editPlant/editPlant.tpl.html',
                controller: 'EditPlantModalCtrl',
                resolve: {
                    plantToEdit: function(){
                        return p;
                    },
                    installationId: function(){
                        return $scope.installationId;
                    },
                    plantTemplates: function(){
                        return plantTemplates;
                    }
                }
            });
            if(event){
                event.stopPropagation();
            }
            modalInstance.result.then(function(selectedItem){
                $log.info(selectedItem);
                $scope.getPlants();
            }, function(result){
                $log.info(result);
                $log.info('Modal dismissed at: ' + new Date());
            });
        };
        $scope.$on('$destroy', function(){
            socketService.unsubscribe(sub);
            com90Socket();
        });
    });
