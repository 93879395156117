angular.module('plants')
  .service('PlantService', function(BASE_PATH, $http) {
    return {
        getPlantTemplates:function(){
            return $http.get(BASE_PATH.API_URL+'plant/types');
      },
      plantListByInstallationId:function(installationId){
        return $http.get(BASE_PATH.API_URL+'installation/'+installationId+'/plants').then(function(response){
          return response.data;
        });
      },
      plantDetail:function(plantId){
        return $http.get(BASE_PATH.API_URL + 'plant/mac/' + plantId);
      }
    };
  });
