angular.module('auth')
    .component('chooseInitialPage', {
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        },
        templateUrl:'app/auth/chooseInitialPage/chooseInitialPage.tpl.html',
        controller: function(HOME_PAGE_OPTIONS,$log,$state){
            var $ctrl = this;
            $ctrl.selectInitialPage = function(){
                var stateParams={};
                if ($ctrl.choosenPage.needInstallationId){
                    stateParams.installationId=$ctrl.resolve.installationId;
                }
                var _selected = HOME_PAGE_OPTIONS.find(function(h) {
                    return h.stateName === $ctrl.choosenPage.stateName; 
                });
                
                if(_selected && _selected.url) {
                    $ctrl.close({$value: _selected.url});
                } else {
                    $ctrl.close({$value: $state.href($ctrl.choosenPage.stateName,stateParams)});
                }
            };
            $ctrl.$onInit = function(){
                $ctrl.installationId=$ctrl.resolve.installationId;
                $ctrl.possibleInitialPages = HOME_PAGE_OPTIONS;
            };
            $ctrl.close = function(){
                $ctrl.dismiss();
            };
        }
    });
