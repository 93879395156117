angular.module('auth')
  .component('groupListItem',{
    bindings:{
      group:'=',
      onUpdate:'&'
    },
    controller:function () {
      var $ctrl = this;
      $ctrl.selectGroup=function(group){
        $ctrl.onUpdate({group:group});
      };
      $ctrl.$onInit=function(){};
    },
    templateUrl:'app/auth/groups/groupList/groupListItem/groupListItem.tpl.html'
  });
