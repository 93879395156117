/* eslint-disable angular/controller-as,angular/no-services */
angular.module('installations')
    .controller('EditInstallationCtrl', function($uibModal, $log, modalService,$http, BASE_PATH, $uibModalInstance, installationToEdit, $scope, UserService,Installation){
        $scope.deleteInstallation = function(){
            var modalOptions = {
                closeButtonText: 'Annulla',
                actionButtonText: 'Sì',
                headerText: 'Eliminare ?',
                bodyText: 'Sicuro di volere eliminare questa installazione?'
            };
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/shared/popup/confirmModal/confirmModal.tpl.html',
                controller: 'ConfirmModalInstanceCtrl',
                resolve: {
                    message: function(){
                        return modalOptions;
                    }
                }
            });

            modalInstance.result.then(function(){
                installationToEdit.id = installationToEdit.installationId;
                installationToEdit.$delete().then(function(){
                    $uibModalInstance.close($scope.plant);
                }, function(error){
                    $scope.errorMessage = error.data.error;
                });
            });

        };

        $scope.control = {};
        if(installationToEdit){
            $scope.installation = angular.copy(installationToEdit);
        }else{
            $scope.isNew = true;
            $scope.installation = { customerId: null, isNew: true, position: {} };
        }

        $scope.getCompanyCustomers = function(){
            Installation.getCompanyCustomerByCompanyId(UserService.getUserManagerCompanyId()).then(function(data){
                $scope.customers = data.data;
                $scope.isLoading = false;
            });
        };

        $scope.getCompanyCustomers();

        $scope.customerChanged = function(){
            // noinspection JSUnresolvedVariable
            $scope.installation.customerId = $scope.selectedCustomerId;
        };
        $scope.openNewCustomerPopup = function(){
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/customer/editCustomer/editCustomer.html',
                controller: 'EditCustomerModalCtrl',
                resolve: {
                    type: function(){
                        return 'customer';
                    },
                    customerToEdit: function(){
                        return null;
                    }
                }
            });

            modalInstance.result.then(function(selectedItem){
                $log.info(selectedItem);
                $scope.getCompanyCustomers();
            }, function(result){
                $log.info(result);
                $log.info('Modal dismissed at: ' + new Date());
            });
        };
        $scope.ok = function(){
            //TODO inserire loader
            $scope.installation.managerId = UserService.getUserManagerCompanyId();
            if($scope.installation.installationId){
                $http.post(BASE_PATH.API_URL + 'installation/' + $scope.installation.installationId, $scope.installation).then(function(modifiedInstallation){
                    $uibModalInstance.close(modifiedInstallation);
                }, function(error){
                    $scope.errorMessage = error;
                });
            }else{
                $http.post(BASE_PATH.API_URL + 'installation', $scope.installation).then(function(modifiedInstallation){
                    UserService.getUserInfo().then(function(){
                        $uibModalInstance.close(modifiedInstallation);
                    });
                }, function(error){
                    $scope.errorMessage = error;
                });
            }
        };
        $scope.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };
    });
