angular.module('scheduler')
    .component('calendarManagement', {
        bindings: {
            installationId: '=',
            groups: '=',
            currentDate: '=',
            initMonthEvents: '='
        },
        templateUrl: 'app/scheduler/calendar/calendar.tpl.html',
        controller: function($log, $scope, Events, toastr, $uibModal){
            var roundInterval = 1000 * 60 * 30;//30 minutes "round slot"
            var onViewDateChange = function(newVal, oldVal){
                if(newVal !== oldVal){
                    $ctrl.cellIsOpen = false;
                    var monthKey = buildMonthKey(newVal);
                    if($ctrl.months.indexOf(monthKey) < 0){
                        //get new data
                        $ctrl.months.push(monthKey);
                        Events.getEventsForMonth($ctrl.installationId, newVal.getFullYear(), newVal.getMonth() + 1).then(function(res){
                            res.forEach(function(event){
                                $ctrl.addEvent(event);
                            });
                        });
                    }
                }
            };
            var isDateAfter = function(first, second){
                first = moment(first);
                second = moment(second);
                var diff = first.diff(second, 'days');
                return diff >= 0;
            };
            var buildMonthKey = function(date){
                date = moment(date) || moment();
                var month = date.month() + 1;
                if(month < 10){
                    month = '0' + month;
                }
                return '' + month + '_' + date.year();
            };
            var copyEventAction = {
                label: '<i class=\'fas fa-copy color-gray\'></i>', // the label of the action
                cssClass: 'edit-action color-gray', // a CSS class that will be added to the action element so you can implement custom styling
                onClick: function(args){ // the action that occurs when it is clicked. The first argument will be an object containing the parent event
                    var modalInstance = $uibModal.open({
                        animation: $scope.animationsEnabled,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        templateUrl: 'app/scheduler/calendar/copyPopup/copyPupUp.tpl.html',
                        controller: 'copyPopUpCtrl',
                        controllerAs: 'vm',
                        resolve: {
                            event: function(){
                                return args.calendarEvent;
                            }
                        }
                    });
                    modalInstance.result.then(function(options){
                        var eventToAdd = angular.copy(args.calendarEvent);
                        if(options.copyOn === 'week'){
                            eventToAdd.startsAt.setDate(eventToAdd.startsAt.getDate() + 7);
                            eventToAdd.endsAt.setDate(eventToAdd.endsAt.getDate() + 7);
                            $ctrl.addEvent(eventToAdd);
                        }else{
                            var month = new Date().getMonth();
                            var year = new Date().getFullYear();
                            if(options.copyOn === 'month'){
                                month = eventToAdd.startsAt.getMonth() + 1;
                                eventToAdd.startsAt.setMonth(month);
                                eventToAdd.endsAt.setMonth(month);
                            }else{
                                month = options.day.getMonth();
                                year = options.day.getFullYear();
                                eventToAdd.startsAt.setMonth(month);
                                eventToAdd.endsAt.setMonth(month);
                                eventToAdd.startsAt.setFullYear(year);
                                eventToAdd.endsAt.setFullYear(year);
                                eventToAdd.startsAt.setDate(options.day.getDate());
                                eventToAdd.endsAt.setDate(options.day.getDate());
                            }
                            $ctrl.addEvent(eventToAdd);
                            /* Events.getEventsForMonth($ctrl.installationId, year, month).then(function(res){
                                 res.forEach(function(event){
                                     $ctrl.addEvent(event);
                                 });
                                 $ctrl.addEvent(eventToAdd);
                             });*/
                        }
                    });
                }
            };
            var removeEventAction = {
                label: '<i class=\'glyphicon glyphicon-remove color-gray \'></i>', // the label of the action
                cssClass: 'remove-action color-gray', // a CSS class that will be added to the action element so you can implement custom styling
                onClick: function(args){ // the action that occurs when it is clicked. The first argument will be an object containing the parent event
                    var index = $ctrl.events.indexOf(args.calendarEvent);
                    $ctrl.events.splice(index, 1);
                }
            };
            var $ctrl = this;
            $ctrl.calendarView = 'day';
            $ctrl.viewDate = moment().startOf('month').toDate();
            $ctrl.addEvent = function(eventToAdd){ //init events
                eventToAdd.actions = [];
                //ensure display name is in the title variable for "calendar view"
                eventToAdd.title = eventToAdd.title || eventToAdd.name;
                // the event can be moved and resized only if is in the current month or after
                if(isDateAfter(eventToAdd.startsAt, $ctrl.currentDate)){
                    eventToAdd.draggable = true;
                    eventToAdd.resizable = true;
                    eventToAdd.actions.push(copyEventAction);
                    eventToAdd.actions.push(removeEventAction);
                }else{
                    eventToAdd.draggable = false;
                    eventToAdd.resizable = false;
                }
                //taking colors from group
                var group = $ctrl.groups.filter(function(value){
                    return value.id === eventToAdd.groupId;
                })[ 0 ];
                if(group){
                    eventToAdd.color = group.color;
                }
                if(eventToAdd.endsAt){
                    eventToAdd.endsAt = new Date(eventToAdd.endsAt.getTime() - 1);
                }
                //prepare ctrl data structure
                if($ctrl.months.indexOf(buildMonthKey(eventToAdd.startsAt)) === -1){
                    $ctrl.months.push(buildMonthKey(eventToAdd.startsAt));
                }
                $ctrl.events.push(eventToAdd);
            };
            $ctrl.$onInit = function(){
                $log.info($ctrl);
                $ctrl.months = [];
                $ctrl.events = [];
                $ctrl.months.push(buildMonthKey($ctrl.currentDate));
                /** @namespace $ctrl.initMonthEvents */
                $ctrl.initMonthEvents.forEach(function(event){
                    $ctrl.addEvent(event);
                });
                //init event color from group
                $ctrl.calendarView = 'month';
                $ctrl.viewDate = moment($ctrl.currentDate).startOf('month').toDate();
                $ctrl.cellIsOpen = false;
                $scope.$watch('$ctrl.viewDate', onViewDateChange);
            };
            $ctrl.save = function(){
                var eventsToSave = $ctrl.events.map(function(event){
                    var eventToSave = {
                        startsAt: event.startsAt,
                        endsAt: event.endsAt,
                        groupId: event.groupId,
                        title: event.name
                    };
                    eventToSave.endsAt = new Date(event.endsAt.getTime() + 1);
                    return eventToSave;
                });
                Events.saveEvents($ctrl.installationId, eventsToSave, $ctrl.viewDate.getFullYear(), $ctrl.viewDate.getMonth() + 1, $ctrl.months).then(function(response){
                    $ctrl.months = [];
                    $ctrl.events = [];
                    $ctrl.months.push(buildMonthKey($ctrl.viewDate));
                    response.forEach(function(event){
                        $ctrl.addEvent(event);
                    });
                    toastr.success('Salvataggio completato');
                }).catch(function(error){
                    $log.error(error);
                    toastr.error('Problema imprevisto riprovare piu tardi');
                });
            };
            $ctrl.eventDropped = function(event, start, end){
                $log.info(event,start);
                var forceFullDay = [ 'month', 'week' ].includes($ctrl.calendarView);
                /** @namespace $ctrl.currentDate */
                if(isDateAfter(start, $ctrl.currentDate)){
                    var externalIndex = $ctrl.groups.indexOf(event);
                    if(externalIndex > -1 && angular.isUndefined(event.calendarEventId)){
                        //new event
                        // this is needed of an event dragged in weekmode will be in the previous day
                        if ($ctrl.calendarView==='week' && start.getDay() !== 1){
                            start =new Date(start.getFullYear(), start.getMonth(), start.getDate() + 1, start.getHours(), start.getMinutes(), start.getSeconds());
                        }
                        var eventToAdd = angular.copy(event, {});
                        eventToAdd.startsAt = !forceFullDay ? start : new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0, 0);
                        end = end || moment(start).add(30, 'minutes').toDate();
                        eventToAdd.endsAt = !forceFullDay ? end : new Date(end.getFullYear(), end.getMonth(), end.getDate() + 1, 0, 0, 0, 0);
                        eventToAdd.groupId = eventToAdd.id;
                        delete eventToAdd.id;
                        if(eventToAdd.endsAt){
                            eventToAdd.endsAt = new Date(Math.round(eventToAdd.endsAt.getTime() / roundInterval) * roundInterval);
                        }
                        $ctrl.addEvent(eventToAdd);
                    }else{
                        event.startsAt = start;
                        if(end){
                            if(!forceFullDay){
                                end = new Date(Math.round((end.getTime()) / roundInterval) * roundInterval);
                            }else{
                                end = new Date(end.getFullYear(), end.getMonth(),end.getDate()+1 , 0, 0, 0, 0);
                            }
                            end = new Date(end.getTime() - 1);//after round remove one millis for day keeping
                            event.endsAt = end;
                        }
                    }
                    $log.warn($ctrl.events);
                    $ctrl.viewDate = start;
                    $ctrl.cellIsOpen = true;
                }else{
                    toastr.warning('Impossibile posizionare nel passato');
                }
            };
        }
    })
;
