angular.module('scheduler',['shared','ui.router', 'permission', 'permission.ui','mwl.calendar', 'ngAnimate', 'ui.bootstrap','ngTouch','auth'])
  .config(function($stateProvider,$touchProvider,calendarConfig,PAGE_SIZE) {
    $touchProvider.ngClickOverrideEnabled(true);
    calendarConfig.templates.calendarDayView='app/scheduler/calendar/calendarDayView.tpl.html';
    calendarConfig.allDateFormats.angular.date.hour = 'HH:mm';
    calendarConfig.i18nStrings.weekNumber = 'Settimana {week}';
      moment.updateLocale('it', {
          week: {
              dow: 1
          }
      });

    $stateProvider
      .state('calendarManagement', {
        url: '/installations/:installationId/calendar',
        parent:'withHeader',
        component: 'calendarManagement',
        resolve: {
          installationId:function($transition$){
            return $transition$.params().installationId;
          },
          currentDate:function () {
            return moment();
          },
          groups: function(Group,installationId) {
            return Group.query({installationId:installationId}).$promise;
          },
          initMonthEvents:function (currentDate,installationId,Events) {
            return Events.getEventsForMonth(installationId,currentDate.year(),currentDate.month()+1);
          }
        },
        data: {
          permissions: {
            only: ['MANAGE-CALENDAR'],
            redirectTo: 'installations'
          }
        }
      })
      .state('pendingNotification', {
        url: '/installations/:installationId/pendingNotification',
        parent:'withHeader',
        component: 'pendingNotificationList',
        resolve: {
          installationId:function($transition$){
            return $transition$.params().installationId;
          },
          
        },
        data: {
          permissions: {
            only: ['SEE-PENDING-NOTIFICATION'],
            redirectTo: 'installations'
          }
        }
      })
      .state('ruleDetail', {
        url: '/installations/:installationId/ruleDetail/:ruleDetailId',
        parent:'withHeader',
        component: 'ruleDetail',
        resolve: {
          installationId:function($transition$){
            return $transition$.params().installationId;
          },
          rules: function(installationId, Rules) {
            return Rules.query({installationId:installationId}).$promise;
          }
        },
        data: {
          permissions: {
            only: ['SCHEDULER-RULE-EDIT'],
            redirectTo: 'installations'
          }
        }
      })
      .state('rules', {
        url: '/installations/:installationId/rules',
        parent:'withHeader',
        component: 'ruleDetail',
        resolve: {
          installationId:function($transition$){
            return $transition$.params().installationId;
          },
          rules: function(installationId, Rules) {
            return Rules.query({installationId:installationId}).$promise;
          }
        },
        data: {
          permissions: {
            only: ['SCHEDULER-RULE-EDIT'],
            redirectTo: 'installations'
          }
        }
      })
      .state('historyNotification', {
        url: '/installations/:installationId/historyNotification',
        parent:'withHeader',
        component: 'historyNotificationList',
        resolve: {
          installationId:function($transition$){
            return $transition$.params().installationId;
          },
          /*notifications:function (installationId,HistoryNotification) {
            return HistoryNotification.query({installationId:installationId,offset:0,limit:PAGE_SIZE}).$promise;
          }*/
        },
        data: {
          permissions: {
            only: ['NOTIFICATION-HISTORY'],
            redirectTo: 'installations'
          }
        }
      });
  });
