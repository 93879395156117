angular.module('shared')
    .component('treeSelection',{
        bindings:{
            installationId:'=',
            nodeSymbol:'<',
            onUpdate:'&',
            getChild:'&',
            idField:'<',
            displayField:'<',
            displayFieldWrapper:'<'
        },
        controller:function ($log) {
            var $ctrl = this;
            $ctrl.getDisplayName=function(node){
                var obj=node;
                if($ctrl.displayFieldWrapper&&node[$ctrl.displayFieldWrapper]){
                    obj=node[$ctrl.displayFieldWrapper];
                }
                return obj[$ctrl.displayField];
            };
            $ctrl.selectNode=function(node){
                $ctrl.onUpdate({node:node});
            };
            $ctrl.$onInit=function(){
                $ctrl.nodeSymbol.collapsed=true;
            };
            $ctrl.passDownGetChild=function(nodeId){
                return $ctrl.getChild({nodeId:nodeId});
            };
            $ctrl.expandNodeChild=function(node){
                $ctrl.getChild({nodeId:node[$ctrl.idField]}).then(function(data){
                    node.children=data;
                    node.collapsed=false;
                });
            };
        },
        templateUrl:'app/shared/TreeSelection/treeSelection.tpl.html'
    });

