angular.module('scheduler')
  .component('historyNotificationList',{
    templateUrl:'app/scheduler/historyNotification/historyNotification.tpl.html',
    bindings: {
      installationId: '=',
      notifications:'='
    },
    
    controller:function ($uibModal,$log,HistoryNotification,toastr,PAGE_SIZE) {
      var $ctrl=this;
      
      $ctrl.refreshNotification=function () {
        return HistoryNotification.query({installationId:$ctrl.installationId,limit:($ctrl.notifications?$ctrl.notifications.length:PAGE_SIZE),offset:0}).$promise.then(function (data) {
          //$ctrl.notifications=data;
          $ctrl.notifications=[];
          $ctrl.prepareList(data);
          return $ctrl.notifications;
        });
      };
      $ctrl.$onInit=function () {
        //starting
        $ctrl.pageParams={installationId:$ctrl.installationId,limit:PAGE_SIZE,offset:0};
        $ctrl.notifications=$ctrl.refreshNotification();
      };
      $ctrl.next=function(){
        $ctrl.pageParams.offset+=PAGE_SIZE;
        return HistoryNotification.query($ctrl.pageParams).$promise.then(function (data) {
          /*angular.forEach(data,function(item){
            $ctrl.notifications.push(item);
          });*/
          $ctrl.prepareList(data);
          return $ctrl.notifications;
        });
      };
      $ctrl.prepareList=function(data){
        angular.forEach(data,function(p){
          var item = p;
          var date = (p.event.Timestamp||p.event.timestamp||p.event.receivedtime);
          var dOpen = date?moment(date,['D-M-YYYY H:m.s']).format('DD-MM-YYYY HH:mm:ss'):null;
          
          item.dOpen = dOpen;
          $ctrl.notifications.push(item);
        });
      }
    }
    
  });
