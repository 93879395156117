angular.module('shared')
  .service('DynamicColorService', function () {
    function _computeColorForView(element){
      var color='';//no color
      if (angular.isUndefined(element.counter)||element.counter===null){
        return color;
      }
      if (element.counter > 0) {
        color = element.r_symbolconfiguration.WebColorWithRows;
      } else {
        color = element.r_symbolconfiguration.WebColorNoRows;
      }
      if (color.length){
        color='#'+color;
      }
      return color;
    }
    function getColorForMap(r_symbolconfiguration,status) {
      var iconData = r_symbolconfiguration.okColor;
      try {
        status = status.split('|')[0];
      } catch (err){
        status = undefined;
      }

      // return r_symbolconfiguration._InOrderColor;
      if (angular.isUndefined(status)) {
        return r_symbolconfiguration.okColor;
      }

      switch (status) {
        case 'NOTIFICATION':
          iconData = r_symbolconfiguration.notificationColor;
          break;
        case 'WARNING':
          iconData = r_symbolconfiguration.warningColor;
          break;
        case 'PREALLARM':
          iconData = r_symbolconfiguration.preallarmColor;
          break;
        case 'ALLARM':
          iconData = r_symbolconfiguration.allarmColor;
          break;
        case 'PRIORITY':
          iconData = r_symbolconfiguration.priorityColor;
          break;
        case 'EMERGENCY':
          iconData = r_symbolconfiguration.emergencyColor;
          break;
      }
      if (angular.isString(iconData)&&iconData.length){
        iconData='rgb('+iconData+')';
      }
      return iconData;
    }
    return {
      getColorForItem:function(element){
        if (angular.isUndefined(element.r_symbolconfiguration.$type)){//views
          return _computeColorForView(element);
        }else{
          return getColorForMap(element.r_symbolconfiguration,element.r_state); //maps
        }
      },
      getColorForMap:getColorForMap
    };
  });
