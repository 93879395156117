/* eslint-disable angular/controller-as */


/**
 * @ngdoc function
 * @name feApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the feApp
 */
angular.module('plants')
    .controller('PlantConsoleCtrl', function(sock, CommandService, $uibModal, $document, $log, $scope, socketService, $rootScope, plantId, installationId, PlantService, Company, $timeout, UserService){
        'use strict';
        $scope.plantId = plantId;
        $scope.msg = {};
        $scope.installationId = installationId;
        $scope.eventList = [];
        $scope.followEvent = false;
        $scope.selectPlant = function(p){
            $scope.selectedPlant = p;
            $scope.msg.plantId = p.plantId;
        };
        var excludeFilter = UserService.getGroupSettingsByInstallationId($scope.installationId).history_exclude_filter;
        if(excludeFilter){
            excludeFilter = excludeFilter.toLowerCase().split(/[,]/).map(function(value){
                return value.trim();
            });
        }
        $scope.goDown = function(){
            var id = 'messagesBox';
            var div = $document[ 0 ].getElementById(id);
            // eslint-disable-next-line angular/angularelement
            $('#' + id).animate({
                scrollTop: div.scrollHeight - div.clientHeight
            }, 500);
        };
        $scope.followChanged = function(){
            if($scope.followEvent){
                $scope.goDown();
            }
        };
        PlantService.plantDetail($scope.plantId).then(function(data){
            $scope.plantInfo = data.data;
            $scope.selectPlant($scope.plantInfo);
            return Company.get({ companyId: $scope.plantInfo.customerId }).$promise;
        }).then(function(response){
            $scope.customerInfo = response.data;
            $scope.getEvents();
            return CommandService.getAvailableCommands($scope.plantInfo.installationId, $scope.plantInfo.plantId);
        }).then(function(commands){
            $scope.cmds = commands;
        });

        function isInSTRListFilter(toBeChecked, stringList){
            if(toBeChecked && stringList){
                toBeChecked = toBeChecked.toLowerCase();
                return stringList.reduce(function(acc, item){
                    return acc || toBeChecked.includes(item.trim().toLowerCase());
                }, false);
            }else{
                return false;
            }
        }
        var parseToOldMessage = function (message) {
            // utility function, it transforms new messages to old format
            // for frontend happiness
            var newMSG = {
                plant: message.plantId.slice(-4),
                progressive: message.counter,
                dateTime: message.msg.datetime,
                eventDesc: message.msg.message,
                eventInfo: message.msg.info,
                Plant: message.plantId.slice(-4),
                Progressive: message.counter,
                Datetime: message.msg.datetime,
                Message: message.msg.message,
                Info: message.msg.info,
                evntType: message.msg.code
            };
            var parsedMessage = {
                MSG: newMSG,
                INSTALLATION_ID: message.installationId,
                PLANT_ID: message.plantId,
                PLANT_DESCRIPTION: message.plantDescription,
                COUNTER: message.counter,
                SOURCE: message.source,
                TIMESTAMPHISTORY: message.timestampHistory
            };
            return parsedMessage;
        };

        $scope.parseMessage = function(jsData, historical){
            if(jsData.PLANT_ID.toUpperCase() === $scope.selectedPlant.MAC.toUpperCase()){
                var plantMessage = jsData.MSG;
                if(angular.isString(plantMessage)){
                    try{
                        plantMessage = angular.fromJson(plantMessage);
                    }catch(e){
                        $log.error(e);
                    }
                }
            }
            if(historical){
                /** @namespace plantMessage.Plant */
                plantMessage[ 'plant' ] = plantMessage.Plant;
                /** @namespace plantMessage.Progressive */
                plantMessage[ 'progressive' ] = plantMessage.Progressive;
                /** @namespace plantMessage.Datetime */
                plantMessage[ 'dateTime' ] = plantMessage.Datetime;
                /** @namespace plantMessage.Message */
                plantMessage[ 'eventDesc' ] = plantMessage.Message;
                plantMessage[ 'eventInfo' ] = plantMessage.Info;
            }
           // plantMessage[ 'timings' ] = $scope.calculateTimingsFromMsg(jsData);
            plantMessage[ 'history' ] = historical;

            decodePlantStateMessage(plantMessage);
            if(!UserService.eventCodeShouldBeHidden(plantMessage.evntType, $scope.installationId)){
                // exclude filter is empty or not match
                if(!excludeFilter || (excludeFilter && !isInSTRListFilter(plantMessage.eventDesc || '', excludeFilter) && !isInSTRListFilter(plantMessage.eventInfo || '', excludeFilter))){
                    $scope.eventList.push(plantMessage);
                }
            }
        };
        $scope.getEvents = function(){
            //clean all
            $scope.eventList = [];
            //FIXME stop coming events to be processed
            CommandService.getEvents($scope.plantInfo.installationId, $scope.plantId, UserService.getGroupIdByInstallationId($scope.plantInfo.installationId)).then(function(data){
                angular.forEach(data, function(eventData){
                    // convert new hi
                    var jsData = parseToOldMessage(eventData.value);

                    try{
                        $scope.parseMessage(jsData, true);
                    }catch(err){
                        $log.error(err);
                        //$scope.eventList.push({ tokens: ['MSG_DECODE_ERR', data]})
                    }
                });
                $scope.subscribedTopic = socketService.subscribe('Installation.' + $scope.plantInfo.installationId);
                $timeout(function(){
                    $scope.followChanged();
                });
            }).catch(function(error){
                $scope.subscribedTopic = socketService.subscribe('Installation.' + $scope.plantInfo.installationId);
                $log.error(error);
            });
        };
        $scope.animationsEnabled = true;
        $scope.open = function(size){
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/plants/commandPopup/commandPopup.tpl.html',
                controller: 'CommandPopupCtrl',
                size: size,
                resolve: {
                    plantAddress: function(){
                        return $scope.selectedPlant.MAC;
                    },
                    plant: function(){
                        return $scope.selectedPlant;
                    },
                    sendMsgFn: function(){
                        return $scope.sendMsg;
                    },
                    command: function(){
                        /** @namespace $scope.cmds.selectedCommand */
                        return $scope.cmds.selectedCommand;
                    }
                }
            });

            modalInstance.result.then(function(selectedItem){
            }, function(){
                $log.info('Modal dismissed at: ' + new Date());
            });
        };
        $scope.commandChanged = function(){
            var cmdString = $scope.cmds.selectedCommand.cmdString;
            $scope.open();
            $scope.msg.cmdString = cmdString;
        };
        $scope.search1 = '';
        /**
         *
         * @param {object} event
         * @param {Array.<{TIMESTAMP:string}>} event.TIMESTAMPHISTORY
         */
        $scope.calculateTimingsFromMsg = function(event){
            var timingObj = { 'total_time': null, 'history': event.TIMESTAMPHISTORY };
            var t1 = moment(event.TIMESTAMPHISTORY[ 0 ].TIMESTAMP + '+0000', [ 'YYYYMMDD HH:mm:ss.SSSZ', 'x' ]);
            t1.utcOffset(2);
            var t2 = moment(event.TIMESTAMPHISTORY[ event.TIMESTAMPHISTORY.length - 1 ].TIMESTAMP, [ 'YYYYMMDD HH:mm:ss.SSS', 'x' ]);
            timingObj.total_time = t2 - t1;
            return timingObj;
        };
        $scope.selectCallback = function(select){
            $scope.search1 = select.item.id;
            $scope.selectedPlant = select.item;
            $scope.validatePlant = true;
            $scope.$apply();
        };

        /**
         *@param {object} data
         *@param {string} data.evntType
         * */
        function decodePlantStateMessage(data){
            if(data.evntType === '185'){
                $scope.plantInfo.isConnected = true;
            }else{
                if(data.evntType === '248'){
                    $scope.plantInfo.isConnected = false;
                }
            }
        }

        var com90Socket = $rootScope.$on('socket:com90', function(event, data){
            if (data.PLANT_ID != null && data.PLANT_ID.toUpperCase() !== $scope.selectedPlant.MAC.toUpperCase()) {
                // message is not for the selected plant
                return;
            }

            try{
                $scope.parseMessage(data);
            }catch(err){
                $log.error(err);
                //$scope.eventList.push({ tokens: ['MSG_DECODE_ERR', data]})
            }
            $scope.$apply();
            $timeout(function(){
                $scope.followChanged();
            });
        });
        $scope.sendMsg = function(plantAddress, commandItem, commandTemplate){
            return CommandService.sendCommand(plantAddress, commandItem, commandTemplate, $scope.plantInfo.installationId, { 'PLANT_DESCRIPTION': $scope.plantInfo.description });
        };
        $scope.$on('$destroy', function(){
            com90Socket();
            socketService.unsubscribe($scope.subscribedTopic);
        });
    });
