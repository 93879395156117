/* eslint-disable angular/controller-as */

angular.module('maps')
    .controller('videoChartModalCtrl', function ($uibModalInstance, $scope, $http, BASE_PATH, $uibModal, $log, blockUI, toastr, videocloudId, hashFilter, sensorDescriptionRequest) {
        $scope.hashFilter = hashFilter;
        function filter_dates(file) {
            if (hashFilter != undefined){
                return file.videocloudregid.includes(hashFilter);
            } else {
                return true;
            }
        }

        $scope.today = new Date();
        $scope.today.setDate($scope.today.getDate()+1);
        $scope.today.setHours(23,59,59,59);
        $scope.toDate = new Date();//moment().format('YYYY-MM-DD');
        $scope.fromDate = new Date();//moment().subtract(7, 'd').format('YYYY-MM-DD');
        $scope.toDate.setHours(23, 59, 59,58);
        $scope.fromDate.setDate($scope.fromDate.getDate()-7);
        $scope.fromDate.setHours(0, 0, 1);
        

        $scope.videocloudId = videocloudId;

        $scope.getListOfVideo = function(){
            var paramObj = {from: $scope.fromDate, to:$scope.toDate};
            if($scope.fromDate && $scope.toDate){
                console.log('Data start e end', $scope.fromDate, $scope.toDate);
                $http.post(BASE_PATH.API_URL + '/videocloud/'+ $scope.videocloudId+'/db/recordings', paramObj).then(function(response) {
                    var videoData = response.data;
                    $scope.videocloudFiles = videoData.filter(filter_dates);
                    // $scope.videocloudFiles = videoData;
                });
            }else{
                console.log('error get list video', $scope.fromDate, $scope.toDate)
            }
        }
        
        $scope.downloadFile = function(file){
            console.log('download file');
            var composeKey = $scope.videocloudId+"/"+file.videocloudregid+'.mp4';
            if(!verifyNameStreamToBeDownloaded(composeKey)){
                console.error('Videocloud file name non corretto', file);
                toastr.error('Videocloud file name non corretto');
                return;
            }
            $http.post(BASE_PATH.API_URL + '/videocloud/'+ $scope.videocloudId+'/recording/url', {"fileKey" : composeKey}).then(function(result){
                window.location.href = result.data.signedUrl;
            })
        }

        $scope.ok = function () {
            $uibModalInstance.close();
        };

        function verifyNameStreamToBeDownloaded(str){
            var m;
            var regex = /[a-z0-9]*_([0-9]*)_([0-9]*).mp4/gm;
            if(regex.test(str))
                return true;
            return false;
        }

    });
