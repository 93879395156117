angular.module('maps')
  .directive('standAloneMaps', function(){
    return {
      restrict: 'AEC',
      templateUrl: 'app/maps/directives/standAloneMap/standAloneMaps.tpl.html',
      scope: {
        installationId: '=',
        mapId: '=',
        symbolHiglith: '=',
        menuOffset:'@?'
      },
      controller: function($q, MapService, $filter, $rootScope, $log, $scope, $location, modalService, socketService, ViewPluginService, DynamicColorService, BASE_PATH){
        // contains all tree nodes flattened and accessibile by id
        $scope.breadcrumbsPath = [];
        $scope.treeNodesMap = {};
        // array with expanded nodes
        $scope.expandedNodes = [];
        $scope.startMapId = $scope.mapId;
        $scope.calculateColor = DynamicColorService.getColorForMap;
        function formatNodes(treeData, parent){
          return treeData.map(function(obj){
            var rObj = obj;
            rObj.name = obj.r_symbolconfiguration.description;
            rObj.parent = parent;
            rObj.children = [];
            $scope.treeNodesMap[obj.r_idsymbol] = rObj;
            return rObj;
          });
        }
        $scope.expandNodeEvent = function(node, expanded){
          $log.info(node, expanded);
          if(expanded === true && node.children.length === 0){
            node.children = [{name: 'loading'}];
            MapService.map.getChildren($scope.installationId, node.r_idsymbol)
              .then(function(children){
                node.children = formatNodes(children, node);
              });
          }
        };
        $scope.expandNode = function(node){
          var matchingIdx = $scope.expandedNodes.map(function(x, idx){
            // noinspection EqualityComparisonWithCoercionJS
              if(x.r_idsymbol == node.r_idsymbol){
              return idx;
            }
          });
          if(matchingIdx.length !== 0){
            for(var i = 0; i < matchingIdx.length; i++){
              $scope.expandedNodes.splice(matchingIdx[i], 1);
            }
          }else{
            $scope.expandedNodes.push(node);
          }
        };
        $scope.onBreadcrumbSelection = function(node){
          $scope.showSelected(node);

        };
        $scope.isNodeSelected = function(node){
          if($scope.selectedNode){
            return node.r_idsymbol === $scope.selectedNode.r_idsymbol;
          }
          return false;
        };
        /**
         * Show selected node by changing mapNode and opens parent leafs
         * @param  {object} node
         * @param  {object} node.parent
         * @param  {object} node.r_idsymbol
         * @param  {Array<object>} node.children
         */
        $scope.showSelected = function(node){
          $log.info('node selection');
          $scope.selectedNode = $scope.treeNodesMap[node.r_idsymbol];
          if(node.r_symbolconfiguration.backGroundFileId){
              if (BASE_PATH.MEDIA_PATH[BASE_PATH.MEDIA_PATH.length-1]==='/'){
                  $scope.backgroundImageURI = BASE_PATH.MEDIA_PATH + 'gemss/image/' + node.r_symbolconfiguration.backGroundFileId;
              }else{
                  $scope.backgroundImageURI = BASE_PATH.MEDIA_PATH + '/gemss/image/' + node.r_symbolconfiguration.backGroundFileId;
              }

            MapService.map.getNodeSymbols($scope.installationId, node.r_idsymbol)
              .then(function(expandedNode){
                $scope.breadcrumbsPath = [node];
                node.children = formatNodes(expandedNode.children, node);
                $scope.mapNode = node;
                // change url location
                //$location.path(base + 'maps/' + node.r_idsymbol, false);
                try{
                  if(node.parent === null){
                    $log.debug('no parent for node ', node);
                  }else{
                    expandParentTreeNodes(node.parent.r_idsymbol);
                  }
                }catch(err){
                  $log.error(err);
                }
                $rootScope.$broadcast('map:resize');
              });
          }else{
            $rootScope.$emit('map:selectnode', node);
            throw new Error('no background for tree element');
            //FIXME
          }
        };
        // INITIALIZATION
       MapService.map.getNodeSymbols($scope.installationId, $scope.mapId).then(function(rootNode){
            $log.debug('end map tree', rootNode);
            $scope.rootData = rootNode;
            $scope.treeNodesMap[$scope.startMapId] = rootNode;
            rootNode.children = formatNodes(rootNode.children, rootNode);
            $scope.dataForTheTree = [rootNode];
            $scope.expandedNodes.push($scope.dataForTheTree[0]);
            $scope.showSelected($scope.dataForTheTree[0]);
            $scope.subscribedTopic =  socketService.subscribe('Installation.' + $scope.installationId );
          });

        /**
         * Recursive expanding of parents treenode
         * @param  {[type]} parentId [description]
         * @return {[type]}          [description]
         */
        var expandParentTreeNodes = function(parentId){
          $log.debug('expanding parent node');
          var parent = $scope.treeNodesMap[parentId];
          $scope.breadcrumbsPath.push(parent);
          if($scope.expandedNodes.indexOf(parent) === -1){
            $scope.expandedNodes.push(parent);
          }
          if(angular.isDefined(parent.parent) || parent.parent != null){
            $log.info('expand parent');
            expandParentTreeNodes(parent.parent.r_idsymbol);
          }
        };

        /**
         * fetch, expand tree and select passed node
         * @param {*} destinationNode
         */
        function navigateToNode(destinationNode){
          $log.log('navigation event received', destinationNode);
          var nextMapNode = $scope.treeNodesMap[destinationNode.nodeId];
          // if node to navigate exists
          if(angular.isDefined(nextMapNode)){
            $scope.showSelected(nextMapNode);
            $scope.$apply();
            // if node does not exists, fetch children data of parents, then show
          }else{
            var hierarchy = destinationNode.hierarchy;
            if(hierarchy){
              var hier = hierarchy.split('.');
              var nodesToBeFetched = [];
              // get nodes that are not into treenodesmap
                angular.forEach(hier,function (nodeId,i) {
                    var node = $scope.treeNodesMap[nodeId];
                    if(angular.isUndefined(node)){
                        nodesToBeFetched.push(hier[i - 1]);
                    }
                });
              //create 'empty' promise which resolves automagically
              var p = $q.when('');
              //recursively reducing nodesToBeFetched array, which implies getting node symbols - initialize reduce function with empty response in order to get it working
              nodesToBeFetched.reduce(function(prev, mapId){
                return prev.then(function(){
                  return MapService.map.getNodeSymbols($scope.installationId, mapId);
                }).then(function(rootNode){
                    // associate only fetched children with current parent node
                  $scope.treeNodesMap[mapId].children = formatNodes(rootNode.children, $scope.treeNodesMap[mapId]);
                });
              }, p).then(function(){// after reduce function because it returns a promise object
                  var nextMapNode = $scope.treeNodesMap[destinationNode.nodeId];
                  $scope.showSelected(nextMapNode);
                });
            }else{
              //FIXME insert stylized node message
              alert('no node data');
            }
          }
        }

        /**
         * Map 'navigateTo' event listener
         *
         * @param  {[type]} navigateToNode) {nodeId, nodeMapInstance}
         * @return {[type]}                 [description]
         */
        var navigateToListener = $rootScope.$on('map:navigate', function(event, destinationNode){
          navigateToNode(destinationNode);
        });

        /**
         * Update tree states on events
         * @param  {[type]} event [description]
         * @param  {[type]} data) {               } [description]
         * @return {[type]}       [description]
         */
        var socketListener = $rootScope.$on('socket:map', function(event, payload){
          var symbolsData = payload.data;
          for(var i = 0; i < symbolsData.length; i++){
            var variationSymbol = symbolsData[i];
            // symbolData.guidLink = symbolsData[i].IdSymbol;
            var toUpdateSymbol = $scope.treeNodesMap[variationSymbol.IdSymbol];
            if(angular.isDefined(toUpdateSymbol)){
              $log.debug('updating symbol state', toUpdateSymbol);
              toUpdateSymbol.r_state = variationSymbol.State;
              toUpdateSymbol.r_command = variationSymbol.Command;
            }
          }
          $scope.$apply();
        });
        // Unregister
        $scope.$on('$destroy', function(){
          $log.log('Unregistering listener');
          socketListener();
          navigateToListener();
          socketService.unsubscribe($scope.subscribedTopic);
        });
      }
    };
  });
