/* eslint-disable angular/controller-as */

angular.module('maps')
    .controller('askVideoModalCtrl', function ($uibModalInstance, $scope, $http, BASE_PATH, $uibModal, $log, blockUI, toastr, symbolDescription, callback) {
        
        $scope.description = symbolDescription;
        $scope.cancel = function(){
            $uibModalInstance.dismiss();
        };
        $scope.ok = function () {
            $uibModalInstance.close();
            callback();
        };
    });
