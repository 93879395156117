angular.module('shared')
  .service('Company',function($resource,BASE_PATH,$http){
    
    var company= $resource(BASE_PATH.API_URL+'company/:companyId',{companyId:'@companyId'});
    
    company.getEmployee=function(companyId){
        return $http.get(BASE_PATH.API_URL+'company/'+companyId+'/employee').then(function(response){
            return response.data;
        });
    };
    return company;
  });
