angular
  .module('app')
  .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.otherwise('/installations');
  $stateProvider
    .state('withHeader', {
      abstract:true,
      template: '<header-component></header-component><div class="padding-top-header full-height" ui-view></div>'
    })
    .state('withoutHeader', {
      abstract:true,
      template: '<div ui-view></div>'
    });
}
