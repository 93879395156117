/* eslint-disable */
angular.module('shared')
  .controller('ConfirmModalInstanceCtrl', function ($uibModalInstance, $scope, message) {
    var $ctrl = this;
    console.log('confirm modal popup');
    $scope.modalOptions = message;
    $scope.ok = function () {
      $uibModalInstance.close('delete');
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
