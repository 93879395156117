/* eslint-disable angular/no-services,angular/controller-as,angular/window-service,angular/controller-as-vm */
//TODO use directive
angular.module('videocloud')
    //.controller('VideoCtrl', function($filter, $rootScope, $window, $uibModal, $log, $scope, BASE_PATH, $http, $state, $location, modalService, socketService, ViewPluginService, installationId, viewId, toastr, UserService,plants,blockUI){

    .controller('ErrorCtrl', function($filter, $rootScope, $window, $uibModal, $log, $scope, BASE_PATH, $http, $state, $location, modalService, socketService, ViewPluginService, toastr, UserService, blockUI, videocloudId, streamId){
        $log.info('Videocloud error controller');
    });



