angular.module('scheduler')
  .service('PendingNotification',function ($resource,$log,BASE_PATH) {
    return  $resource(BASE_PATH.API_URL + 'installation/:installationId/pendingNotification/:id/:action', {
      installationId: '@installationId',
      id: '@id',
      action: '@action'
    }, {
      takenOver: {method: 'POST', params: {action: 'takenOver'}}
    });
  });
