angular.module('auth')
    .component('groupList', {
        bindings: {
            groups: '=',
            installationId: '=',
            rootMapSymbol: '='
        },
        controller: function($log, Group, $filter, blockUI, toastr,MapService){
            var $ctrl = this;
            $ctrl.addGroup = function(){
                angular.forEach($ctrl.groups, function(group){
                    group.selected = false;
                });
                $ctrl.selectedGroup = new Group({installationId: $ctrl.installationId});
                
            };
            $ctrl.createGroup = function(){
                $log.info('createGroup');
                $ctrl.newGroup.$save().then(function(response){
                    response.reloadGroups = $ctrl.reloadGroups;
                    $ctrl.groups.push(response);
                }).catch(function(error){
                    $log.error(error);
                });
            };
            $ctrl.saveGroup = function(){
                blockUI.start();
                var isNew = angular.isUndefined($ctrl.selectedGroup.id);
                $ctrl.selectedGroup.installationId = $ctrl.installationId;
                $log.info($ctrl.selectedGroup);
                $ctrl.selectedGroup.$save().then(function(response){
                    response.installationId=$ctrl.installationId;
                    if(isNew){
                        response.reloadGroups = $ctrl.reloadGroups;
                        $ctrl.groups.push(response);
                    }
                    MapService.map.getNodeInfo($ctrl.installationId,$ctrl.selectedGroup.groupSettings.initialMapSymbol).then(function(nodeInfo){
                        $ctrl.selectedGroup.groupSettings.initialMapNode=nodeInfo;
                        blockUI.stop();
                    });
                }).catch(function(error){
                    $log.error(error);
                });
            };
            $ctrl.onSelectGroup = function(group){
                blockUI.start();
                Group.get({installationId: $ctrl.installationId, id: group.id}).$promise.then(function(response){
                    angular.forEach($ctrl.groups, function(group){
                        group.selected = false;
                    });
                    Object.keys(response).forEach(function(key){
                        group[key] = response[key];
                    });
                    group.selected = true;
                    group.installationId = $ctrl.installationId;
                    MapService.map.getNodeInfo($ctrl.installationId,group.groupSettings.initialMapSymbol).then(function(nodeInfo){
                        $ctrl.selectedGroup = group;
                        $ctrl.selectedGroup.reloadGroups = $ctrl.reloadGroups;
                        $ctrl.selectedGroup.groupSettings.initialMapNode=nodeInfo;
                        blockUI.stop();
                    });
                }).catch(function(error){
                    $log.error(error);
                    blockUI.stop();
                    toastr.error('Problema imprevisto riprovare in seguito');
                });
            };
            $ctrl.reloadGroups = function(){
                var selectedGroupId = ($ctrl.selectedGroup || {}).id;
                $ctrl.groups = Group.query({installationId: $ctrl.installationId});
                if(selectedGroupId){
                    var selectedGroup = $filter('filter')($ctrl.groups, {id: selectedGroupId})[0];
                    if(selectedGroup){
                        $ctrl.onSelectGroup(selectedGroup[0]);
                    }
                }
            };
        },
        templateUrl: 'app/auth/groups/groupList/groupList.tpl.html'
    });
