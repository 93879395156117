angular.module('shared')
    .service('socketService', function (BASE_PATH, $q, $rootScope, $log, UserService, $timeout, toastr, $window, modalService, $uibModal) {
        var sock;
        var pingMsg = 'ping_';
        var pongMsg = 'pong_';
        var keepAliveTimeoutValue = 4000;
        var keepAliveTimeout;
        var waiting = true;

        var connect = function () {
            var deferred = $q.defer();
            if (angular.isDefined(sock)) {
                deferred.resolve(sock);
                return deferred.promise;
            }
            var openingSock = new SockJS(path + '?sess=' + encodeURIComponent(UserService.getUserToken())); //TODO FIXME REMEMBER
            openingSock.onopen = function () {
                $log.info('RT Socket Open. ');
                $timeout(sendPing, keepAliveTimeoutValue * 2);
                //handleMessage({data:JSON.stringify({MSG:{subject:'oggetto',body:'corpo',TYPE:'NOTIFICATION'}})});
                deferred.resolve(openingSock);
            };
            openingSock.onmessage = handleMessage;
            openingSock.onerror = function (e) {
                $log.error(e);
                deferred.resolve(true);
            };
            openingSock.onclose = function (e) {
                e = e || {};
                e.code = 'E' + (e.code || '001');
                $log.error('RT Socket Closed. ', e);
                if (keepAliveTimeout) {
                    $timeout.cancel(keepAliveTimeout);
                    deferred.resolve(true);
                }
                // $window.alert('SOCKET: Persa la connessione realtime, ricaricare la pagina');

                /* Variabili di gestione finestra avvenuta disconnessione */
                /* (MD, 14/12/2020)                          */
                var modalOptions = {
                    closeButtonText: 'Chiudi',
                    actionButtonText: 'Ricarica',
                    headerText: 'Avvenuta disconnessione',
                    bodyText: 'Persa la connessione realtime, ricaricare la pagina per proseguire'
                };
                var modalInstance = $uibModal.open({
                    animation: false,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'app/shared/popup/confirmModal/confirmModal.tpl.html',
                    controller: 'ConfirmModalInstanceCtrl',
                    resolve: {
                        message: function(){
                            return modalOptions;
                        }
                    }
                });

                modalInstance.result.then(function(){
                    $window.location.reload(true);
                }, function(result){
                    $log.info(result);
                    $log.info('Modal dismissed at: ' + new Date());
                    //$window.location.reload(true);
                });

                deferred.resolve(true);
                // DO NOT COMMIT
                // $window.location.reload(true);
            };
            return deferred.promise;
        };
        var factory = {
            connect: connect,
            subscribe: function (topic) {
                factory.send({'subscribe': topic});
                return topic;
            },
            unsubscribe: function (topic) {
                factory.send({'unsubscribe': topic});
            },
            send: function (msgString) {
                if (!angular.isString(msgString)) {
                    try {
                        msgString = angular.toJson(msgString);
                    } catch (e) {
                        $log.debug(msgString + ' is not a json');
                    }
                }
                factory.connect().then(function (sock) {
                    sock.send(msgString);
                });
            }
        };

        function handleSoundNotification(data) {
            $rootScope.$emit('socket:sound', {
                'installationId': 'no-installation-id',//TODO check if exists
                'data': angular.fromJson(data.MSG || {number: 1}) // 11/05/2021, prima era "Number"
            });
        }

        var path = BASE_PATH.SOCK_PATH.includes('http') ? BASE_PATH.SOCK_PATH : BASE_PATH.API_URL + BASE_PATH.SOCK_PATH;
        var openNotificationToastr = function (message) {
            /** @namespace message.users */
            /** @namespace message.body */
            /** @namespace message.subject */
            /** @namespace message.notification_level */
            // we want to notify the current user only if present in the notification group.
            var notificationUsers = message.users || [];
            var settings = notificationUsers.filter(function (user) {
                /** @namespace user.email */
                return user.email === UserService.getUserUsername();
            });
            if (settings.length === 0) {
                // user is not in notification group so do not notify
                return
            }
            if (settings.length > 1) {
                $log.warn('user is in multiple notification groups. this may cause unwanted behaviours.')
            }
            settings = settings[0];
            $log.debug('Popup notification user settings: ' + JSON.stringify(settings))

            var level = message.notification_level || 'warning';
            if (settings.enable_pop_up_notification) {
                toastr[level](message.body || '', message.subject || 'Attenzione', {
                    timeOut: 0,
                    extendedTimeOut: 0,
                    closeIcon: false,
                    allowHtml: true,
                    closeButton: true,
                    tapToDismiss: false,
                    toastClass: 'toast no-opacity',
                    closeHtml: '<button><i class="fa fa-times-circle"></i></button>'
                });
                /** @namespace message.if_popup_sound */
                /** @namespace settings.sound_on_pop_up_notification */
                if (message.if_popup_sound && settings.sound_on_pop_up_notification) {
                    handleSoundNotification({MSG: {number: 1}});
                }
            }

        };
        /* keep for debug reason
        var openFakeToastr = function () {
            openNotificationToastr({
                body: ' impianto Demo  <br><br>  ATTIVAZIONE ZONA  <br> 0011 -  Zona Semplice 11 <br><br> ' + new Date().toLocaleString(),
                subject: 'Installazione prova'
            });
            $timeout(function(){
            openFakeToastr();
            },10000);
        };
        openFakeToastr();
        */
        /* views sound debug
        var runSound=function(){
            handleSoundNotification({MSG:{Number:-1}});
        };
        runSound();
         */
        var capitalizeFirstLetter = function (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };
        var objectKeysToUpperCase = function (input) {
            if (angular.isObject(input) !== true) return input;
            if (angular.isArray(input)) return input.map(objectKeysToUpperCase);
            return Object.keys(input).reduce(function (newObj, key) {
                var oldValue = input[key];
                var newVal = (angular.isObject(oldValue)) ? objectKeysToUpperCase(oldValue) : oldValue;
                newObj[capitalizeFirstLetter(key)] = newVal;
                return newObj;
            }, {});
        };
        var parseMessage = function (message) {
            // utility function, it transforms new messages to old format
            // for frontend happiness
            var msgType = message.notificationType || message.source;
            switch (msgType) {
                case 'CONNECTIONSTATE':
                    var newMSG = {
                        idPlant: message.msg.idPlant.slice(-4),
                        plant: message.msg.plant,
                        connected: message.msg.connected,
                        plantDescription: message.plantDescription,
                        installationId: message.installationId,
                        dateTime: new Date(),
                        userInfo: message.userInfo
                    };
                    break;
                case 'COM90':
                    var newMSG = {
                        plant: message.plantId.slice(-4),
                        progressive: message.counter,
                        dateTime: message.msg.datetime,
                        eventDesc: message.msg.message,
                        eventInfo: message.msg.info,
                        Plant: message.plantId.slice(-4),
                        Progressive: message.counter,
                        Datetime: message.msg.datetime,
                        Message: message.msg.message,
                        Info: message.msg.info,
                        evntType: message.msg.code
                    };
                    break;
                case 'VIEWS':
                    var newMSG = objectKeysToUpperCase(message.msg);
                    break;
                case 'SMOWKE':
                    var newMSG = objectKeysToUpperCase(message.msg);
                    break;
                case 'SCHEDULER':
                case 'NOTIFICATION':
                    var newMSG = message.msg;
                    break;
                case 'SOUND':
                    // Cambiato il servizio in modo che venga gestito il messaggio come viene ricevuto.
                    var newMSG = message.msg;
                    break;
                case 'REFRESH':
                    var newMSG = message.msg;
                    break;
                default:
                    $log.error('!!! Unable to parse new message ' + message.uuid);
                    var newMSG = message.msg;
            }

            var parsedMessage = {
                MSG: { TYPE: message.notificationType, MSG: newMSG },
                INSTALLATION_ID: message.installationId,
                PLANT_ID: message.plantId,
                PLANT_DESCRIPTION: message.plantDescription,
                COUNTER: message.counter,
                SOURCE: message.source,
                TIMESTAMPHISTORY: message.timestampHistory,
                USERINFO: message.userInfo
            };
            return parsedMessage;
        };

        var handleMessage = function (e) {
            var COM90_MSG_TYPE='COM90';
            if (e.data.includes(pongMsg)) {
                waiting = false;
            } else {
                $log.info('Incoming Message', e.data);
                var data = angular.fromJson(e.data);

                // convert new message format to new old
                data = parseMessage(data);

                var gemssMsg = data.MSG;
                if (angular.isString(gemssMsg)) {
                    gemssMsg = angular.fromJson(gemssMsg);
                }

                if (data.NOTIFICATION_TYPE===COM90_MSG_TYPE){
                    //data.MSG = angular.fromJson(data);
                    $rootScope.$emit('socket:com90', data);
                }else{
                    var type = gemssMsg.TYPE || gemssMsg.MSG.type || gemssMsg.MSG.Type;
                    switch (type) {
                        case 'CONNECTIONSTATE':
                            data.MSG = angular.fromJson(gemssMsg.MSG);
                            $rootScope.$emit('socket:connectionstate', data);
                            break;
                        case COM90_MSG_TYPE:
                            data.MSG = angular.fromJson(gemssMsg.MSG);
                            $rootScope.$emit('socket:com90', data);
                            break;
                        case 'VIEWS':
                            $rootScope.$emit('socket:view', {
                                'installationId': data.INSTALLATION_ID,
                                'data': angular.fromJson(gemssMsg.MSG)
                            });
                            break;
                        case 'SMOWKE':
                            $rootScope.$emit('socket:map', {
                                'installationId': 'no-installation-id',
                                'data': angular.fromJson(gemssMsg.MSG)
                            });
                            break;
                        case 'SOUND':
                            handleSoundNotification(angular.fromJson(gemssMsg));
                            break;
                        case 'NOTIFICATION':
                            openNotificationToastr(gemssMsg.MSG);
                            break;
                        case 'REFRESH':
                            var msg=gemssMsg.MSG;
                            // MD, Aggiunto controllo valore null (16/03/2021)
                            if (msg !== null && msg.length > 0){
                                msg=angular.fromJson(gemssMsg.MSG);
                            }
                            $rootScope.$emit('socket:refresh', {
                                'installationId': data.INSTALLATION_ID,
                                'data': msg
                            });
                            break;
                        default:
                            $log.error('not recognized message from ws');
                            break;
                    }
                }

            }
        };

        window.testHandleMsg = handleMessage;
        var sendPing = function () {
            factory.send(pingMsg);
            waitForPong();
        };
        var waitForPong = function () {
            if (angular.isDefined(keepAliveTimeout)) {
                $timeout.cancel(keepAliveTimeout);
            }
            keepAliveTimeout = $timeout(function () {
                if (waiting) {
                    $log.error('timeout on keep alive');
                    if (sock) {
                        factory.connect().then(function (sock) {
                            sock.onclose();
                        });
                    }
                } else {
                    waiting = true;
                    sendPing();
                }
            }, keepAliveTimeoutValue);
        };
        sock = connect();
        return factory;
    });

